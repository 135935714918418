import React, { Fragment, useState } from 'react';

import { useDataState } from '../../contexts/DataProvider';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DeleteIcon from '@material-ui/icons/Delete';

import { ICompany } from '../../interfaces/company';
import { IEvent } from '../../interfaces/event';
import { IStaff } from '../../interfaces/staff';
import { IRoom } from '../../interfaces/room';

import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Tooltip } from '@material-ui/core';
import Confirmation from '../Confirmation/Confirmation';

interface IRoomRowProps {
	/* The room in this row */
	room: IRoom;

	/* The current selected company */
	selectedCompany: ICompany;

	/* The current selected event */
	selectedEvent: IEvent;

	onRemoveRoom: (roomName: string) => void;

	/* When a request has completed */
	onRequestComplete: (severity: 'success' | 'error', message: string) => void;
}

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset'
		}
	}
});

// Cell component to assign facilitators to rooms
const RoomRow: React.FC<IRoomRowProps> = ({
	room,
	selectedCompany,
	selectedEvent,
	onRemoveRoom,
	onRequestComplete
}) => {
	const classes = useRowStyles();

	const { data, setData } = useDataState();

	const [facilitatorId, setFacilitatorId] = useState<number | string>(
		room.staff && room.staff.length > 0 ? room.staff[0].id! : ''
	);
	const [oldFacilitatorId, setOldFacilitatorId] = useState<number | string>('');
	const [savedFacilitatorId, setSavedFacilitatorId] = useState<number | undefined>(
		room.staff && room.staff.length > 0 ? room.staff[0].id! : undefined
	);
	const [hasSaved, setHasSaved] = useState<boolean>(
		room.staff && room.staff.length > 0 ? true : false
	);

	const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
	/**
	 * When the facilitator drop down changes
	 * @param event - the dropdown event
	 */
	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const newId = event.target.value as number;
		setOldFacilitatorId(facilitatorId as number);
		setFacilitatorId(newId);

		setHasSaved(newId === savedFacilitatorId);
	};

	/**
	 * Makes request to change the facilitator for the room depending on current state (POST/Delete)
	 */
	const saveChanges = () => {
		if (facilitatorId === '') {
			return;
		} else if (oldFacilitatorId === '') {
			// If adding staff member first time
			axios
				.post(
					`/api/company/${selectedCompany.id}/events/${selectedEvent.id}/rooms/${room.name}/staff`,
					JSON.stringify({ id: facilitatorId }),
					{ headers: { 'Content-Type': 'application/json' } }
				)
				.then(res => {
					const facilitator: IStaff | undefined = data.staff!.find(
						staffMember => staffMember.id === facilitatorId
					);

					if (facilitator) {
						if (room.staff) {
							room!.staff!.push(facilitator);
						} else {
							room.staff = [facilitator];
						}

						const newRoomList = [...data!.rooms!];
						const roomIndex = newRoomList.findIndex(
							dataRoom => dataRoom.name === room.name
						);
						newRoomList[roomIndex] = room;
						setData(prevData => ({ ...prevData, rooms: newRoomList }));
						setHasSaved(true);
						setSavedFacilitatorId(facilitatorId as number);
						onRequestComplete('success', 'Facilitator assigned.');
					}
				})
				.catch(err => {
					onRequestComplete('error', 'Unable to assign facilitator, please try again.');
				});
		} else if (facilitatorId !== oldFacilitatorId) {
			// If switching facilitators
			// Need to remove old one and then add new one
			// NOTE: for now just one facilitator per event, we have the capabilities for multiple that can be implemented later
			axios
				.delete(
					`/api/company/${selectedCompany.id}/events/${selectedEvent.id}/rooms/${room.name}/staff/${oldFacilitatorId}`
				)
				.then(response => {
					if (response.status === 200) {
						const updatedStaff = room!.staff!.filter(
							staffMember => staffMember.id !== facilitatorId
						);
						room.staff = updatedStaff;
						const newRoomList = [...data!.rooms!];
						const roomIndex = newRoomList.findIndex(
							dataRoom => dataRoom.name === room.name
						);
						newRoomList[roomIndex] = room;
						setData(prevData => ({ ...prevData, rooms: newRoomList }));

						axios
							.post(
								`/api/company/${selectedCompany.id}/events/${selectedEvent.id}/rooms/${room.name}/staff`,
								JSON.stringify({ id: facilitatorId }),
								{ headers: { 'Content-Type': 'application/json' } }
							)
							.then(res => {
								onRequestComplete('success', 'Facilitator changed.');
								const facilitator: IStaff | undefined = data.staff!.find(
									staffMember => staffMember.id === facilitatorId
								);

								if (facilitator) {
									room!.staff!.push(facilitator);
									const newRoomList = [...data!.rooms!];
									const roomIndex = newRoomList.findIndex(
										dataRoom => dataRoom.name === room.name
									);
									newRoomList[roomIndex] = room;
									setData(prevData => ({ ...prevData, rooms: newRoomList }));
									setHasSaved(true);
									setSavedFacilitatorId(facilitatorId as number);
								}
							})
							.catch(err => {
								onRequestComplete(
									'error',
									'Unable to change facilitator, please try again.'
								);
							});
					}
				})
				.catch(err => {
					onRequestComplete('error', 'Unable to change facilitator, please try again.');
				});
		}
	};

	const removeFacilitator = () => {
		axios
			.delete(
				`/api/company/${selectedCompany.id}/events/${selectedEvent.id}/rooms/${room.name}/staff/${facilitatorId}`
			)
			.then(response => {
				if (response.status === 200) {
					onRequestComplete('success', 'Facilitator removed.');

					const updatedStaff = room!.staff!.filter(
						staffMember => staffMember.id !== facilitatorId
					);
					room.staff = updatedStaff;
					const newRoomList = [...data!.rooms!];
					const roomIndex = newRoomList.findIndex(
						dataRoom => dataRoom.name === room.name
					);
					newRoomList[roomIndex] = room;
					setData(prevData => ({ ...prevData, rooms: newRoomList }));
					setHasSaved(false);
					setSavedFacilitatorId(undefined);
					setFacilitatorId('');
					return;
				}
			})
			.catch(err => {
				onRequestComplete('error', 'Unable to remove facilitator, please try again.');
				return;
			});
	};

	return (
		<Fragment>
			<TableRow className={classes.root}>
				<TableCell component="th" scope="row">
					<Tooltip title="Delete Room">
						<IconButton
							onClick={() => setShowConfirmDelete(true)}
							color="primary"
							aria-label="Delete Room"
						>
							<DeleteIcon />
						</IconButton>
					</Tooltip>

					{room.name}
				</TableCell>
				{room.supervisor ? (
					<TableCell>
						<span style={{ fontWeight: 'bold' }}>Supervisor Room</span>
					</TableCell>
				) : (
					<TableCell>
						<Grid container justify="center" alignItems="center">
							<Grid item xs={8}>
								<FormControl style={{ width: '90%' }}>
									<InputLabel
										style={{ marginLeft: '20px' }}
										id="demo-simple-select-label"
									>
										Facilitator
									</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={facilitatorId}
										onChange={handleChange}
										variant="outlined"
									>
										{data.staff?.map(staffMember => (
											<MenuItem
												key={staffMember.id}
												value={staffMember.id}
											>{`${staffMember.firstName} ${staffMember.lastName}`}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								{facilitatorId !== '' &&
									oldFacilitatorId !== facilitatorId &&
									!hasSaved && (
										<Button
											variant="contained"
											disableTouchRipple
											onClick={saveChanges}
										>
											Save
										</Button>
									)}
								{hasSaved && (
									<Button
										variant="contained"
										disableTouchRipple
										style={{ marginLeft: '5px' }}
										onClick={removeFacilitator}
									>
										Remove
									</Button>
								)}
							</Grid>
						</Grid>
					</TableCell>
				)}
			</TableRow>
			<Confirmation
				visiblity={showConfirmDelete}
				title="Remove this Room?"
				content="Are you sure you want to remove this room from the event?"
				onConfirm={() => onRemoveRoom(room.name)}
				onCancel={() => setShowConfirmDelete(false)}
			/>
		</Fragment>
	);
};

interface IRoomTableProps {
	/* Information on the current rooms */
	roomData: IRoom[];

	/* The current selected company */
	selectedCompany: ICompany;

	/* The current selected event */
	selectedEvent: IEvent;

	onRemoveRoom: (roomName: string) => void;

	/* When a request has completed */
	onRequestComplete: (severity: 'success' | 'error', message: string) => void;
}

const RoomTable: React.FC<IRoomTableProps> = ({
	roomData,
	selectedCompany,
	selectedEvent,
	onRemoveRoom,
	onRequestComplete
}) => {
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<span style={{ fontWeight: 'bold' }}>Room Name</span>
						</TableCell>
						<TableCell>
							<span style={{ fontWeight: 'bold' }}>Assign Facilitator</span>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{roomData.map(room => (
						<RoomRow
							key={room.name}
							room={room}
							selectedCompany={selectedCompany}
							selectedEvent={selectedEvent}
							onRemoveRoom={name => onRemoveRoom(name)}
							onRequestComplete={onRequestComplete}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default RoomTable;
