import { IFields } from '../components/Form';
import { futureDate, isEmail, minLength, passwordMatch, required } from './validators';

export const loginFields: IFields = {
	email: {
		id: 'email',
		type: 'email',
		label: 'Email',
		fullWidth: true,
		value: '',
		validation: [{ rule: required }, { rule: isEmail }]
	},
	password: {
		id: 'password',
		type: 'password',
		label: 'Password',
		value: '',
		autoFocus: false,
		fullWidth: true,
		validation: [{ rule: required }, { rule: minLength, args: 7 }]
	}
};

export const confirmEmailFields: IFields = {
	confirmEmail: {
		id: 'confirmEmail',
		type: 'email',
		label: 'Email Address',
		value: '',
		autoFocus: true,
		fullWidth: true,
		validation: [{ rule: required }, { rule: isEmail }]
	}
};

export const staffFields: IFields = {
	firstName: {
		id: 'firstName',
		type: 'text',
		label: 'First Name',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	},
	lastName: {
		id: 'lastName',
		type: 'text',
		label: 'Last Name',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	},
	email: {
		id: 'email',
		type: 'email',
		label: 'Email',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }, { rule: isEmail }]
	},
	role: {
		id: 'role',
		type: 'text',
		label: 'Role',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }],
		editor: 'dropdown',
		options: ['Admin', 'Facilitator', 'Coach']
	}
};

export const participantFields: IFields = {
	firstName: {
		id: 'firstName',
		type: 'text',
		label: 'First Name',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	},
	lastName: {
		id: 'lastName',
		type: 'text',
		label: 'Last Name',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	},
	email: {
		id: 'email',
		type: 'email',
		label: 'Email',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }, { rule: isEmail }]
	},
	position: {
		id: 'position',
		type: 'text',
		label: 'Position',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	},
	room: {
		id: 'room',
		type: 'text',
		label: 'Assigned Room',
		value: '',
		editor: 'dropdown',
		options: [],
		fullWidth: true,
		noLowerCaseOptionValue: true,
		validation: [{ rule: required }]
	}
};

export const companyFields: IFields = {
	name: {
		id: 'name',
		type: 'text',
		label: 'Company Name',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	},
	industry: {
		id: 'industry',
		type: 'text',
		label: 'Industry',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	},
	description: {
		id: 'description',
		type: 'text',
		label: 'Short Description',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	}
};

export const companyContactFields: IFields = {
	firstName: {
		id: 'firstName',
		type: 'text',
		label: 'First Name',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	},
	lastName: {
		id: 'lastName',
		type: 'text',
		label: 'Last Name',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	},
	email: {
		id: 'email',
		type: 'email',
		label: 'Email',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }, { rule: isEmail }]
	},
	division: {
		id: 'division',
		type: 'text',
		label: 'Division',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	},
	phoneNumber: {
		id: 'phoneNumber',
		type: 'text',
		label: 'Phone Number',
		value: '',
		fullWidth: true,
		validation: []
	},
	cellNumber: {
		id: 'cellNumber',
		type: 'text',
		label: 'Cell Number',
		value: '',
		fullWidth: true,
		validation: []
	}
};

export const resetFields: IFields = {
	newPassword: {
		id: 'newPassword',
		type: 'password',
		label: 'New Password',
		value: '',
		autoFocus: true,
		fullWidth: true,
		validation: [{ rule: required }, { rule: minLength, args: 7 }]
	},
	confirmPassword: {
		id: 'confirmPassword',
		type: 'password',
		label: 'Confirm Password',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }, { rule: passwordMatch, args: 'newPassword' }]
	}
};

export const eventFields: IFields = {
	name: {
		id: 'name',
		type: 'text',
		label: 'Event Name',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	},
	startDateTime: {
		id: 'startDateTime',
		editor: 'date',
		type: '',
		label: 'Start Date',
		value: undefined,
		fullWidth: true,
		validation: [{ rule: required }, { rule: futureDate }]
	},
	description: {
		id: 'description',
		editor: 'multilinetextbox',
		type: '',
		label: 'Description',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	}
};

export const roomFields: IFields = {
	name: {
		id: 'name',
		type: 'text',
		label: 'Room Name',
		value: '',
		fullWidth: true,
		validation: [{ rule: required }]
	}
};
