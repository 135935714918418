import {
	DailyCall,
	DailyEventObjectActiveSpeakerChange,
	DailyParticipant
} from '@daily-co/daily-js';

/**
 * Call state is comprised of:
 * - "Call items" (inputs to the call, i.e. participants or shared screens)
 * - UI state that depends on call items (for now, just whether to show "click allow" message)
 *
 * Call items are keyed by id:
 * - "local" for the current participant
 * - A session id for each remote participant
 * - "<id>-screen" for each shared screen
 */
const initialCallState = {
	callItems: {
		local: {
			videoTrackState: null,
			audioTrackState: null,
			name: 'subscribe|Me'
		}
	},
	callList: [],
	currentSpeaker: null,
	clickAllowTimeoutFired: false,
	camOrMicError: null,
	fatalError: null
};

// --- Actions ---

/**
 * CLICK_ALLOW_TIMEOUT action structure:
 * - type: string
 */
const CLICK_ALLOW_TIMEOUT = 'CLICK_ALLOW_TIMEOUT';

/**
 * PARTICIPANTS_CHANGE action structure:
 * - type: string
 * - callObject: the call object with the participants
 */
const PARTICIPANTS_CHANGE = 'PARTICIPANTS_CHANGE';

/**
 * PARTICIPANTS_AUDIO_CHANGE action structure (for facilitator view audio only):
 * - type: string
 * - callObject: the call object with the participants
 */
const PARTICIPANTS_AUDIO_CHANGE = 'PARTICIPANTS_AUDIO_CHANGE';

/**
 * PARTICIPANTS_AUDIO_CHANGE action structure (for when someone speaks):
 * - type: string
 * - speakerEvent: the event object when speaker changes
 */
const SPEAKER_UPDATE = 'SPEAKER_UPDATE';

/**
 * CAM_OR_MIC_ERROR action structure:
 * - type: string
 * - message: string
 */
const CAM_OR_MIC_ERROR = 'CAM_OR_MIC_ERROR';

/**
 * CAM_OR_MIC_ERROR action structure:
 * - type: string
 * - message: string
 */
const FATAL_ERROR = 'FATAL_ERROR';

// --- Reducer and helpers --

const callReducer = (callState: any, action: any): any => {
	switch (action.type) {
		case CLICK_ALLOW_TIMEOUT:
			return {
				...callState,
				clickAllowTimeoutFired: true
			};
		case PARTICIPANTS_CHANGE:
			const call = getCall(action.callObject, callState.currentSpeaker);
			return {
				...callState,
				callItems: call.items,
				callList: call.list
			};
		case PARTICIPANTS_AUDIO_CHANGE:
			const audioCallItems = getAudioCallItems(action.callObject);
			return {
				...callState,
				callItems: audioCallItems
			};
		case SPEAKER_UPDATE:
			const updatedSpeaker = updateCurrentSpeaker(action.speakerEvent);
			const updateCall = getCall(action.callObject, callState.currentSpeaker);
			return {
				...callState,
				currentSpeaker: updatedSpeaker,
				callItems: updateCall.items,
				callList: updateCall.list
			};
		case CAM_OR_MIC_ERROR:
			return { ...callState, camOrMicError: action.message };
		case FATAL_ERROR:
			return { ...callState, fatalError: action.message };
		default:
			throw new Error();
	}
};

const getCall = (callObject: DailyCall, currentSpeaker: string | null): any => {
	let call: any = { items: { ...initialCallState.callItems }, list: [] }; // Ensure we *always* have a local participant
	for (const [id, participant] of Object.entries(callObject.participants())) {
		const nameTag = participant.user_name;
		const nameSplit = nameTag.split('|');

		if (nameSplit.length === 2) {
			if (nameSplit[0] === 'subscribe') {
				callObject.updateParticipant(participant.session_id, {
					setSubscribedTracks: true,
				});

				let speaking = false;

				if (currentSpeaker !== null && currentSpeaker === participant.session_id) {
					speaking = true;
				}

				const callItem = {
					id: id,
					sessionId: participant.session_id,
					videoTrackState: participant.tracks.video,
					audioTrackState: participant.tracks.audio,
					name: nameSplit[1],
					speaker: speaking
				};

				call.items[id] = callItem;

				if (getScreenCallItem(participant)) {
					const screenShareCallItem = {
						id: id + '-screen',
						audioTrackState: participant.tracks.screenAudio,
						videoTrackState: participant.tracks.screenVideo
					};
					call.list.push(screenShareCallItem);
				}
			
				call.list.push(callItem);				

				continue;
			}
		}

		callObject.updateParticipant(participant.session_id, {
			setSubscribedTracks: false
		});
	}

	return call;
};

const getScreenCallItem = (participant: DailyParticipant): any => {
	const trackStatesForInclusion = ['loading', 'playable', 'interrupted'];
	return (
		trackStatesForInclusion.includes(participant.tracks.screenVideo.state) ||
		trackStatesForInclusion.includes(participant.tracks.screenAudio.state)
	);
};

const getAudioCallItems = (callObject: DailyCall): any => {
	let callItems: any = { ...initialCallState.callItems }; // Ensure we *always* have a local participant
	for (const [id, participant] of Object.entries(callObject.participants())) {
		if (isLocal(id)) continue;

		callItems[id] = {
			audioTrackState: participant.tracks.audio
		};
	}
	return callItems;
};

const updateCurrentSpeaker = (event: DailyEventObjectActiveSpeakerChange) => {
	return event.activeSpeaker.peerId;
};

// True if id corresponds to local participant (*not* their screen share)
const isLocal = (id: string): boolean => {
	return id === 'local';
};

const isScreenSharing = (id: string): boolean => {
	return id.endsWith('-screen');
};

const containsScreenShare = (callList: any) => {
	if (!callList) return;
	return callList.map((callItem: any) => callItem.id).some((id: string) => isScreenSharing(id));
};

export {
	initialCallState,
	CLICK_ALLOW_TIMEOUT,
	PARTICIPANTS_CHANGE,
	SPEAKER_UPDATE,
	PARTICIPANTS_AUDIO_CHANGE,
	CAM_OR_MIC_ERROR,
	FATAL_ERROR,
	callReducer,
	isLocal,
	isScreenSharing,
	containsScreenShare
};
