import React, { Fragment } from 'react';
import { DailyCall } from '@daily-co/daily-js';

import { makeStyles } from '@material-ui/core/styles';
import Document from './Document';
import { IDocument } from '../../interfaces/document';

import * as MessageType from '../../interfaces/message';

import PreviewDocument from '../PreviewDocument';
import ScreenShare from '../Video/ScreenShare';

interface IDesktopProps {
	socket: WebSocket | null;
	space: string;
	documents: IDocument[];
	previewDocument: IDocument | null;
	onRemovePreviewDocument: () => void;
	onDocumentSelected: (document: IDocument) => void;
	callObject: DailyCall | null;
	hideAssets?: boolean;
	desktopHeight: number;
	desktopWidth: number;
}

const useStyles = makeStyles(theme => ({
	root: {
		position: 'absolute',
		top: 0,
		left: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	addDocumentButton: {
		position: 'absolute',
		bottom: 125,
		left: '48%',
		transform: 'translateX(-50%)'
	},
	previewDocument: {
		margin: theme.spacing(4)
	}
}));

const Desktop: React.FC<IDesktopProps> = ({
	socket,
	space,
	documents,
	previewDocument,
	onRemovePreviewDocument,
	onDocumentSelected,
	callObject,
	hideAssets,
	desktopHeight,
	desktopWidth
}) => {
	const classes = useStyles();

	const handleRemoveDocument = (key: string): void => {
		if (socket) {
			const message: MessageType.IDocumentClose = {
				type: 'close-document',
				space: space,
				data: {
					assetKey: key
				}
			};
			socket.send(JSON.stringify(message));
		}
	};

	const handleUpdateDocument = (document: IDocument): void => {
		if (socket) {
			const message: MessageType.ISetDocumentMessage = {
				type: 'set-document',
				space: space,
				data: {
					assetKey: document.name,
					x: document.posX,
					y: document.posY,
					height: document.height,
					width: document.width
				}
			};

			socket.send(JSON.stringify(message));
		}
	};

	return (
		<div className={classes.root}>
			{/* Will hide assets when a facilitator is screen sharing. The same assets will pop back up when screensharing is finished. */}
			{!hideAssets && (
				<Fragment>
					{documents.map(doc => (
						<Document
							key={doc.url}
							desktopHeight={desktopHeight}
							desktopWidth={desktopWidth}
							document={doc}
							onUpdateDocument={handleUpdateDocument}
							onRemoveDocument={handleRemoveDocument}
							onDocumentSelected={onDocumentSelected}
						/>
					))}
				</Fragment>
			)}

			<ScreenShare callObject={callObject} />

			{!hideAssets && (
				<Fragment>
					{previewDocument && (
						<PreviewDocument
							document={previewDocument}
							onRemoveDocument={onRemovePreviewDocument}
						/>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default Desktop;
