import { Grid, makeStyles } from '@material-ui/core';
import { DividerCenter, DividerLeft, DividerRight } from '../../assets/chatPanel';

const useStyles = makeStyles(() => ({
	divider: {
		height: 15
	},
	dividerContainer: {
		display: 'flex',
		width: '100%',
		height: 'auto'
	}
}));

const ChatDivider: React.FC = () => {
	const classes = useStyles();
	return (
		<Grid item xs={12} className={classes.dividerContainer}>
			<DividerLeft className={classes.divider} />
			<DividerCenter className={classes.divider} />
			<DividerRight className={classes.divider} />
		</Grid>
	);
};

export default ChatDivider;
