import React from 'react';
import { useHistory } from 'react-router-dom';

import { useGlobalState } from '../../../contexts/GlobalStateProvider';
import { useSocketManager } from '../../../contexts/SocketManager';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';

import * as MessageType from '../../../interfaces/message';

import { makeStyles } from '@material-ui/core/styles';

import PanToolIcon from '@material-ui/icons/PanTool';
import { RoomPlate } from '../../../assets/roomPlate';

interface IRoomManagerHeaderProps {
	roomName: string;
	space: string;
	needAssistance: boolean;
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		flexGrow: 1
	},
	headerBar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		flexGrow: 1
	},
	alert: {
		paddingRight: '10px'
	},
	roomPlate: {
		backgroundColor: 'transparent',
		backgroundImage: `url(${RoomPlate})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center'
	}
}));

const RoomManagerHeader: React.FC<IRoomManagerHeaderProps> = ({
	roomName,
	space,
	needAssistance
}) => {
	const classes = useStyles();
	const history = useHistory();
	const { state } = useGlobalState();

	const { leaveRoom, socket } = useSocketManager();

	/**
	 * Remove assistance flag (for staff memebrs)
	 */
	const removeAssistanceHandler = (): void => {
		if (socket) {
			const message: MessageType.ISetAssistanceFlagMessage = {
				type: 'set-assistance-flag',
				space: space,
				data: {
					needsFacilitatorAssistance: false
				}
			};
			socket.send(JSON.stringify(message));
		}
	};

	/**
	 * Leaves the current room
	 */
	const leaveRoomHandler = (): void => {
		leaveRoom(roomName);
		history.push({
			pathname: '/dashboard',
			state: { page: 3 }
		});
	};

	return (
		<div className={classes.root}>
			<AppBar position="static" classes={{ root: classes.roomPlate }}>
				<Toolbar>
					<div className={classes.headerBar}>
						{state.user?.role !== 'participant' && needAssistance && (
							<Tooltip title="Room Needs Assistance - Click to Remove">
								<IconButton onClick={removeAssistanceHandler}>
									<PanToolIcon className={classes.alert} color="primary" />
								</IconButton>
							</Tooltip>
						)}
						{state.user?.role === 'participant' && needAssistance && (
							<Tooltip title="Room Needs Assistance">
								<PanToolIcon className={classes.alert} color="primary" />
							</Tooltip>
						)}
						<Typography variant="body1" className={classes.title}>
							{roomName}
						</Typography>
					</div>
					{state.user?.role !== 'participant' && (
						<Tooltip title="Leave Room">
							<IconButton edge="end" color="inherit" onClick={leaveRoomHandler}>
								<CancelIcon />
							</IconButton>
						</Tooltip>
					)}
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default RoomManagerHeader;
