import { createMuiTheme } from '@material-ui/core/styles';

import { brassDefault, brassPressed } from './assets/brassCircularButton';
import { longButtonDefault, longButtonHover, longButtonPressed } from './assets/longButton';
import { mediumButtonDefault, mediumButtonHover, mediumButtonPressed } from './assets/mediumButton';

export const theme = createMuiTheme({
	palette: {
		common: {
			black: '#000',
			white: '#fff'
		},
		type: 'light',
		primary: {
			light: '#da0202',
			main: '#c41818',
			dark: '#b32929',
			contrastText: '#fff'
		},
		secondary: {
			light: '#e1ba43',
			main: '#d1b253',
			dark: '#c4ac60',
			contrastText: '#272828'
		},
		error: {
			light: '#e57373',
			main: '#f44336',
			dark: '#d32f2f',
			contrastText: '#fff'
		},
		warning: {
			light: '#ffb74d',
			main: '#ff9800',
			dark: '#f57c00',
			contrastText: 'rgba(0, 0, 0, 0.87)'
		},
		info: {
			light: '#64b5f6',
			main: '#2196f3',
			dark: '#1976d2',
			contrastText: '#fff'
		},
		success: {
			light: '#81c784',
			main: '#4caf50',
			dark: '#388e3c',
			contrastText: 'rgba(0, 0, 0, 0.87)'
		},
		background: {
			paper: '#fff',
			default: '#fafafa'
		}
	},
	typography: {
		htmlFontSize: 16,
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		button: {
			textTransform: 'none'
		}
	},
	breakpoints: {
		values: {
			xs: 1024,
			// ipad pro
			sm: 1366,
			// fhd monitor
			md: 1920,
			// qhd monitor
			lg: 2560,
			//4k monitor
			xl: 3440
		}
	},
	overrides: {
		MuiTableCell: {
			root: {
				maxWidth: 125,
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis'
			}
		},
		MuiFormLabel: {
			root: {
				'&$focused': {
					color: '#272828'
				}
			}
		},
		MuiOutlinedInput: {
			root: {
				'&$focused $notchedOutline': {
					borderColor: '#d1b253'
				}
			}
		},
		MuiTabs: {
			indicator: {
				height: '.25rem'
			}
		},
		MuiFab: {
			root: {
				height: 60,
				width: 60,
				backgroundColor: 'transparent',
				backgroundImage: `url(${brassDefault})`,
				backgroundSize: 'contain',
				boxShadow: '0 10px 20px rgba(0,0,0, .5)',
				transitionDuration: '.60s',
				'&:active': {
					backgroundImage: `url(${brassPressed})`,
					boxShadow: '0 5px 15px rgba(0,0,0, .5)'
				},
				'&:hover': {
					backgroundColor: 'transparent'
				},
				'&$disabled': {
					backgroundColor: 'transparent',
					backgroundImage: `url(${brassPressed})`
				}
			}
		},
		MuiButton: {
			root: {},
			fullWidth: {
				backgroundImage: `url(${longButtonDefault})`,
				backgroundSize: 'cover',
				'&:hover': {
					backgroundImage: `url(${longButtonHover})`
				},
				'&:active': {
					backgroundImage: `url(${longButtonPressed})`
				}
			},
			contained: {
				backgroundImage: `url(${mediumButtonDefault})`,
				backgroundSize: 'cover',
				transition: '.3s',
				'&:hover': {
					backgroundImage: `url(${mediumButtonHover})`,
					transition: '.3s'
				},
				'&:active': {
					backgroundImage: `url(${mediumButtonPressed})`
				},
				'&$disabled': {
					backgroundImage: `url(${mediumButtonPressed})`
				}
			}
		}
	},
	// disabling the ripple animation across the app
	props: {
		MuiButtonBase: {
			disableRipple: true
		},
		MuiTab: {
			disableRipple: true
		}
	}
});
