import React from 'react';

import { useGlobalState } from '../../../contexts/GlobalStateProvider';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import StaffNavigation from '../../StaffNavigation';

import { RoomType } from '../../../types/types';

import { makeStyles } from '@material-ui/core/styles';
import { RoomPlate } from '../../../assets/roomPlate';

interface IRoomManagerHeaderProps {
	roomName: RoomType;
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		flexGrow: 1
	},
	headerBar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		flexGrow: 1
	},
	alert: {
		paddingRight: '10px'
	},
	roomPlate: {
		backgroundColor: 'transparent',
		backgroundImage: `url(${RoomPlate})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center'
	}
}));

const MainStageManagerHeader: React.FC<IRoomManagerHeaderProps> = ({ roomName }) => {
	const classes = useStyles();
	const { state } = useGlobalState();

	return (
		<div className={classes.root}>
			<AppBar position="static" classes={{ root: classes.roomPlate }}>
				<Toolbar>
					<div className={classes.headerBar}>
						<Typography variant="body1" className={classes.title}>
							{roomName}
						</Typography>
					</div>
					{state.user?.role !== 'participant' && <StaffNavigation />}
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default MainStageManagerHeader;
