import React, { useState, useRef, useLayoutEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from '../../TabPanel';
import MainStageManagerHeader from './GeneralRoomManagerHeader';
import ChatView from '../ChatView/ChatView';
import ChatViewIcon from '../ChatView/ChatViewIcon';

import { makeStyles } from '@material-ui/core/styles';
import LobbyView from '../LobbyView/LobbyView';
import { RoomType } from '../../../types/types';
import MainStageLobbyView from '../LobbyView/MainStageLobbyView';
import { IChatRoomData } from '../../../interfaces/roomSocketData';
import { FrameChat, WoodPanel } from '../../../assets/chatPanel';
import AnnouncementViewIcon from '../ChatView/AnnouncementViewIcon';
import AnnouncementChatView from '../ChatView/AnnouncementChatView';

import { UsersTabDefault, UsersTabHover, UsersTabPressed } from '../../../assets/icons/chatTabs';
import TabIcon from '../../Icons/TabIcon';
import {
	Tab1Default,
	Tab1Hover,
	Tab1Pressed,
	Tab2Default,
	Tab2Hover,
	Tab2Pressed,
	Tab3Default,
	Tab3Hover,
	Tab3Pressed
} from '../../../assets/chatTab';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import { Container, Tooltip } from '@material-ui/core';

interface IRoomMangerProps {
	socket: WebSocket | null;
	roomType: RoomType;
	roomData: IChatRoomData;
	announcementRoomData: IChatRoomData;
}

const useStyles = makeStyles(theme => ({
	root: {
		maxHeight: '100vh',
		width: '100vw'
	},
	sidePanel: {
		width: '20vw',
		height: 'auto',
		position: 'absolute',
		top: 0,
		right: 0,
		display: 'inline'
	},
	tab: {
		height: 50,
		minWidth: 25,
		paddingLeft: 0,
		paddingRight: 0,
		[theme.breakpoints.up('lg')]: {
			height: 75
		}
	},
	panel: {
		color: theme.palette.primary.contrastText,
		backgroundImage: `url(${FrameChat})`,
		backgroundSize: 'contain'
	},
	woodPanel: {
		position: 'absolute',
		left: -37,
		zIndex: 1000,
		[theme.breakpoints.down('md')]: {
			left: -33
		}
	},

	tab1: {
		padding: theme.spacing(0),
		backgroundImage: `url(${Tab1Default})`,
		backgroundSize: 'cover',
		'&:hover': {
			backgroundImage: `url(${Tab1Hover})`,
			backgroundSize: 'cover'
		},
		'&:active': {
			backgroundImage: `url(${Tab1Pressed})`,
			backgroundSize: 'cover'
		}
	},
	tab1Pressed: {
		backgroundImage: `url(${Tab1Pressed})`,
		backgroundSize: 'cover'
	},
	tab2: {
		padding: theme.spacing(0),
		backgroundImage: `url(${Tab2Default})`,
		backgroundSize: 'cover',
		'&:hover': {
			backgroundImage: `url(${Tab2Hover})`,
			backgroundSize: 'cover'
		},
		'&:active': {
			backgroundImage: `url(${Tab2Pressed})`,
			backgroundSize: 'cover'
		}
	},
	tab2Pressed: {
		backgroundImage: `url(${Tab2Pressed})`,
		backgroundSize: 'cover'
	},
	tab3: {
		padding: theme.spacing(0),
		backgroundImage: `url(${Tab3Default})`,
		backgroundSize: 'cover',
		'&:hover': {
			backgroundImage: `url(${Tab3Hover})`,
			backgroundSize: 'cover'
		},
		'&:active': {
			backgroundImage: `url(${Tab3Pressed})`,
			backgroundSize: 'cover'
		}
	},
	tab3Pressed: {
		backgroundImage: `url(${Tab3Pressed})`,
		backgroundSize: 'cover'
	},
	'@global': {
		'.MuiTab-textColorInherit': {
			opacity: 1
		}
	}
}));

const MainStageManager: React.FC<IRoomMangerProps> = ({
	socket,
	roomType,
	roomData,
	announcementRoomData
}) => {
	const classes = useStyles();
	let { height, width } = useWindowDimensions();

	const [tabValue, setTabValue] = useState<number>(0);
	const [isChatOpen, setIsChatOpen] = useState<boolean>(true);
	const [isAnnouncementsOpen, setIsAnnouncementsOpen] = useState<boolean>(false);
	const [headerHeight, setHeaderHeight] = useState<number>(0);
	const [windowDimensions, setWindowDimensions] = useState<{ height: number; width: number }>({
		height,
		width
	});
	const headerRef = useRef<HTMLDivElement | null>(null);

	/**
	 * Handles the tab value changing
	 * @param newValue - new value to change to
	 */
	const handleTabChange = (newValue: number): void => {
		setTabValue(newValue);
		if (newValue === 0) {
			setIsChatOpen(true);
		} else {
			setIsChatOpen(false);
		}

		if (newValue === 2) {
			setIsAnnouncementsOpen(true);
		} else {
			setIsAnnouncementsOpen(false);
		}
	};

	//  grabs and sets the values of the side panel's header to calculate
	// 	what the exact height of the chat component should be.
	useLayoutEffect(() => {
		if (headerRef.current !== null) {
			setHeaderHeight(headerRef.current.clientHeight);
		}
		setWindowDimensions({ height, width });
	}, [headerRef, height, width]);

	return (
		<div className={classes.root}>
			<Container disableGutters className={classes.sidePanel}>
				<WoodPanel
					className={classes.woodPanel}
					style={{ height: windowDimensions.height }}
				/>
				<Grid container direction="column" style={{ height: 'auto', display: 'inline' }}>
					<Grid item ref={headerRef}>
						<MainStageManagerHeader roomName={roomType} />
						<Tabs
							value={tabValue}
							onChange={(event: React.ChangeEvent<{}>, value: any) =>
								handleTabChange(value)
							}
							TabIndicatorProps={{
								style: {
									display: 'none'
								}
							}}
							variant="fullWidth"
						>
							<Tooltip title={isChatOpen ? 'Chat' : 'View Chat'}>
								<Tab
									icon={
										<ChatViewIcon
											messages={roomData.messages}
											isOpen={isChatOpen}
											tab
										/>
									}
									className={classes.tab}
									classes={{
										root: isChatOpen ? classes.tab1Pressed : classes.tab1
									}}
									disableTouchRipple
								/>
							</Tooltip>

							<Tooltip title={tabValue === 1 ? 'Room Users' : 'View Room Users'}>
								<Tab
									icon={
										<TabIcon
											defaultIcon={UsersTabDefault}
											hoverIcon={UsersTabHover}
											isPressed={tabValue === 1}
											pressedIcon={UsersTabPressed}
										/>
									}
									className={classes.tab}
									classes={{
										root: tabValue === 1 ? classes.tab2Pressed : classes.tab2
									}}
									disableTouchRipple
								/>
							</Tooltip>
							<Tooltip
								title={
									isAnnouncementsOpen ? ' Announcements' : 'View Announcements'
								}
							>
								<Tab
									icon={
										<AnnouncementViewIcon
											messages={announcementRoomData.messages}
											isOpen={isAnnouncementsOpen}
										/>
									}
									className={classes.tab}
									classes={{
										root: isAnnouncementsOpen
											? classes.tab3Pressed
											: classes.tab3
									}}
									disableTouchRipple
								/>
							</Tooltip>
						</Tabs>
					</Grid>
					<Grid
						item
						className={classes.panel}
						style={{ height: windowDimensions.height - headerHeight }}
					>
						<TabPanel index={0} value={tabValue}>
							<ChatView
								socket={socket}
								space={roomData.space}
								messages={roomData.messages}
								makeActions={false}
								height={windowDimensions.height - headerHeight}
							/>
						</TabPanel>
						<TabPanel index={1} value={tabValue}>
							{roomType === 'Main Stage' ? (
								<MainStageLobbyView lobby={roomData.lobby} space={roomData.space} />
							) : (
								<LobbyView lobby={roomData.lobby} roomType={roomType} />
							)}
						</TabPanel>

						<TabPanel index={2} value={tabValue}>
							<AnnouncementChatView
								socket={socket}
								space={announcementRoomData.space}
								messages={announcementRoomData.messages}
								height={windowDimensions.height - headerHeight}
							/>
						</TabPanel>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default MainStageManager;
