import { Fragment, useState, useEffect } from 'react';
import { useGlobalState } from '../../contexts/GlobalStateProvider';
import { DailyCall } from '@daily-co/daily-js';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeviceList from './DeviceDropdown';
import Confirmation from '../Confirmation/Confirmation';

interface DeviceSelectionProps {
	callObject: DailyCall | null;
	visibility: boolean;
	onClose: () => void;
}

const DeviceSelection = ({ callObject, visibility, onClose }: DeviceSelectionProps) => {
	const { setAcknowledgement, closeAcknowledgement } = useGlobalState();
	const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
	const [micDevices, setMicDevices] = useState<MediaDeviceInfo[]>([]);
	const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
	const [selectedAudio, setSelectedAudio] = useState<string>('');
	const [selectedMic, setSelectedMic] = useState<string>('');
	const [selectedVideo, setSelectedVideo] = useState<string>('');
	const [isLoading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		!callObject && setLoading(true);

		callObject?.enumerateDevices().then(response => {
			const audio = response.devices.filter(type => type.kind === 'audiooutput');
			const mic = response.devices.filter(type => type.kind === 'audioinput');
			const video = response.devices.filter(type => type.kind === 'videoinput');

			setLoading(false);
			// set devices
			setAudioDevices(audio);
			setMicDevices(mic);
			setVideoDevices(video);
		});
	}, [callObject]);

	const handleSelectedAudio = (e: any) => {
		e.preventDefault();
		setSelectedAudio(e.target.value);
	};

	const handleSelectedVideo = (e: any) => {
		e.preventDefault();
		setSelectedVideo(e.target.value);
	};

	const handleSelectedMic = (e: any) => {
		e.preventDefault();
		setSelectedMic(e.target.value);
	};

	const setDevices = async () => {
		if (callObject) {
			const outputDevices = callObject.setOutputDevice({
				outputDeviceId: selectedAudio
			});

			const inputDevices = callObject.setInputDevicesAsync({
				audioDeviceId: selectedMic,
				videoDeviceId: selectedVideo
			});

			Promise.all([outputDevices, inputDevices])
				.then(() => {
					onClose();
				})
				.catch(err => {
					console.log(err);
					onClose();
					setAcknowledgement(
						'Device Selection Failed',
						'We were unable to set your video and/or audio devices, please try again or contact support.',
						'Ok',
						closeAcknowledgement
					);
				});
		}
	};

	return (
		<Fragment>
			<Confirmation
				title="Select Preferred Audio/Video"
				content={
					<Fragment>
						{isLoading ? (
							<Grid container justify="center">
								<Grid item>
									<Typography variant="h6" color="primary">
										...Loading Devices
									</Typography>
								</Grid>
							</Grid>
						) : (
							<Fragment>
								<DeviceList
									deviceType={audioDevices}
									heading="Audio"
									onSelect={handleSelectedAudio}
									getValue={selectedAudio}
								/>
								<DeviceList
									deviceType={micDevices}
									heading="Microphone"
									onSelect={handleSelectedMic}
									getValue={selectedMic}
								/>
								<DeviceList
									deviceType={videoDevices}
									heading="Video"
									onSelect={handleSelectedVideo}
									getValue={selectedVideo}
								/>
							</Fragment>
						)}
					</Fragment>
				}
				visiblity={visibility}
				onConfirm={() => setDevices()}
				confirmationMsg="Submit"
				onCancel={onClose}
			/>
		</Fragment>
	);
};

export default DeviceSelection;
