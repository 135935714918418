import React, { Fragment, useState } from 'react';

import { useDataState } from '../../contexts/DataProvider';
import { useGlobalState } from '../../contexts/GlobalStateProvider';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';

import { IEvent } from '../../interfaces/event';
import { ICompany } from '../../interfaces/company';
import { IParticipant } from '../../interfaces/participant';
import { IRoom } from '../../interfaces/room';

import { eventFields } from '../../utils/inputFields';

import Form from '../../components/Form';
import Field from '../../components/Field';

import EventHeader from '../../components/ManageEvents/EventHeader';
import EventInfo from '../../components/ManageEvents/EventInfo';
import Details from '../../components/Details';

import axios from 'axios';

const ManageEvents: React.FC = () => {
	const { data, setData } = useDataState();

	const { setAcknowledgement, closeAcknowledgement } = useGlobalState();

	const [tabValue, setTabValue] = useState<number>(0);
	const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
	const [companyInputValue, setCompanyInputValue] = useState<string>('');
	const [isEditEvent, setIsEditEvent] = useState<boolean>(false);
	const [eventInputValue, setEventInputValue] = useState<string>('');
	const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);
	const [showEventDetails, setShowEventDetails] = useState<boolean>(false);
	const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
	const [snackbarMessage, setSnackbarMessage] = useState<string>('');
	const [snackbarSeverity, setSnackBackSeverity] = useState<Color | undefined>(undefined);
	const [archive, setArchive] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	/**
	 * When user selects a company in the drop down (autocomplete)
	 */
	const handleChangeCompany = (newSelectedCompany: ICompany | null): void => {
		setSelectedCompany(newSelectedCompany);

		if (newSelectedCompany) {
			handleGetEvents(archive, newSelectedCompany);
			clearEventInput();
		}
	};

	/**
	 * When user selects a event in the drop down (autocomplete)
	 */
	const handleChangeEvent = (newSelectedEvent: IEvent | null): void => {
		setSelectedEvent(newSelectedEvent);

		if (selectedCompany !== null) {
			if (newSelectedEvent) {
				axios
					.get(`/api/company/${selectedCompany.id}/events/${newSelectedEvent.id}`)
					.then(response => {
						const event: IEvent = response.data;
						const rooms: IRoom[] = event.rooms!;
						const participants: IParticipant[] = [];

						// Get all participants from each room to display in 'edit participants' area
						rooms.forEach(room => {
							room.participants?.forEach(participant => {
								let newParticipant: IParticipant = participant;
								newParticipant.roomName = room.name;
								participants.push(newParticipant);
							});
						});

						setData(prevData => ({
							...prevData,
							rooms: rooms,
							participants: participants
						}));
					});
			}
		}
	};

	/**
	 * Handle when the archive toggle changes
	 * @param checked - the new state of the toggle
	 */
	const handleChangeArchive = (checked: boolean): void => {
		setArchive(checked);

		handleGetEvents(checked, selectedCompany!);
	};

	/**
	 * Removes the selected event and value in auto complete
	 */
	const clearEventInput = (): void => {
		setSelectedEvent(null);
		setEventInputValue('');
	};

	/**
	 * Handles the change of tab views in this component
	 * @param {React.ChangeEvent<{}>} event - The default change event that gets passed from component
	 * @param {number} newValue - The new value for the tab component to change to
	 */
	const handleTabChange = (newValue: number): void => {
		setTabValue(newValue);
	};

	/**
	 * Show the event details panel for adding or editing
	 * @param {boolean} editMode - If the user is in edit mode or not
	 */
	const handleShowEventDetails = (editMode: boolean): void => {
		setIsEditEvent(editMode);

		eventFields.name.value = editMode ? selectedEvent?.name : '';
		eventFields.startDateTime.value = editMode
			? new Date(selectedEvent!.startTime as string)
			: new Date();
		eventFields.description.value = editMode ? selectedEvent?.description : '';

		setShowEventDetails(true);
	};

	/**
	 * Handles which get request to do based on the current state of archive toggle
	 * @param {boolean} includeArchive - whether to include archives or not
	 * @param {ICompany} selectedCompany - the current selected comapny
	 */
	const handleGetEvents = (includeArchive: boolean, selectedCompany: ICompany): void => {
		if (includeArchive) {
			getAllEvents(selectedCompany);
		} else {
			getUnarchivedEvents(selectedCompany);
		}
	};

	/**
	 * Makes request to get Events (with archives included)
	 * @param {ICompany} selectedCompany - the current selected comapny
	 */
	const getAllEvents = (selectedCompany: ICompany): void => {
		axios
			.get(`/api/company/${selectedCompany.id}/events?type=all`)
			.then(response => {
				const events: IEvent[] = [];
				response.data.forEach((event: IEvent) => {
					events.push(event);
				});
				setData(prevData => ({ ...prevData, events: events }));
			})
			.catch(err => {
				console.log(err);
				setAcknowledgement(
					'Error',
					'We were unable to retreive events for this company, please try again or contact support.',
					'Ok',
					closeAcknowledgement
				);
			});
	};

	/**
	 * Makes request to get Events (not including archives)
	 * @param {ICompany} selectedCompany - the current selected comapny
	 */
	const getUnarchivedEvents = (selectedCompany: ICompany): void => {
		axios
			.get(`/api/company/${selectedCompany.id}/events`)
			.then(response => {
				const events: IEvent[] = [];
				response.data.forEach((event: IEvent) => {
					events.push(event);
				});
				setData(prevData => ({ ...prevData, events: events }));
			})
			.catch(err => {
				console.log(err);
				setAcknowledgement(
					'Error',
					'We were unable to retreive events for this company, please try again or contact support.',
					'Ok',
					closeAcknowledgement
				);
			});
	};

	/**
	 * Makes request to add or modify event to database (POST/PUT)
	 * @param {any} eventData - All the event info needed to add or edit
	 */
	const handleAddEvent = (eventData: any): void => {
		setIsLoading(true);
		if (selectedCompany) {
			if (isEditEvent && selectedEvent) {
				const editedEvent: IEvent = {
					name: eventData.name,
					startTime: (eventData.startDateTime as Date).toISOString(),
					description: eventData.description
				};

				axios
					.put(
						`/api/company/${selectedCompany.id}/events/${selectedEvent.id}`,
						JSON.stringify(editedEvent),
						{ headers: { 'Content-Type': 'application/json' } }
					)
					.then(response => {
						const newEventList = [...data.events!];
						const updatedEvent: IEvent = response.data;
						const oldEventIndex = data.events!.findIndex(
							event => event.id === selectedEvent.id
						);
						newEventList[oldEventIndex] = updatedEvent;
						setData(prevData => ({ ...prevData, events: newEventList }));
						setSelectedEvent(updatedEvent);
						setShowEventDetails(false);
						setIsLoading(false);
						handleRequestComplete('success', 'Event has been edited.');
					})
					.catch(err => {
						const code = err.response.status;
						setIsLoading(false);
						if (code === 409) {
							handleRequestComplete(
								'error',
								'Can not update event name, a company with this name already exists.'
							);
						} else {
							handleRequestComplete(
								'error',
								'Failed to update event, please try again.'
							);
						}
					});
			} else {
				const addedEvent: IEvent = {
					name: eventData.name,
					startTime: (eventData.startDateTime as Date).toISOString(),
					description: eventData.description
				};

				axios
					.post(`/api/company/${selectedCompany.id}/events`, JSON.stringify(addedEvent), {
						headers: { 'Content-Type': 'application/json' }
					})
					.then(response => {
						const newEvent: IEvent = response.data;
						const newEventList = [...data.events!, newEvent];
						setData(prevData => ({ ...prevData, events: newEventList }));
						setShowEventDetails(false);
						setIsLoading(false);
						handleRequestComplete('success', 'Event Added.');
					})
					.catch(err => {
						const code = err.response.status;
						setIsLoading(false);
						if (code === 409) {
							handleRequestComplete('error', 'Event with this name already exists.');
						} else {
							handleRequestComplete(
								'error',
								'Failed to add event, please try again.'
							);
						}
					});
			}
		}
	};

	/**
	 * Handles when a request is completed and show snackbar
	 * @param {'success' | 'error'} severity - If the request was an success or error, change the type of snackbar
	 * @param {string} message - The message to send to snackbar
	 */
	const handleRequestComplete = (severity: 'success' | 'error', message: string): void => {
		setSnackBackSeverity(severity);
		setSnackbarMessage(message);
		setShowSnackbar(true);
	};

	return (
		<Fragment>
			<EventHeader
				selectedCompany={selectedCompany}
				selectedEvent={selectedEvent}
				companyInputValue={companyInputValue}
				eventInputValue={eventInputValue}
				tabValue={tabValue}
				eventDetails={showEventDetails}
				archiveChecked={archive}
				onTabChange={handleTabChange}
				onCompanyChange={handleChangeCompany}
				onArchiveChange={handleChangeArchive}
				onCompanyInputChange={newValue => setCompanyInputValue(newValue)}
				onEventChange={handleChangeEvent}
				onEventInputChange={newValue => setEventInputValue(newValue)}
				onClearEventInput={clearEventInput}
				onShowEventDetails={handleShowEventDetails}
				onRequestComplete={handleRequestComplete}
			/>
			<Grid container direction="column" alignItems="center" spacing={5}>
				{showEventDetails && (
					<Fragment>
						{!isLoading ? (
							<Details
								title="Event Details"
								size={6}
								onClose={() => setShowEventDetails(false)}
							>
								<Form
									onSubmit={handleAddEvent}
									fields={eventFields}
									render={() => (
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<Grid container justify="center">
												<Grid item xs={12}>
													<Field {...eventFields.startDateTime} />
												</Grid>
											</Grid>
											<Grid container justify="space-around">
												<Grid item xs={12}>
													<Field {...eventFields.name} />
												</Grid>
											</Grid>
											<Grid container justify="space-around">
												<Grid item xs={12}>
													<Field {...eventFields.description} />
												</Grid>
											</Grid>
											<Grid container justify="flex-end">
												<Grid item>
													<Button
														type="submit"
														variant="contained"
														disableTouchRipple
													>
														{isEditEvent ? 'Edit' : 'Add'}
													</Button>
												</Grid>
											</Grid>
										</MuiPickersUtilsProvider>
									)}
								/>
							</Details>
						) : (
							<Grid item>
								<CircularProgress size={100} color="primary" />
							</Grid>
						)}
					</Fragment>
				)}
				{selectedEvent !== null && !showEventDetails && !selectedEvent.isArchived && (
					<EventInfo
						tabValue={tabValue}
						selectedCompany={selectedCompany}
						selectedEvent={selectedEvent}
						onRequestComplete={handleRequestComplete}
						onUpdateEvent={handleChangeEvent}
					/>
				)}
			</Grid>
			<Snackbar
				open={showSnackbar}
				autoHideDuration={3000}
				onClose={() => setShowSnackbar(false)}
			>
				<MuiAlert severity={snackbarSeverity}>{snackbarMessage}</MuiAlert>
			</Snackbar>
		</Fragment>
	);
};

export default ManageEvents;
