import { ILobby, IRoomUser } from '../../interfaces/lobby';

/**
 * When a user joins a lobby
 * @param {ILobby} roomLobby - the current state of the lobby
 * @param {string} id - the user's id
 * @param {IRoomUser} user - information on the user
 * @returns {ILobby} - the new state of the lobby
 */
export const handleUserJoinLobby = (roomLobby: ILobby, id: string, user: IRoomUser): ILobby => {
	return { ...roomLobby, [id]: user };
};

/**
 * When a user leaves a lobby
 * @param {ILobby} roomLobby - the current state of the lobby
 * @param {string} id - the user's id
 * @returns {ILobby} - the new state of the lobby
 */
export const handleUserLeftLobby = (roomLobby: ILobby, id: string): ILobby => {
	const newLobbyList = { ...roomLobby };
	delete newLobbyList[id];

	return newLobbyList;
};

export const handleUserRaiseHand = (
	roomLobby: ILobby,
	userId: string,
	raiseHand: boolean
): ILobby => {
	const updatedUser = roomLobby[userId];
	if (updatedUser) {
		updatedUser.handRaised = raiseHand;
	}
	return roomLobby;
};

export const handleUserVideoChatter = (
	roomLobby: ILobby,
	userId: string,
	videoEnabled: boolean
): ILobby => {
	const updatedUser = roomLobby[userId];
	if (updatedUser) {
		updatedUser.videoEnabled = videoEnabled;
	}
	return roomLobby;
};
