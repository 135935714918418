import React, { Fragment, useState } from 'react';

import { useDataState } from '../../contexts/DataProvider';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { contactHeaderCells } from '../../utils/tableData';
import { ICompanyContact } from '../../interfaces/companyContact';
import { ICompany } from '../../interfaces/company';

import Form from '../../components/Form';
import Field from '../../components/Field';
import { companyContactFields } from '../../utils/inputFields';

import Details from '../../components/Details';
import Confirmation from '../../components/Confirmation/Confirmation';

import SelectSortTable from '../../components/SelectSortTable';

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

interface ICompanyContactProps {
	/** The current selected company */
	selectedCompany: ICompany | null;

	/** When a request has completed */
	onRequestComplete: (severity: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles(theme => ({
	btnContainer: {
		padding: '1rem 0'
	}
}));

const CompanyContacts: React.FC<ICompanyContactProps> = ({
	selectedCompany,
	onRequestComplete
}) => {
	const classes = useStyles();

	const { data, setData } = useDataState();

	const [selectedContactId, setSelectedContactId] = useState<number | undefined>(undefined);
	const [showContactDetails, setShowContactDetails] = useState<boolean>(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
	const [isEditContact, setIsEditContact] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	/**
	 * Show the company contact details for adding and editing
	 * @param {any} id - potential id of selected contact for editing
	 */
	const handleEditContact = (id: any): void => {
		const companyContact = data.contacts?.find(contact => contact.id === id);

		setIsEditContact(companyContact !== undefined);

		// Set the field values if in edit mode
		companyContactFields.firstName.value = companyContact ? companyContact.firstName : '';
		companyContactFields.lastName.value = companyContact ? companyContact.lastName : '';
		companyContactFields.email.value = companyContact ? companyContact.email : '';
		companyContactFields.division.value = companyContact ? companyContact.division : '';
		companyContactFields.phoneNumber.value = companyContact ? companyContact.phoneNumber : '';
		companyContactFields.cellNumber.value = companyContact ? companyContact.cellNumber : '';

		setShowContactDetails(!showContactDetails);
	};

	/**
	 * Get the Company Contact that is selected in table
	 * @returns {ICompanyContact | undefined[]} - The selected contact or undefined if not found
	 */
	const getSelectedContact = (): ICompanyContact | undefined => {
		return data.contacts!.find(contact => contact.id === selectedContactId);
	};

	/**
	 * When user selects Company Contact from table
	 * @param {number | undefined} newSelectedContact- The id of selected Company Contact or undefined if no selected
	 */
	const handleSelectedContact = (newSelectedContact: number | undefined): void => {
		setSelectedContactId(newSelectedContact);
	};

	/**
	 * Makes request to add or modify company contacts to database (POST/PUT)
	 * @param {any} companyData - All the staff info needed to add or edit
	 */
	const handleAddCompanyContact = (companyContactData: any): void => {
		setIsLoading(true);
		if (isEditContact) {
			if (selectedCompany !== null && selectedContactId !== undefined) {
				const editedCompanyContact: ICompanyContact = {
					firstName: companyContactData.firstName,
					lastName: companyContactData.lastName,
					email: companyContactData.email,
					division: companyContactData.division,
					phoneNumber: companyContactData.phoneNumber,
					cellNumber: companyContactData.cellNumber
				};

				axios
					.put(
						`/api/company/${selectedCompany.id}/contacts/${selectedContactId}`,
						JSON.stringify(editedCompanyContact),
						{ headers: { 'Content-Type': 'application/json' } }
					)
					.then(response => {
						const newCompanyContactList = [...data.contacts!];
						const updatedContact: ICompanyContact = response.data;
						const oldContactIndex = data.contacts!.findIndex(
							contact => contact.id === selectedContactId
						);
						newCompanyContactList[oldContactIndex] = updatedContact;
						setData(prevData => ({ ...prevData, contacts: newCompanyContactList }));
						setShowContactDetails(false);
						setIsLoading(false);
						onRequestComplete('success', 'Company Contact has been edited.');
					})
					.catch(err => {
						const code = err.response.status;
						setIsLoading(false);
						if (code === 409) {
							onRequestComplete(
								'error',
								'Can not update company name, a company with this name already exists.'
							);
						} else {
							onRequestComplete(
								'error',
								'Failed to update company, please try again.'
							);
						}
					});
			}
		} else {
			if (selectedCompany !== null) {
				const newContact: ICompanyContact = {
					firstName: companyContactData.firstName,
					lastName: companyContactData.lastName,
					email: companyContactData.email,
					division: companyContactData.division,
					phoneNumber: companyContactData.phoneNumber,
					cellNumber: companyContactData.cellNumber
				};

				axios
					.post(
						`/api/company/${selectedCompany.id}/contacts`,
						JSON.stringify(newContact),
						{
							headers: { 'Content-Type': 'application/json' }
						}
					)
					.then(response => {
						const newContact: ICompanyContact = response.data;
						const newContactList = [...data.contacts!, newContact];
						setData(prevData => ({ ...prevData, contacts: newContactList }));
						setShowContactDetails(false);
						setIsLoading(false);
						onRequestComplete('success', 'Company contact has been added.');
					})
					.catch(err => {
						const code = err.response.status;
						setIsLoading(false);
						if (code === 409) {
							onRequestComplete(
								'error',
								'Company contact with this email already exists.'
							);
						} else {
							onRequestComplete(
								'error',
								'Failed to add company contact, please try again'
							);
						}
					});
			}
		}
	};

	/**
	 * Makes a request to delete company contact (DELETE)
	 */
	const handleDeleteCompanyContact = (): void => {
		if (selectedCompany && selectedContactId) {
			axios
				.delete(`/api/company/${selectedCompany.id}/contacts/${selectedContactId}`)
				.then(response => {
					const newContactList = data.contacts!.filter(
						contact => contact.id !== selectedContactId
					);
					setData(prevData => ({ ...prevData, contacts: newContactList }));
					setSelectedContactId(undefined);
					setShowConfirmDelete(false);
					setShowContactDetails(false);
					onRequestComplete('success', 'Company contact deleted.');
				})
				.catch(err => {
					onRequestComplete('error', 'Failed to delete company, please try again.');
				});
		}
	};

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item>
				<Typography variant="h6">Company Contacts</Typography>
				<hr />
			</Grid>
			<SelectSortTable
				headers={contactHeaderCells}
				data={data.contacts ? data.contacts : []}
				selected={selectedContactId}
				onSelectedChange={handleSelectedContact}
				excludedColumns={['companyId']}
				onEdit={id => handleEditContact(id)}
				onDelete={() => setShowConfirmDelete(true)}
				lockable={false}
			/>
			<Grid container justify="flex-start" className={classes.btnContainer}>
				<Grid item>
					<Button
						variant="contained"
						disableTouchRipple
						startIcon={<PersonAddIcon />}
						onClick={() => handleEditContact(-1)}
					>
						Add Contact
					</Button>
				</Grid>

				{showContactDetails && (
					<Fragment>
						{!isLoading ? (
							<Grid container>
								<Details
									title="Company Contact Details"
									size={12}
									onClose={() => setShowContactDetails(false)}
								>
									<Form
										onSubmit={handleAddCompanyContact}
										fields={companyContactFields}
										render={() => (
											<Fragment>
												<Grid container justify="space-around">
													<Grid item xs={5}>
														<Field
															{...companyContactFields.firstName}
															value={
																isEditContact
																	? getSelectedContact()
																			?.firstName
																	: ''
															}
														/>
													</Grid>
													<Grid item xs={5}>
														<Field
															{...companyContactFields.lastName}
															value={
																isEditContact
																	? getSelectedContact()?.lastName
																	: ''
															}
														/>
													</Grid>
												</Grid>
												<Grid container justify="space-around">
													<Grid item xs={5}>
														<Field
															{...companyContactFields.division}
															value={
																isEditContact
																	? getSelectedContact()?.division
																	: ''
															}
														/>
													</Grid>
													<Grid item xs={5}>
														<Field
															{...companyContactFields.email}
															value={
																isEditContact
																	? getSelectedContact()?.email
																	: ''
															}
														/>
													</Grid>
												</Grid>
												<Grid container justify="space-around">
													<Grid item xs={5}>
														<Field
															{...companyContactFields.phoneNumber}
															value={
																isEditContact
																	? getSelectedContact()
																			?.phoneNumber
																	: ''
															}
														/>
													</Grid>
													<Grid item xs={5}>
														<Field
															{...companyContactFields.cellNumber}
															value={
																isEditContact
																	? getSelectedContact()
																			?.cellNumber
																	: ''
															}
														/>
													</Grid>
												</Grid>
												<Grid container justify="flex-end">
													<Grid item>
														<Button
															type="submit"
															variant="contained"
															disableTouchRipple
														>
															{isEditContact ? 'Edit' : 'Add'}
														</Button>
													</Grid>
												</Grid>
											</Fragment>
										)}
									/>
								</Details>
							</Grid>
						) : (
							<Grid container justify="center" alignItems="center">
								<CircularProgress size={100} color="primary" />
							</Grid>
						)}
					</Fragment>
				)}
			</Grid>
			<Confirmation
				visiblity={showConfirmDelete}
				title="Delete this Contact?"
				content="Are you sure you want to delete this company contact?"
				onConfirm={handleDeleteCompanyContact}
				onCancel={() => setShowConfirmDelete(false)}
			/>
		</Grid>
	);
};

export default CompanyContacts;
