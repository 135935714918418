import React, { useState, useEffect, useRef } from 'react';
import { useGlobalState } from '../../../contexts/GlobalStateProvider';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import * as MessageType from '../../../interfaces/message';

import AnnouncementChatCard from './AnnouncementChatCard';

import { makeStyles } from '@material-ui/core/styles';
import { RoomPlate } from '../../../assets/roomPlate';
import { Tooltip } from '@material-ui/core';

interface IAnnouncementChatViewProps {
	socket: WebSocket | null;
	space: string;
	messages: MessageType.IMessage[];
	className?: string;
	height?: number;
}

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		flexGrow: 1
	},
	messages: {
		overflow: 'auto',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	inputArea: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		backgroundImage: `url(${RoomPlate})`,
		backgroundSize: 'cover'
	},
	textInput: {
		flexGrow: 5,
		margin: theme.spacing(1)
	},
	sendButton: {
		flex: 1,
		alignContent: 'flex-end',
		marginRight: '10px'
	},
	'@global': {
		'.MuiInputBase-input': {
			color: theme.palette.primary.contrastText
		},
		'.MuiOutlinedInput-root': {
			borderRadius: '8px'
		},
		'.MuiOutlinedInput-notchedOutline': {
			boxShadow: 'inset 0 0 3px #000000'
		},
		'.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderWidth: 0,
			boxShadow: 'inset 0 0 3px #000000'
		}
	},
	inputContainer: {
		width: '20vw'
	}
}));

const AnnouncementChatView: React.FC<IAnnouncementChatViewProps> = ({
	socket,
	space,
	messages,
	height
}) => {
	const classes = useStyles();

	const { state } = useGlobalState();

	const messageContainer = useRef<any>();

	const [textMessage, setTextMessage] = useState<string>('');
	const [replyMessage, setReplyMessage] = useState<MessageType.IMessage | undefined>(undefined);

	/**
	 * Handles The textmessage being set with a 1000 limit of characters
	 * @param event - input event
	 */
	const handleChangeTextMessage = (event: any) => {
		const message: string = event.target.value;

		if (message.length < 1000) {
			setTextMessage(message);
		}
	};

	/**
	 * Handles sending the message through the socket
	 */
	const handleSendMessage = (): void => {
		const messageTrim = textMessage.trim();
		if (messageTrim !== '') {
			const message: MessageType.ISendChatMessage = {
				type: 'send-chat',
				space: space,
				data: {
					message: messageTrim
				}
			};

			if (replyMessage) {
				message.data.responseTo = replyMessage.id;
				setReplyMessage(undefined);
			}

			if (socket) {
				socket.send(JSON.stringify(message));
			}

			setTextMessage('');
		}
	};

	// Scroll down to the last message if needed when messages are added
	useEffect(() => {
		if (messageContainer) {
			const scroll =
				messageContainer.current.scrollHeight - messageContainer.current.clientHeight;
			messageContainer.current.scrollTo(0, scroll);
		}
	}, [messages]);

	return (
		<div className={classes.container} style={{ height }}>
			<div ref={messageContainer} className={classes.messages}>
				{messages.map(message => (
					<AnnouncementChatCard key={message.time.toISOString()} message={message} />
				))}
			</div>
			{state.user?.role !== 'participant' && (
				<div className={classes.inputContainer}>
					<div className={classes.inputArea}>
						<div className={classes.textInput}>
							<TextField
								value={textMessage}
								onChange={handleChangeTextMessage}
								variant="outlined"
								placeholder="Type Message"
								fullWidth
								onKeyPress={e => (e.key === 'Enter' ? handleSendMessage() : null)}
							/>
						</div>
						<div className={classes.sendButton}>
							<Tooltip title="Send Message">
								<Button
									variant="contained"
									disableTouchRipple
									onClick={handleSendMessage}
								>
									Send
								</Button>
							</Tooltip>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AnnouncementChatView;
