import { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useGlobalState } from './contexts/GlobalStateProvider';
import { DataStateProvider } from './contexts/DataProvider';
import { SnackbarProvider } from 'notistack';

import { SocketManager } from './contexts/SocketManager';

import Login from './views/Login';
import Dashboard from './views/Dashboard';
import ResetPassword from './views/ResetPassword';
import GeneralRoom from './views/GeneralRoom';
import GameRoom from './views/GameRoom';
import FacilitatorView from './views/FacilitatorView';
import ParticipantLogin from './views/ParticipantLogin';
import WaitingRoom from './views/WaitingRoom';

import { IStaff } from './interfaces/staff';

import axios from 'axios';
import { IParticipant } from './interfaces/participant';
import SupervisorNavigation from './views/SupervisorNavigation';

const Routing = () => {
	const [showApp, setShowApp] = useState<boolean>(false);
	const { state, setState } = useGlobalState();

	// Check user session before proceeding
	useEffect(() => {
		axios
			.get('/api/me')
			.then(response => {
				const type: 'participant' | 'staff' = response.data.type;

				if (type === 'participant') {
					const userData: IParticipant = response.data.data;
					setState(() => ({
						user: {
							id: userData.id!,
							email: userData.email!,
							firstName: userData.firstName!,
							lastName: userData.lastName!,
							role: type
						}
					}));
				} else if (type === 'staff') {
					const userData: IStaff = response.data.data;

					setState(() => ({
						user: {
							id: userData.id,
							email: userData.email,
							firstName: userData.firstName,
							lastName: userData.lastName,
							role: userData.role
						}
					}));
				}

				setShowApp(true);
			})
			.catch(err => {
				setShowApp(true);
			});
	}, [setState]);

	return (
		<Fragment>
			{showApp && (
				<Router>
					<SnackbarProvider maxSnack={5} preventDuplicate hideIconVariant>
						<SocketManager
							value={{
								socket: null,
								callObject: null,
								rooms: {
									staffChatRoom: undefined,
									mainStage: undefined,
									greenRoom: undefined,
									announcementRoom: undefined,
									experienceRooms: []
								}
							}}
						>
							<Switch>
								<Route exact path="/login" component={Login} />
								<Route
									path="/dashboard"
									render={() =>
										state.user?.email !== undefined ? (
											<DataStateProvider>
												<Dashboard />
											</DataStateProvider>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route path="/reset-password" component={ResetPassword} />
								<Route path="/participant-login" component={ParticipantLogin} />
								<Route
									path="/main-stage"
									render={() =>
										state.user?.email !== undefined ? (
											<GeneralRoom roomType="Main Stage" />
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									path="/green-room"
									render={() =>
										state.user?.email !== undefined ? (
											<GeneralRoom roomType="Green Room" />
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									path="/experience-room"
									render={() =>
										state.user?.email !== undefined ? (
											<GameRoom />
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									path="/facilitator-view"
									render={() =>
										state.user?.email !== undefined ? (
											<FacilitatorView />
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									path="/waiting-room"
									render={() =>
										state.user?.email !== undefined ? (
											<WaitingRoom />
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									path="/supervisor-navigation"
									render={() =>
										state.user?.email !== undefined ? (
											<SupervisorNavigation />
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route exact path="/">
									<Redirect to="/login" />
								</Route>
							</Switch>
						</SocketManager>
					</SnackbarProvider>
				</Router>
			)}
		</Fragment>
	);
};

export default Routing;
