import { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';

const DeviceDropdown = ({ deviceType, heading, onSelect, getValue }: any) => {
	return (
		<Fragment>
			<Typography variant="subtitle1">{heading}</Typography>
			<select onChange={onSelect} value={getValue}>
				{deviceType &&
					deviceType.map((a: any) => (
						<option key={a.deviceId} value={a.deviceId}>
							{a.label}
						</option>
					))}
			</select>
		</Fragment>
	);
};

export default DeviceDropdown;
