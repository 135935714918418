import React, { Fragment, useState } from 'react';

import { useDataState } from '../../contexts/DataProvider';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EmailIcon from '@material-ui/icons/Email';

import TabPanel from '../TabPanel';
import RoomTable from './RoomTable';
import ParticipantTable from './ParticipantTable';
import Details from '../Details';
import Confirmation from '../Confirmation/Confirmation';
import { required } from '../../utils/validators';

import Form from '../Form';
import Field from '../Field';

import { participantHeaderCells } from '../../utils/tableData';
import { ICompany } from '../../interfaces/company';
import { IParticipant } from '../../interfaces/participant';

import { participantFields, roomFields } from '../../utils/inputFields';

import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { IRoom } from '../../interfaces/room';
import { IEvent } from '../../interfaces/event';
import UploadCSV from '../UploadCSV';
import { Tab, Tabs } from '@material-ui/core';

interface IParticipantInfoProps {
	/** The current tab value */
	tabValue: number;

	/** The current selected company */
	selectedCompany: ICompany | null;

	/** The Current Selected Event */
	selectedEvent: IEvent | null;

	/** When a request has completed */
	onRequestComplete: (severity: 'success' | 'error', message: string) => void;

	onUpdateEvent: (newSelectedEvent: IEvent | null) => void;
}

const useStyles = makeStyles(theme => ({
	title: {
		marginTop: '25px'
	},
	fill: {
		width: '100%'
	},
	spacing: {
		marginTop: '20px',
		marginBottom: '20px'
	},
	eventPaper: {
		marginTop: '20px',
		paddingBottom: '30px'
	},
	participantDetailPaper: {
		marginTop: '25px',
		marginBottom: '25px',
		padding: '10px',
		width: '100%'
	},
	roomTable: {
		marginBottom: '25px'
	}
}));

const EventInfo: React.FC<IParticipantInfoProps> = ({
	tabValue,
	selectedCompany,
	selectedEvent,
	onRequestComplete,
	onUpdateEvent
}) => {
	const classes = useStyles();

	const { data, setData } = useDataState();

	const [selectedParticpant, setSelectedParticipant] = useState<any[]>([]);
	const [isEditParticipantMode, setIsEditParticipantMode] = useState<boolean>(false);
	const [showParticipantDetails, setShowParticipantDetails] = useState<boolean>(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
	const [isSupervisorRoom, setIsSupervisorRoom] = useState<boolean>(false);
	const [addParticipantTabValue, setAddParticipantTabValue] = useState<number>(0);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [emailMessage, setEmailMessage] = useState<string>('');
	const [onpenEmailMessage, setOpenEmailMessage] = useState<boolean>(false);

	/**
	 * Handles The email message
	 * @param event - input event
	 */
	const handleChangeEmailMessage = (event: any) => {
		setEmailMessage(event.target.value);
	};

	/**
	 * Show the participant details panel for adding or editing
	 * @param {boolean} editMode - If the user is in edit mode or not
	 */
	const handleParcipantDetails = (editMode: boolean) => {
		setIsEditParticipantMode(editMode);

		// Get values of selected participant if in edit mode
		participantFields.firstName.value = editMode ? getSelectedParcipant()?.firstName : '';
		participantFields.lastName.value = editMode ? getSelectedParcipant()?.lastName : '';
		participantFields.email.value = editMode ? getSelectedParcipant()?.email : '';
		participantFields.position.value = editMode ? getSelectedParcipant()?.position : '';

		if (!editMode && data.rooms) {
			const roomNames: string[] = [];
			data.rooms.forEach(room => {
				roomNames.push(room.name);
			});

			participantFields.room.options = roomNames;
			participantFields.room.validation = [{ rule: required }];
		} else {
			participantFields.room.validation = [];
		}

		setShowParticipantDetails(true);
	};

	const handleToggleSupervisorRoom = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setIsSupervisorRoom(checked);
	};

	const eventHasSupervisorRoom = (): boolean | undefined => {
		let result = false;
		if (data.rooms) {
			data.rooms.forEach(room => {
				if (room.supervisor) {
					result = true;
				}
			});
		}

		return result;
	};

	/**
	 * Makes request to add room to the selected event (POST)
	 * @param {any} roomData - Information on the room to add
	 */
	const handleAddRoom = (roomData: any) => {
		if (selectedCompany && selectedEvent) {
			const createdRoom: IRoom = {
				name: roomData.name,
				supervisor: isSupervisorRoom
			};

			axios
				.post(
					`/api/company/${selectedCompany.id}/events/${selectedEvent.id}/rooms`,
					JSON.stringify(createdRoom),
					{ headers: { 'Content-Type': 'application/json' } }
				)
				.then(response => {
					const room: IRoom = response.data;

					if (data.rooms) {
						const newRoomList = [...data.rooms, room];
						setData(prevData => ({ ...prevData, rooms: newRoomList }));
					} else {
						const newRoomList = [room];
						setData(prevData => ({ ...prevData, rooms: newRoomList }));
					}

					setIsSupervisorRoom(false);
					onRequestComplete('success', 'Room has been added.');
				})
				.catch(err => {
					const code = err.response.status;
					setIsSupervisorRoom(false);
					if (code === 409) {
						onRequestComplete(
							'error',
							'Can not add room, room with this name already exists.'
						);
					} else {
						onRequestComplete('error', 'Failed to add room, please try again.');
					}
				});
		}
	};

	/**
	 * Makes request to delete room to the selected event (DELETE)
	 * @param {string} roomName - The name of the room to delete
	 */
	const handleDeleteRoom = (roomName: string) => {
		if (selectedCompany && selectedEvent) {
			axios
				.delete(
					`/api/company/${selectedCompany.id}/events/${selectedEvent.id}/rooms/${roomName}`
				)
				.then(response => {
					const newRoomList = data.rooms!.filter(room => room.name !== roomName);
					setData(prevData => ({ ...prevData, rooms: newRoomList }));
					onRequestComplete('success', 'Room Removed.');
				})
				.catch(err => {
					onRequestComplete('error', 'Failed to remove room, please try again.');
				});
		}
	};

	/**
	 * Makes request to add or modify participant to database (POST/PUT)
	 * @param {any} participantData - All the participant info needed to add or edit
	 */
	const handleAddParticipant = (participantData: any): void => {
		setIsLoading(true);
		if (selectedCompany) {
			if (isEditParticipantMode) {
				const editedParticipant = getSelectedParcipant();

				if (editedParticipant) {
					const newEditedParticipabt: IParticipant = {
						firstName: participantData.firstName,
						lastName: participantData.lastName,
						email: participantData.email,
						position: participantData.position
					};

					axios
						.put(
							`/api/company/${selectedCompany.id}/participants/${selectedParticpant}`,
							JSON.stringify(newEditedParticipabt),
							{ headers: { 'Content-Type': 'application/json' } }
						)
						.then(response => {
							const responseParticipant: IParticipant = response.data;
							const newParticipantList = [...data.participants!];
							const oldParticipantIndex = data.participants!.findIndex(
								participant => participant.id === selectedParticpant[0]
							);
							newParticipantList[oldParticipantIndex] = responseParticipant;
							setData(prevData => ({
								...prevData,
								participants: newParticipantList
							}));
							setShowParticipantDetails(false);
							setIsLoading(false);
							onRequestComplete('success', 'Participant Edited.');
						})
						.catch(err => {
							const code = err.response.status;
							setIsLoading(false);
							if (code === 409) {
								onRequestComplete(
									'error',
									'Can not update email, a participant with this email already exists.'
								);
							} else {
								onRequestComplete(
									'error',
									'Failed to update participant, please try again.'
								);
							}
						});
				}
			} else {
				const newParticipant: IParticipant = {
					firstName: participantData.firstName,
					lastName: participantData.lastName,
					email: participantData.email,
					position: participantData.position
				};

				axios
					.get(
						`/api/company/${selectedCompany.id}/participants?email=${newParticipant.email}`
					)
					.then(res => {
						const foundParticipants: IParticipant[] = res.data;

						if (foundParticipants.length === 0) {
							axios
								.post(
									`/api/company/${selectedCompany.id}/participants`,
									JSON.stringify(newParticipant),
									{ headers: { 'Content-Type': 'application/json' } }
								)
								.then(res => {
									const createdParticipant: IParticipant = res.data;
									addParticipantToEvent(
										selectedCompany,
										participantData.room,
										createdParticipant
									);
								})
								.catch(err => {
									setShowParticipantDetails(false);
									setIsLoading(false);

									console.log(err);

									onRequestComplete(
										'error',
										'Failed to add participant, please try again'
									);
								});
						} else {
							const participantInEvent = data.participants?.find(
								part => part.id === foundParticipants[0].id
							);

							if (participantInEvent) {
								const error = new Error('Partipant already in event');
								throw error;
							}
							addParticipantToEvent(
								selectedCompany,
								participantData.room,
								foundParticipants[0]
							);
						}
					})
					.catch(err => {
						setShowParticipantDetails(false);
						setIsLoading(false);

						console.log(err);

						if (err.message === 'Partipant already in event') {
							onRequestComplete('error', 'Participant is already in event');
						} else {
							onRequestComplete(
								'error',
								'Failed to add participant, please try again'
							);
						}
					});
			}
		}
	};

	const addParticipantToEvent = (
		company: ICompany,
		roomName: string,
		participant: IParticipant
	) => {
		axios
			.post(
				`/api/company/${company.id}/events/${
					selectedEvent!.id
				}/rooms/${roomName}/participants`,
				JSON.stringify({ id: participant.id }),
				{ headers: { 'Content-Type': 'application/json' } }
			)
			.then(response => {
				const selectedRoom = data.rooms!.find(room => room.name === roomName);

				if (selectedRoom) {
					if (selectedRoom.participants) {
						selectedRoom.participants.push(participant);
					} else {
						selectedRoom.participants = [participant];
					}

					const newRoomList = [...data!.rooms!];
					const roomIndex = newRoomList.findIndex(dataRoom => dataRoom.name === roomName);
					newRoomList[roomIndex] = selectedRoom;
					setData(prevData => ({ ...prevData, rooms: newRoomList }));

					participant.roomName = roomName;
					const newParticipantList = [...data.participants!, participant];
					setData(prevData => ({
						...prevData,
						participants: newParticipantList
					}));
					setShowParticipantDetails(false);
					setIsLoading(false);
					onRequestComplete('success', 'Participant Added and assigned to room.');
				}
			})
			.catch(err => {
				setShowParticipantDetails(false);
				setIsLoading(false);

				const newParticipantList = [...data.participants!, participant];
				setData(prevData => ({
					...prevData,
					participants: newParticipantList
				}));
				onRequestComplete(
					'error',
					'Participant was added, but not assigned to room, please try again.'
				);
			});
	};

	/**
	 * Makes a request to delete particpant from database (DELETE)
	 */
	const handleDeleteParticipant = (): void => {
		if (selectedCompany && selectedEvent && selectedParticpant.length === 1) {
			const participant = getSelectedParcipant();

			if (participant) {
				axios
					.delete(
						`/api/company/${selectedCompany.id}/events/${selectedEvent.id}/rooms/${participant.roomName}/participants/${selectedParticpant}`
					)
					.then(response => {
						const newParticipantList = data.participants!.filter(
							participant => participant.id !== selectedParticpant[0]
						);
						setData(prevData => ({ ...prevData, participants: newParticipantList }));
						setSelectedParticipant([]);
						setShowConfirmDelete(false);
						onRequestComplete('success', 'Participant Removed.');
					})
					.catch(err => {
						onRequestComplete(
							'error',
							'Failed to remove participant, please try again.'
						);
					});
			}
		}
	};

	/**
	 * Get the staff member that is selected in table
	 * @returns {IParticipant | undefined} - The selected participant or undefined if not found
	 */
	const getSelectedParcipant = (): IParticipant | undefined => {
		return data.participants!.find(participant => participant.id === selectedParticpant[0]);
	};

	/**
	 * Makes request to email invitations to event to participants
	 */
	const handleEmailParticipants = () => {
		// TO DO: Send Ids of participants to email
		if (selectedCompany && selectedEvent && selectedParticpant.length > 0) {
			const ids = {
				participants: selectedParticpant,
				message: emailMessage
			};
			axios
				.post(
					`/api/company/${selectedCompany.id}/events/${selectedEvent.id}/send_invitation_email_to_participants`,
					JSON.stringify(ids),
					{ headers: { 'Content-Type': 'application/json' } }
				)
				.then(res => {
					onRequestComplete('success', 'Emails have been sent to participants');
					setEmailMessage('');
					setOpenEmailMessage(false);
				})
				.catch(err => {
					onRequestComplete('error', 'Failed to email participants, please try again');
					setEmailMessage('');
					setOpenEmailMessage(false);
				});
		}
	};

	const handleTabChange = (newValue: number): void => {
		setAddParticipantTabValue(newValue);
	};

	const ManageParticipantsTable = () => (
		<Fragment>
			{showParticipantDetails && (
				<Grid container direction="column" alignItems="center">
					{!isLoading ? (
						<Grid container justify="center">
							<Grid item xs={8}>
								<Details
									header={
										<Grid item>
											<Tabs
												value={addParticipantTabValue}
												onChange={(
													event: React.ChangeEvent<{}>,
													value: any
												) => handleTabChange(value)}
												indicatorColor="secondary"
												textColor="secondary"
												variant="fullWidth"
											>
												<Tab
													label={
														isEditParticipantMode
															? 'Edit Participant'
															: 'Manually Add Participant'
													}
												/>
												<Tab label="Upload Participants via CSV" />
											</Tabs>
										</Grid>
									}
									title={
										addParticipantTabValue === 1
											? 'Upload CSV'
											: isEditParticipantMode
											? 'Edit Participant Details'
											: 'Enter Participant Details'
									}
									size={12}
									onClose={() => setShowParticipantDetails(false)}
								>
									<TabPanel value={addParticipantTabValue} index={0}>
										<Grid
											container
											style={{
												display: 'flex',
												flexDirection: 'column',
												margin: '1rem 0'
											}}
										>
											<Form
												onSubmit={handleAddParticipant}
												fields={participantFields}
												render={() => (
													<Fragment>
														<Grid
															container
															justify="space-around"
															alignItems="center"
														>
															<Grid item xs={12}>
																<TextField
																	value={
																		selectedCompany
																			? selectedCompany?.name
																			: ''
																	}
																	label="Company"
																	variant="outlined"
																	fullWidth
																	disabled
																/>
															</Grid>
														</Grid>

														<Grid container justify="center">
															<Grid item xs={6}>
																<Field
																	{...participantFields.firstName}
																/>
															</Grid>
															<Grid item xs={6}>
																<Field
																	{...participantFields.lastName}
																/>
															</Grid>
														</Grid>
														<Grid container justify="center">
															<Grid item xs={6}>
																<Field
																	{...participantFields.email}
																/>
															</Grid>
															<Grid item xs={6}>
																<Field
																	{...participantFields.position}
																/>
															</Grid>
														</Grid>
														{!isEditParticipantMode && (
															<Grid
																container
																justify="space-around"
																alignItems="center"
															>
																<Grid item xs={12}>
																	<Field
																		{...participantFields.room}
																	/>
																</Grid>
															</Grid>
														)}
														<Grid container justify="flex-end">
															<Grid item>
																<Button
																	type="submit"
																	variant="contained"
																	disableTouchRipple
																>
																	{isEditParticipantMode
																		? 'Edit'
																		: 'Add'}
																</Button>
															</Grid>
														</Grid>
													</Fragment>
												)}
											/>
										</Grid>
									</TabPanel>
									<TabPanel value={addParticipantTabValue} index={1}>
										<UploadCSV
											selectedCompany={selectedCompany}
											selectedEvent={selectedEvent}
											onRequestComplete={onRequestComplete}
											toggleLoading={setIsLoading}
											toggleDetails={setShowParticipantDetails}
											onUpdateEvent={onUpdateEvent}
										/>
									</TabPanel>
								</Details>
							</Grid>
						</Grid>
					) : (
						<Grid item>
							<CircularProgress size={100} color="primary" />
						</Grid>
					)}
				</Grid>
			)}
		</Fragment>
	);
	return (
		<div className={classes.spacing} style={{ width: '100%' }}>
			<TabPanel value={tabValue} index={0}>
				<Grid container justify="center">
					<Grid item xs={6}>
						<Grid
							container
							direction="column"
							justify="center"
							className={classes.spacing}
						>
							<Grid container justify="center">
								<Grid item>
									<Typography variant="h6">Rooms</Typography>
									<hr />
								</Grid>
							</Grid>
							<Grid container className={classes.spacing}>
								<Grid item xs={12}>
									<Form
										onSubmit={handleAddRoom}
										fields={roomFields}
										render={() => (
											<Fragment>
												<Grid
													container
													justify="space-between"
													alignItems="center"
													spacing={5}
												>
													<Grid item xs={7}>
														<Field {...roomFields.name} />
													</Grid>
													<Grid item xs={3}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={isSupervisorRoom}
																	onChange={
																		handleToggleSupervisorRoom
																	}
																	name="checkedB"
																	color="primary"
																/>
															}
															label="Supervisor Room"
															disabled={eventHasSupervisorRoom()}
														/>
													</Grid>
													<Grid item xs={2}>
														<Button
															type="submit"
															variant="contained"
															disableTouchRipple
														>
															Add
														</Button>
													</Grid>
												</Grid>
											</Fragment>
										)}
									/>
								</Grid>
							</Grid>
							<Grid container className={classes.spacing}>
								<Grid item xs={12}>
									<RoomTable
										roomData={data.rooms ? data.rooms : []}
										selectedCompany={selectedCompany!}
										selectedEvent={selectedEvent!}
										onRemoveRoom={handleDeleteRoom}
										onRequestComplete={onRequestComplete}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</TabPanel>

			<TabPanel value={tabValue} index={1}>
				<Grid container justify="center">
					<Grid item xs={9}>
						<Grid
							container
							direction="column"
							justify="center"
							className={classes.spacing}
						>
							<Grid container justify="center">
								<Grid item>
									<Typography variant="h6">Participants</Typography> <hr />
								</Grid>
							</Grid>

							<Grid container justify="flex-start" className={classes.spacing}>
								<Grid item xs={12}>
									<ParticipantTable
										size={12}
										headers={participantHeaderCells}
										participantData={data.participants ? data.participants : []}
										selectedCompany={selectedCompany!}
										selectedEvent={selectedEvent!}
										selected={selectedParticpant}
										onSelectedChange={newSelected =>
											setSelectedParticipant(newSelected)
										}
										onRequestComplete={onRequestComplete}
										excludedColumns={['companyId', 'enabled', 'roomName']}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								justify="space-between"
								spacing={2}
								className={classes.spacing}
							>
								<Grid item>
									<Button
										variant="contained"
										disableTouchRipple
										startIcon={<AccountBoxIcon />}
										onClick={() => handleParcipantDetails(false)}
									>
										Add Participant(s)
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										disableTouchRipple
										startIcon={<EmailIcon />}
										disabled={selectedParticpant.length === 0}
										onClick={() => setOpenEmailMessage(true)}
									>
										Email Participant(s)
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										disableTouchRipple
										startIcon={<EditIcon />}
										disabled={
											selectedParticpant.length > 1 ||
											selectedParticpant.length === 0
										}
										onClick={() => handleParcipantDetails(true)}
									>
										Edit Participant
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										disableTouchRipple
										startIcon={<DeleteIcon />}
										disabled={
											selectedParticpant.length > 1 ||
											selectedParticpant.length === 0
										}
										onClick={() => setShowConfirmDelete(true)}
									>
										Remove Participant
									</Button>
								</Grid>
							</Grid>
							<ManageParticipantsTable />
						</Grid>
					</Grid>
				</Grid>
			</TabPanel>
			<Dialog
				open={onpenEmailMessage}
				onClose={() => setOpenEmailMessage(false)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Send Invitation Email to Participants
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Add a custom message to let your participants know about the event, this
						will be added to the email.
					</DialogContentText>
					<TextField
						autoFocus
						multiline
						value={emailMessage}
						onChange={handleChangeEmailMessage}
						rows={5}
						margin="dense"
						id="name"
						label="Message"
						type="text"
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenEmailMessage(false)} color="primary">
						Cancel
					</Button>
					<Button onClick={handleEmailParticipants} color="primary">
						Send
					</Button>
				</DialogActions>
			</Dialog>

			<Confirmation
				visiblity={showConfirmDelete}
				title="Remove this Particpant?"
				content="Are you sure you want to remove this participant from the event?"
				onConfirm={handleDeleteParticipant}
				onCancel={() => setShowConfirmDelete(false)}
			/>
		</div>
	);
};

export default EventInfo;
