import React, { useState, useRef, useEffect } from 'react';

import Badge from '@material-ui/core/Badge';
import { IMessage } from '../../../interfaces/message';
import {
	AnnouncementTabDefault,
	AnnouncementTabHover,
	AnnouncementTabPressed
} from '../../../assets/icons/chatTabs';
import TabIcon from '../../Icons/TabIcon';
import { makeStyles } from '@material-ui/core';

interface IChatViewIconProps {
	messages: IMessage[];
	isOpen: boolean;
}

const useStyles = makeStyles(theme => ({
	badge: {
		top: '30%',
		right: '18%',
		transform: 'scale(.8) translate(60%, -50%)',
		[theme.breakpoints.up('lg')]: {
			top: '25%',
			right: '18%',
			transform: 'scale(.8) translate(60%, -50%)'
		}
	}
}));

const AnnouncementViewIcon: React.FC<IChatViewIconProps> = ({ messages, isOpen }) => {
	const classes = useStyles();
	const messageTotal = useRef<number>(messages.length);
	const [unreadMessage, setUnreadMessage] = useState<number>(0);

	useEffect(() => {
		if (!isOpen) {
			if (messageTotal.current < messages.length) {
				setUnreadMessage(prevState => prevState + 1);
			}
		} else {
			messageTotal.current = messages.length;
			setUnreadMessage(0);
		}
	}, [messages, isOpen]);

	return (
		<Badge
			badgeContent={unreadMessage}
			color="primary"
			overlap="circle"
			classes={{ anchorOriginTopRightCircle: classes.badge }}
		>
			<TabIcon
				defaultIcon={AnnouncementTabDefault}
				hoverIcon={AnnouncementTabHover}
				isPressed={isOpen}
				pressedIcon={AnnouncementTabPressed}
			/>
		</Badge>
	);
};

export default AnnouncementViewIcon;
