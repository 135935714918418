import React, { Fragment, useState } from 'react';
import { GlobalStateProvider } from './contexts/GlobalStateProvider';

import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';

import Routing from './Routing';
import DailyIframe from '@daily-co/daily-js';
import ErrorDialog from './components/ErrorDialog';
import { List, ListItem, Typography } from '@material-ui/core';
import { useEffect } from 'react';

const App: React.FC = () => {
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		const supportedBrowser = DailyIframe.supportedBrowser().supported;

		!supportedBrowser && setError(true);
	}, [setError]);

	return (
		<Fragment>
			<ThemeProvider theme={theme}>
				<GlobalStateProvider>
					<Routing />
					<ErrorDialog
						content={
							<Fragment>
								<Typography>
									You'll need to <b>upgrade your browser</b> before joining the
									experience!
									<br />
									Please download one of the following. Access your sign-in link
									from your new browser once it's downloaded.
								</Typography>
								<List>
									<ListItem disableGutters>
										•
										<a href="https://www.google.ca/chrome/?brand=FHFK&gclsrc=ds&gclsrc=ds">
											Google Chrome
										</a>
									</ListItem>
									<ListItem disableGutters>
										•
										<a href="https://www.microsoft.com/en-us/edge">
											Microsoft Edge
										</a>
									</ListItem>
									<ListItem disableGutters>
										•
										<a href="https://www.mozilla.org/en-CA/firefox/new/">
											Firefox
										</a>
									</ListItem>
									<ListItem disableGutters>
										•
										<a href="https://support.apple.com/downloads/safari">
											Safari (for Mac)
										</a>
									</ListItem>
								</List>
							</Fragment>
						}
						visibility={error}
					/>
				</GlobalStateProvider>
			</ThemeProvider>
		</Fragment>
	);
};

export default App;
