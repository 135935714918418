import { Fragment } from 'react';
import { List, ListItem, Typography } from '@material-ui/core';

import ErrorDialog from './ErrorDialog';

interface IScreenShareErrorHandlingProps {
	onClose: () => void;
	visibility: boolean;
}

const ScreenShareErrorHandling: React.FC<IScreenShareErrorHandlingProps> = ({
	onClose,
	visibility
}) => {
	return (
		<ErrorDialog
			content={
				<Fragment>
					<Typography>
						It appears you're using a browser that doesn't support this feature!
					</Typography>
					<Typography>
						Please download one of the following browsers and reopen the application
						from there:
						<List>
							<ListItem disableGutters>
								•
								<a href="https://www.google.ca/chrome/?brand=FHFK&gclsrc=ds&gclsrc=ds">
									Google Chrome
								</a>
							</ListItem>
							<ListItem disableGutters>
								•<a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>
							</ListItem>
						</List>
					</Typography>
				</Fragment>
			}
			onClose={onClose}
			visibility={visibility}
		/>
	);
};

export default ScreenShareErrorHandling;
