import React, { Fragment } from 'react';

import { useGlobalState } from '../../../contexts/GlobalStateProvider';

import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { IMessage } from '../../../interfaces/message';

import { ChatlogPaper } from '../../../assets/chatPanel';
import Icon from '../../Icons/Icon';
import {
	ActionDefault,
	ActionPressed,
	ReplyArrowDefault,
	ReplyArrowHover
} from '../../../assets/icons/chatMessage';
import ChatDivider from '../../Icons/ChatDivider';

interface IChatCardProps {
	message: IMessage;
	makeActions: boolean;
	onMakeActionClicked: (messageId: number) => void;
	onReplyClicked: (message: IMessage) => void;
}

const useStyles = makeStyles(theme => ({
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%'
	},
	replyRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%'
	},
	spacing: {
		margin: theme.spacing(1)
	},
	name: {
		fontWeight: 500,
		textTransform: 'capitalize'
	},
	role: {
		color: '#565656',
		textTransform: 'capitalize'
	},
	action: {
		backgroundImage: `url(${ChatlogPaper})`,
		backgroundSize: 'contain',
		margin: theme.spacing(1.5),
		padding: theme.spacing(2),
		boxShadow: 'inset 0 0 20px #000000',
		border: `1px solid ${theme.palette.secondary.dark}`,
		borderRadius: '8px',
		lineHeight: 1.25,
		maxWidth: '17vw',
		height: 'auto'
	},
	message: {
		backgroundImage: `url(${ChatlogPaper})`,
		backgroundSize: 'contain',
		margin: theme.spacing(1.5),
		padding: theme.spacing(2),
		lineHeight: 1.25,
		maxWidth: '17vw',
		height: 'auto'
	},
	iconContainer: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	replyTo: {
		display: 'flex',
		justifyContent: 'flex-start'
	},
	actionText: {
		fontWeight: 'bold',
		fontStyle: 'italic',
		marginRight: '5px'
	},
	icon: {
		height: 30,
		fontSize: '2.25rem',
		cursor: 'pointer'
	},
	time: {
		color: '#565656'
	},

	content: {
		fontWeight: 500,
		margin: theme.spacing(1, 0)
	}
}));

const ChatCard: React.FC<IChatCardProps> = ({
	message,
	makeActions,
	onMakeActionClicked,
	onReplyClicked
}) => {
	const classes = useStyles();
	const { state } = useGlobalState();

	return (
		<Paper className={message.isAction ? classes.action : classes.message}>
			{/* Reply  */}
			{message.replyTo && (
				<Grid>
					<Grid item className={classes.replyTo}>
						<Typography variant="body2" color="primary">
							<b>RE: {message.replyTo.from?.name}</b>
						</Typography>

						<Typography variant="body1">
							<i>&gt;&gt; "{message.replyTo.message}"</i>
						</Typography>
					</Grid>
				</Grid>
			)}
			{/* message container */}
			<Grid>
				<Grid item className={classes.row}>
					{/* first row: name, time */}
					<Typography variant="subtitle1" className={classes.name}>
						{message.from?.name}
					</Typography>
					<Typography variant="body2" className={classes.time}>
						{message.time.toLocaleString()}
					</Typography>
				</Grid>

				<Grid item className={classes.row}>
					{/* second row: role, icons */}
					<Typography variant="body2" className={classes.role}>
						{message.from?.role}
					</Typography>
					<Grid item className={classes.iconContainer}>
						{makeActions && (
							<Fragment>
								{message.isAction ? (
									<Typography variant="body1" className={classes.actionText}>
										Action
									</Typography>
								) : (
									<Tooltip title="Make Message a Action">
										<Grid item>
											<Icon
												viewbox="0 0 150 150"
												defaultIcon={ActionDefault}
												hoverIcon={ActionPressed}
												onClick={() => onMakeActionClicked(message.id)}
												className={classes.icon}
											/>
										</Grid>
									</Tooltip>
								)}
							</Fragment>
						)}
						{state.user?.role !== 'participant' && (
							<Tooltip title="Reply to Message">
								<Grid item>
									<Icon
										viewbox="0 0 150 150"
										defaultIcon={ReplyArrowDefault}
										hoverIcon={ReplyArrowHover}
										onClick={() => onReplyClicked(message)}
										className={classes.icon}
									/>
								</Grid>
							</Tooltip>
						)}
					</Grid>
				</Grid>

				<ChatDivider />

				<Grid item style={{ wordWrap: 'break-word' }}>
					<Typography variant="body2" className={classes.content}>
						{message.message}
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default ChatCard;
