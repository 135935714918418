export interface IHeader {
    /* To reference the header for sorting */
    id: string;

    /* The label to show on the table */
    label: string;
}

export const staffHeaderCells: IHeader[] = [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'email', label: 'Email' },
    { id: 'role', label: 'Role' },
    { id: 'enabled', label: 'Enabled' },
    { id: 'passwordReset', label: 'Password Reset' }
];

export const participantHeaderCells: IHeader[] = [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'email', label: 'Email' },
    { id: 'position', label: 'Position' },
];

export const contactHeaderCells: IHeader[] = [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'email', label: 'Email' },
    { id: 'division', label: 'Division' },
    { id: 'phoneNumber', label: 'Phone Number' },
    { id: 'cellNumber', label: 'Cell Number' }
];

export const roomHeaderCells: IHeader[] = [
    { id: 'room', label: 'Room' },
    { id: 'assignment', label: 'Team Assignment' }
];

export const eventNavigationHeaderCells: IHeader[] =[
    {id: 'name', label: 'Room'},
    {id: 'staff', label: 'Facilitator'},
    {id: 'participants', label: 'Participants'}
];
