import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IConfirmationProps {
	/* To show the dialogue component or not */
	visiblity: boolean;

	/* The title of the dialouge component */
	title: string;

	/* The body text of the dialouge component */
	content: string | React.ReactNode;

	/* If user confirms the action */
	onConfirm: () => void;

	/*Confirmation message- default 'Yes' */
	confirmationMsg?: string;

	/*Cancel message- default 'Cancel' */
	cancelMsg?: string;

	/* If user cancels the action */
	onCancel: () => void;
}

const Confirmation: React.FC<IConfirmationProps> = ({
	visiblity,
	title,
	content,
	onConfirm,
	confirmationMsg = 'Yes',
	cancelMsg = 'Cancel',
	onCancel
}) => {
	return (
		<Dialog open={visiblity} onClose={onCancel}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				{content === String ? <DialogContentText>{content}</DialogContentText> : content}
			</DialogContent>
			<DialogActions>
				<Button onClick={onConfirm} color="primary">
					{confirmationMsg}
				</Button>
				<Button onClick={onCancel} color="primary">
					{cancelMsg}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Confirmation;
