import React, { Fragment, useState, useEffect, useReducer, useRef, useLayoutEffect } from 'react';
import { useSocketManager } from '../contexts/SocketManager';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import VideoPanel from '../components/Video/VideoPanel';
import VideoTray from '../components/Video/VideoTray';
import Desktop from '../components/Desktop/Desktop';
import StaffChat from '../components/StaffChat/StaffChat';
import RoomManager from '../components/RoomSystems/GameRoom/RoomManager';

import { IDocument } from '../interfaces/document';
import { IAsset } from '../interfaces/asset';

import { matchImage, matchVideo } from '../utils/fileMatch';
import {
	callReducer,
	containsScreenShare,
	initialCallState,
	PARTICIPANTS_CHANGE
} from '../utils/callState';
import CabinImage from '../components/CabinImage';
import useWindowDimensions from '../utils/useWindowDimensions';

const useStyles = makeStyles(theme => ({
	page: {
		height: '100vh'
	},
	videoPanel: {
		width: '80vw',
		position: 'absolute',
		top: 0
	},

	fullHeight: {
		height: '100%'
	},
	screen: {
		height: '100%'
	},
	cabinArtwork: {
		width: '80%',
		height: '100vh',
		objectFit: 'cover'
	},
	tray: {
		width: '80vw'
	}
}));

const GameRoom: React.FC = () => {
	const classes = useStyles();
	const { socket, connect, leaveRooms, rooms, getExperienceRoom, callObject, setDocuments } =
		useSocketManager();
	const { height, width } = useWindowDimensions();
	const videoTrayRef = useRef<HTMLDivElement | null>(null);
	const buttonTrayRef = useRef<HTMLDivElement | null>(null);
	const sidePanelRef = useRef<HTMLDivElement | null>(null);
	const [windowDimensions, setWindowDimensions] = useState<{ height: number; width: number }>({
		height,
		width
	});
	const [videoPanelHeight, setVideoPanelHeight] = useState<number>(0);
	const [buttonTrayHeight, setButtonTrayHeight] = useState<number>(0);
	const [panelWidth, setPanelWidth] = useState<number>(0);
	const [previewDocument, setPreviewDocument] = useState<IDocument | null>(null);
	const [callState, dispatch] = useReducer(callReducer, initialCallState);

	//  grabs and sets the values of the side panel's header to calculate
	// 	what the exact height of the chat component should be.
	useLayoutEffect(() => {
		if (sidePanelRef.current !== null) {
			setPanelWidth(sidePanelRef.current.clientWidth);
		}
		if (videoTrayRef.current !== null) {
			setVideoPanelHeight(videoTrayRef.current.clientHeight);
		}
		if (buttonTrayRef.current !== null) {
			setButtonTrayHeight(buttonTrayRef.current.clientHeight);
		}

		setWindowDimensions({ height, width });
	}, [height, width]);

	const getDesktopHeight = windowDimensions.height - videoPanelHeight - buttonTrayHeight;
	const getDesktopWidth = windowDimensions.width - panelWidth;

	useEffect(() => {
		if (!socket) {
			connect();
		}
	}, [socket, connect]);

	useEffect(() => {
		return () => {
			leaveRooms('Experience Room');
		};
	}, [leaveRooms]);

	useEffect(() => {
		if (!callObject) return;

		const handleNewParticipantsState = (): void => {
			// listen for particpant changes to update callState as participants are joining
			dispatch({
				type: PARTICIPANTS_CHANGE,
				callObject: callObject
			});
		};

		// Use initial state
		handleNewParticipantsState();

		// Listen for changes in state
		callObject.on('participant-updated', handleNewParticipantsState);

		// Stop listening for changes in state
		return function cleanup() {
			callObject.off('participant-updated', handleNewParticipantsState);
		};
	}, [callObject]);

	/**
	 * When facilitators want to preview assets (seperate from adding to desktop)
	 * @param {IAsset} asset - Asset to preview
	 */
	const handlePreviewAsset = (asset: IAsset): void => {
		const isImage = matchImage(asset.url);
		const isVideo = matchVideo(asset.url);

		const newDocument: IDocument = {
			name: asset.key,
			url: asset.url,
			type: isImage ? 'image' : isVideo ? 'video' : 'notSupported',
			posX: 0,
			posY: 0,
			width: 0,
			height: 0
		};

		setPreviewDocument(newDocument);
	};

	/**
	 * Prioritize document that user clicks to render ontop of the rest
	 * @param {IDocument} document - the selected document
	 */
	const handleDocumentSelected = (document: IDocument): void => {
		const documents = getExperienceRoom()!.documents;
		const newDocumentlist = [...documents];

		newDocumentlist.push(newDocumentlist.splice(newDocumentlist.indexOf(document), 1)[0]);

		setDocuments(newDocumentlist);
	};

	/**
	 * Removes the current document in preview
	 */
	const handleRemovePreviewDocument = () => {
		setPreviewDocument(null);
	};

	return (
		<Grid container className={classes.page}>
			{getExperienceRoom() !== null && rooms.announcementRoom && (
				<Fragment>
					<Grid container className={classes.videoPanel} ref={videoTrayRef}>
						<Grid item xs={12} className={classes.fullHeight}>
							<VideoPanel callObject={callObject} staffPanel={false} />
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} className={classes.screen}>
							<CabinImage className={classes.cabinArtwork} />
							<Desktop
								socket={socket}
								space={getExperienceRoom()!.space}
								documents={getExperienceRoom()!.documents}
								previewDocument={previewDocument}
								onRemovePreviewDocument={handleRemovePreviewDocument}
								onDocumentSelected={handleDocumentSelected}
								hideAssets={containsScreenShare(callState?.callList)}
								callObject={callObject}
								desktopHeight={getDesktopHeight - 60}
								desktopWidth={getDesktopWidth - 100}
							/>
						</Grid>
						<Grid item xs={12} className={classes.tray} ref={buttonTrayRef}>
							<VideoTray
								callObject={callObject}
								socket={socket}
								space={getExperienceRoom()!.space}
								needAssistance={getExperienceRoom()!.roomAssistance!}
								trayRef={buttonTrayRef}
							/>

							{rooms.staffChatRoom && (
								<StaffChat socket={socket} roomData={rooms.staffChatRoom} />
							)}
						</Grid>
					</Grid>
					<Grid container>
						<Grid item>
							<RoomManager
								socket={socket}
								roomData={getExperienceRoom()!}
								announcementRoomData={rooms.announcementRoom}
								onPreviewAsset={handlePreviewAsset}
								panelRef={sidePanelRef}
							/>
						</Grid>
					</Grid>
				</Fragment>
			)}
		</Grid>
	);
};

export default GameRoom;
