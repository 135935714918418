import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IConfirmationProps {
	/* To show the dialogue component or not */
	visiblity: boolean;

	/* The title of the dialouge component */
	title: string;

	/* The body text of the dialouge component */
	content: string | React.ReactNode;

	/* If user confirms the action */
	onAcknowledge: () => void;

	/*Confirmation message- default 'Yes' */
	acknowledgeMsg?: string;
}

const Acknowledgement: React.FC<IConfirmationProps> = ({
	visiblity,
	title,
	content,
	onAcknowledge,
	acknowledgeMsg = 'Ok'
}) => {
	return (
		<Dialog open={visiblity}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				{content === String ? <DialogContentText>{content}</DialogContentText> : content}
			</DialogContent>
			<DialogActions>
				<Button onClick={onAcknowledge} color="primary">
					{acknowledgeMsg}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Acknowledgement;
