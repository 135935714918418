import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

interface IErrorDialogProps {
	visibility: boolean;
	// Optional- because some dialog windows shouldn't be closable (i.e. if the user NEEDS to upgrade their browser before continuing.)
	onClose?: () => void;
	title?: string;
	content: string | React.ReactNode;
}

const useStyles = makeStyles(theme => ({
	dialog: {
		'& a': {
			color: theme.palette.primary.main,
			'&:hover': {
				color: theme.palette.primary.dark
			}
		}
	},
	title: {
		color: theme.palette.error.main
	}
}));

const ErrorDialog: React.FC<IErrorDialogProps> = ({
	visibility,
	onClose,
	title = 'There was an Error...',
	content
}) => {
	const classes = useStyles();
	return (
		<Dialog open={visibility} onClose={onClose} className={classes.dialog}>
			<DialogTitle className={classes.title}>{title}</DialogTitle>
			<DialogContent>
				{content === String ? <DialogContentText>{content}</DialogContentText> : content}
			</DialogContent>
			<DialogActions>
				{onClose && (
					<Button onClick={onClose} color="primary">
						Okay
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default ErrorDialog;
