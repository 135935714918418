import React, { createContext, Dispatch, SetStateAction, useState, useContext } from 'react';
import { ICompany } from '../interfaces/company';
import { ICompanyContact } from '../interfaces/companyContact';
import { IEvent } from '../interfaces/event';
import { IParticipant } from '../interfaces/participant';
import { IRoom } from '../interfaces/room';
import { IStaff } from '../interfaces/staff';

export interface IDataState {
	companies: ICompany[];
	staff: IStaff[];
	contacts: ICompanyContact[];
	events: IEvent[];
	rooms: IRoom[];
	participants: IParticipant[];
}

// create the context to be able to view and edit global state
const DataContext = createContext({
	data: {} as Partial<IDataState>,
	setData: {} as Dispatch<SetStateAction<Partial<IDataState>>>,
});

const DataStateProvider = ({
	children,
	value = {} as IDataState,
}: {
	children: React.ReactNode;
	value?: Partial<IDataState>;
}) => {
	const [data, setData] = useState(value);

	return <DataContext.Provider value={{ data, setData }}>{children}</DataContext.Provider>;
};

const useDataState = () => {
	const context = useContext(DataContext);
	if (!context) {
		throw new Error('useDataState must be used within a DataContext');
	}

	return context;
};

export { DataStateProvider, useDataState };
