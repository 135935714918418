import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

import Form from '../components/Form';
import Field from '../components/Field';
import { resetFields } from '../utils/inputFields';

import axios from 'axios';

interface IResetRequest {
	email: string;

	password: string;

	token: string;
}

const useStyles = makeStyles(theme => ({
	page: {
		height: '100vh',
		width: '100vw'
	},
	title: {
		textAlign: 'center'
	},
	line: {
		width: '100%'
	},
	formArea: {
		width: '100%'
	},
	confirmButton: {
		width: '100%',
		marginTop: '25px'
	}
}));

const ResetPassword: React.FC = (props: any) => {
	const classes = useStyles();

	const history = useHistory();
	const [token, setToken] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [resetComplete, setResetComplete] = useState<boolean>(false);
	const [resetTitle, setResetTitle] = useState<string>('');
	const [resetMessage, setResetMessage] = useState<string>('');

	useEffect(() => {
		// Retreive the email and token from url for verification
		const tokenParam = new URLSearchParams(props.location.search).get('token');
		const emailParam = new URLSearchParams(props.location.search).get('email');
		if (tokenParam) {
			setToken(tokenParam);
		}

		if (emailParam) {
			setEmail(emailParam);
		}
	}, [props.location.search]);

	/**
	 * Makes request to confirm password has been reset(POST)
	 * @param {any} passwordData - All password information
	 */
	const handleReset = (passwordData: any): void => {
		const passwordRequest: IResetRequest = {
			email,
			password: passwordData.confirmPassword,
			token
		};

		axios
			.post('/api/staff/password_reset', JSON.stringify(passwordRequest), {
				headers: { 'Content-Type': 'application/json' }
			})
			.then(response => {
				openResetCompleteDialogue(
					'Reset Complete',
					'Your password has been reset, you can now return to login.'
				);
			})
			.catch(err => {
				const code = err.response.status;

				switch (code) {
					case 401:
						openResetCompleteDialogue(
							'Reset Failed',
							'We were unable to reset your password, please return to login and try the reset request again.'
						);
						break;
					case 429:
						setErrorMessage(
							'Too many attempt to reset password, please wait and try again.'
						);
						break;
					default:
						setErrorMessage('oops, something went wrong!');
				}
			});
	};

	/**
	 * Opens the reset complete dialogue component with message and redirect to login
	 * @param {string} title - The title of the dialogue
	 * @param {string} message - The message to go onto the body of the dialogue
	 */
	const openResetCompleteDialogue = (title: string, message: string): void => {
		setResetTitle(title);
		setResetMessage(message);
		setResetComplete(true);
	};

	/**
	 * Redirect back to login after complete reset process
	 */
	const confirmResetComplete = (): void => {
		history.push('/login');
	};

	return (
		<Fragment>
			<Grid container justify="center" alignItems="center" className={classes.page}>
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
					spacing={10}
					style={{ width: '100%' }}
				>
					<Grid item className={classes.title}>
						<Typography variant="h4">Learn2 Platform</Typography>
						<Typography variant="h3">SAVE THE TITANIC</Typography>
						<hr className={classes.line} />
					</Grid>
					<Grid container justify="center">
						<Grid item xs={4}>
							<Form
								onSubmit={handleReset}
								fields={resetFields}
								render={() => (
									<Grid
										container
										direction="column"
										alignItems="center"
										spacing={2}
									>
										<Grid item>
											<Typography
												variant="h5"
												style={{ textAlign: 'center' }}
											>
												Reset Password
											</Typography>
											<Typography variant="body1" color="error">
												{errorMessage}
											</Typography>
										</Grid>
										<Grid item className={classes.formArea}>
											<Field {...resetFields.newPassword} />
											<Field {...resetFields.confirmPassword} />
											<Button
												type="submit"
												variant="contained"
												disableTouchRipple
												className={classes.confirmButton}
											>
												Confirm
											</Button>
										</Grid>
									</Grid>
								)}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Dialog
				open={resetComplete}
				onClose={confirmResetComplete}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{resetTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText>{resetMessage}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={confirmResetComplete} color="primary">
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

export default ResetPassword;
