import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { IMessage } from '../../../interfaces/message';
import { ChatlogPaper } from '../../../assets/chatPanel';
import ChatDivider from '../../Icons/ChatDivider';

interface IAnnouncementChatCardProps {
	message: IMessage;
}

const useStyles = makeStyles(theme => ({
	action: {
		backgroundColor: theme.palette.secondary.dark,
		margin: theme.spacing(1.5),
		padding: theme.spacing(2)
	},
	message: {
		backgroundImage: `url(${ChatlogPaper})`,
		backgroundSize: 'contain',
		margin: theme.spacing(1.5),
		padding: theme.spacing(2),
		lineHeight: 1.25,
		maxWidth: '17vw'
	},
	announcement: {
		fontWeight: 500,
		maxWidth: '17vw'
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%'
	},
	time: {
		color: '#565656'
	},
	content: {
		fontWeight: 500,
		margin: theme.spacing(1, 0)
	}
}));

const AnnouncementChatCard: React.FC<IAnnouncementChatCardProps> = ({ message }) => {
	const classes = useStyles();

	return (
		<Paper className={message.isAction ? classes.action : classes.message}>
			<Grid container>
				<Grid item xs={12} className={classes.row}>
					<Typography
						variant="subtitle1"
						color="primary"
						className={classes.announcement}
					>
						Announcement
					</Typography>
					<Typography variant="body2" className={classes.time}>
						{message.time.toLocaleString()}
					</Typography>
				</Grid>
				<ChatDivider />
				<Grid item xs={12} style={{ wordWrap: 'break-word' }}>
					<Typography variant="body1" className={classes.content}>
						{message.message}
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default AnnouncementChatCard;
