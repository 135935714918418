import { ILobby } from '../../interfaces/lobby';
import * as MessageType from '../../interfaces/message';

/**
 * Handles the 'chat-message' message (when a new chat message is received).
 * @param {MessageType.IMessage[]} messages - the current list of all messages in room
 * @param {Ilobby} lobby - the current lobby of the room
 * @param {MessageType.IChatMessage} socketMessage - all the information of the receive chat message
 * @returns {MessageType.IMessage[]} - the updated message list
 */
export const handleUpdateChat = (
	messages: MessageType.IMessage[],
	lobby: ILobby,
	socketMessage: MessageType.IChatMessageData
): MessageType.IMessage[] => {
	const newMessage: MessageType.IMessage = toMessage(lobby, socketMessage);
	if (socketMessage.responseTo) {
		newMessage.replyTo = messages.find((m) => m.id === socketMessage.responseTo);
	}
	const newMessageList = [...messages, newMessage];

	return newMessageList;
};

/**
 * Updates an existing message in the chat log
 * @param {MessageType.IMessage[]} messages - the current list of all messages in room
 * @param {MessageType.IChatMessage} socketMessage - all the information of the received chat message
 * @returns {MessageType.IMessage[]} - the updated message list
 */
export const handleUpdateChatMessage = (
	messages: MessageType.IMessage[],
	socketMessage: MessageType.IChatMessageData
): MessageType.IMessage[] => {
	const messageIndex = messages.findIndex((msg) => msg.id === socketMessage.id);
	if (messageIndex !== -1) {
		const newMessages = [...messages];
		newMessages[messageIndex].isAction = true;

		return newMessages;
	}
	return messages;
};

/**
 * Converts message data into a formatted message type
 * @param {Ilobby} lobby - the current lobby of the room
 * @param {MessageType.IChatMessageData} chatMessage - the data for the chat message
 * @returns {MessageType.IMessage} - the formatted message
 */
const toMessage = (
	lobby: ILobby,
	chatMessage: MessageType.IChatMessageData
): MessageType.IMessage => {
	const fromUser = lobby[chatMessage.from];

	const message: MessageType.IMessage = {
		id: chatMessage.id,
		isAction: chatMessage.isAction,
		from: {
			name: fromUser ? `${fromUser.firstName} ${fromUser.lastName}` : 'unknown',
			role: fromUser ? `${fromUser.role}` : 'unknown',
		},
		time: new Date(chatMessage.timestamp),
		message: chatMessage.message,
	};
	return message;
};
