import React, { Fragment, useEffect, useState } from 'react';
import { useSocketManager } from '../contexts/SocketManager';
import { useGlobalState } from '../contexts/GlobalStateProvider';
import { RoomType } from '../types/types';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import VideoPanel from '../components/Video/VideoPanel';
import MainStageTray from '../components/Video/MainStageTray';
import GeneralRoomManager from '../components/RoomSystems/GeneralRoom/GeneralRoomManager';
import ScreenShare from '../components/Video/ScreenShare';
import StaffChat from '../components/StaffChat/StaffChat';
import CabinImage from '../components/CabinImage';
import Instructions from '../components/MultiStepInstructions/Instructions';

const useStyles = makeStyles(theme => ({
	page: {
		height: '100vh',
		display: 'inline'
	},
	videoPanel: {
		width: '80vw',
		position: 'absolute',
		top: 0
	},
	fullHeight: {
		height: '100%'
	},
	screen: {
		position: 'absolute',
		top: '20%',
		width: '70vw'
	},
	cabinArtwork: {
		width: '80%',
		height: '100vh',
		objectFit: 'cover'
	},
	tray: {
		width: '80vw'
	},
	updateEventButton: {
		position: 'fixed',
		bottom: 25,
		left: 175,
		transform: 'translateX(-50%)',
		zIndex: 999
	}
}));

interface IGeneralRoomProps {
	roomType: RoomType;
}

const GeneralRoom: React.FC<IGeneralRoomProps> = ({ roomType }) => {
	const classes = useStyles();
	const { state } = useGlobalState();
	const {
		rooms,
		callObject,
		socket,
		connect,
		facilitatorUpdateEvent,
		leaveRooms,
		facilitatorData
	} = useSocketManager();
	const [showInstructions, setShowInstructions] = useState<boolean>(false);

	const getAcknowledgement = localStorage.getItem('acknowledgedInstructions');

	useEffect(() => {
		if (!getAcknowledgement) {
			setShowInstructions(true);
		}
	}, [setShowInstructions, getAcknowledgement]);

	const handleStoreAcknowledgement = () => {
		localStorage.setItem('acknowledgedInstructions', 'true');
		setShowInstructions(false);
	};

	useEffect(() => {
		if (!socket) {
			connect();
		}
	}, [socket, connect]);

	useEffect(() => {
		return () => {
			leaveRooms(roomType);
		};
	}, [leaveRooms, roomType]);

	const room = roomType === 'Main Stage' ? rooms.mainStage : rooms.greenRoom;
	const announcementRoom = rooms.announcementRoom;

	return (
		<Fragment>
			<Grid container className={classes.page}>
				{room && announcementRoom && (
					<Fragment>
						<Grid container className={classes.videoPanel}>
							<Grid item xs={12} className={classes.fullHeight}>
								<VideoPanel callObject={callObject} staffPanel />
							</Grid>
						</Grid>
						<CabinImage className={classes.cabinArtwork} />
						<Grid container>
							<Grid item xs={12} className={classes.screen}>
								<ScreenShare callObject={callObject} />
							</Grid>
							<Grid item xs={12} className={classes.tray}>
								<MainStageTray
									callObject={callObject}
									socket={socket}
									space={room.space}
								/>
								{roomType === 'Main Stage' && state.user?.role === 'admin' && (
									<div className={classes.updateEventButton}>
										{facilitatorData &&
											facilitatorData.eventState === 'main-stage' && (
												<Button
													variant="contained"
													disableTouchRipple
													onClick={() =>
														facilitatorUpdateEvent('running')
													}
												>
													Start Event
												</Button>
											)}
										{facilitatorData &&
											facilitatorData.eventState === 'running' && (
												<Button
													variant="contained"
													disableTouchRipple
													onClick={() =>
														facilitatorUpdateEvent('main-stage')
													}
												>
													Recall Participants
												</Button>
											)}
									</div>
								)}
								{rooms.staffChatRoom && (
									<StaffChat socket={socket} roomData={rooms.staffChatRoom} />
								)}
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<GeneralRoomManager
								socket={socket}
								roomType={roomType}
								roomData={room}
								announcementRoomData={announcementRoom}
							/>
						</Grid>
					</Fragment>
				)}
			</Grid>
			<Instructions
				onAcknowledge={() => handleStoreAcknowledgement()}
				onShowInstructions={showInstructions}
			/>
		</Fragment>
	);
};

export default GeneralRoom;
