import React, { useEffect, useMemo, useRef } from 'react';
import { DailyTrackState } from '@daily-co/daily-js';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Determine the state of the track
 * @param {string} kind - the type of track
 * @param {DailyTrackState} trackState - the state of the track
 * @returns {string | null | undefined} - the message to display on the overlay
 */
const getTrackUnavailableMessage = (kind: string, trackState: DailyTrackState) => {
	if (!trackState) return;
	switch (trackState.state) {
		case 'blocked':
			if (trackState.blocked!.byPermissions) {
				return `${kind} permission denied`;
			} else if (trackState.blocked!.byDeviceMissing) {
				return `${kind} device missing`;
			}
			return `${kind} blocked`;
		case 'off':
			if (trackState.off!.byUser) {
				return `${kind} off by user`;
			} else if (trackState.off!.byBandwidth) {
				return `${kind} muted to save bandwidth`;
			}
			return `${kind} off`;
		case 'sendable':
			return `${kind} not subscribed`;
		case 'loading':
			return `${kind} loading...`;
		case 'interrupted':
			return `${kind} interrupted`;
		case 'playable':
			return null;
	}
};

interface IVideoTileProps {
	videoTrackState: DailyTrackState;
	audioTrackState: DailyTrackState;
	isLocalPerson: boolean;
}

const useStyles = makeStyles(theme => ({
	overlay: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	video: {
		display: 'grid',
		padding: 0
	}
}));

const VideoTile: React.FC<IVideoTileProps> = ({
	videoTrackState,
	audioTrackState,
	isLocalPerson
}) => {
	const classes = useStyles();
	const videoEl = useRef<any>(null);
	const audioEl = useRef<any>(null);

	const videoTrack = useMemo(() => {
		return videoTrackState && videoTrackState.state === 'playable'
			? videoTrackState.track
			: null;
	}, [videoTrackState]);

	const audioTrack = useMemo(() => {
		return audioTrackState && audioTrackState.state === 'playable'
			? audioTrackState.track
			: null;
	}, [audioTrackState]);

	const videoUnavailableMessage = useMemo(() => {
		return getTrackUnavailableMessage('video', videoTrackState);
	}, [videoTrackState]);

	const audioUnavailableMessage = useMemo(() => {
		return getTrackUnavailableMessage('audio', audioTrackState);
	}, [audioTrackState]);

	//When video track changes, update video srcObject
	useEffect(() => {
		videoEl.current && (videoEl.current!.srcObject = new MediaStream([videoTrack!]));
	}, [videoTrack]);

	//When audio track changes, update audio srcObject
	useEffect(() => {
		audioEl.current && (audioEl.current!.srcObject = new MediaStream([audioTrack!]));
	}, [audioTrack]);

	return (
		<div className={classes.video}>
			{videoTrack && (
				<video height="100%" width="100%" autoPlay muted playsInline ref={videoEl} />
			)}
			{videoUnavailableMessage && (
				<div className={classes.overlay}>
					{videoUnavailableMessage && videoUnavailableMessage !== 'video off by user' && (
						<p>{videoUnavailableMessage}</p>
					)}
					{audioUnavailableMessage && audioUnavailableMessage !== 'audio off by user' && (
						<p>{audioUnavailableMessage}</p>
					)}
				</div>
			)}
			{!isLocalPerson && audioTrack && <audio autoPlay playsInline ref={audioEl} />}
		</div>
	);
};

export default VideoTile;
