import React from 'react';

interface ITabPanelProps {
	/* The tab value to reference */
	value: number;

	/* The index on which this panel is visible */
	index: number;

	/* Content inside this panel */
	children: React.ReactNode;
}

const TabPanel: React.FC<ITabPanelProps> = ({ value, index, children }) => {
	return (
		<div hidden={value !== index} style={{ height: '100%', width: '100%' }}>
			{children as JSX.Element}
		</div>
	);
};

export default TabPanel;
