import React, { Fragment, useState } from 'react';

import { useDataState } from '../../contexts/DataProvider';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import DeleteIcon from '@material-ui/icons/Delete';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';

import Autocomplete from '@material-ui/lab/Autocomplete';

import Confirmation from '../../components/Confirmation/Confirmation';
import ArchiveConfirmation from '../Confirmation/ArchiveConfirmation';

import { IEvent } from '../../interfaces/event';
import { ICompany } from '../../interfaces/company';

import { makeStyles } from '@material-ui/core/styles';
import { RemoveType } from '../../types/types';

import axios from 'axios';

interface IEventHeaderProps {
	/** The current selected company */
	selectedCompany: ICompany | null;

	/** The current selected event */
	selectedEvent: IEvent | null;

	/** The value in the company auto complete */
	companyInputValue: string;

	/** The value in the event auto complete */
	eventInputValue: string;

	/** The tab value to display the appropriate panel */
	tabValue: number;

	/** Whether to show the event details or not */
	eventDetails: boolean;

	/** The state of the archive toggle */
	archiveChecked: boolean;

	/** When user changes the tab view */
	onTabChange: (newTabValue: number) => void;

	/** When the company changes on auto complete */
	onCompanyChange: (newCompany: ICompany | null) => void;

	/** When the user toggles the archive checkbox */
	onArchiveChange: (checked: boolean) => void;

	/** When the event changes on auto complete */
	onEventChange: (newEvent: IEvent | null) => void;

	/** When the input on company auto complete is changes */
	onCompanyInputChange: (newValue: string) => void;

	/** When the input on event auto complete is changes */
	onEventInputChange: (newValue: string) => void;

	/** When the event auto complete clears */
	onClearEventInput: () => void;

	/** When user showing the event details (add or edit) */
	onShowEventDetails: (editMode: boolean) => void;

	/** When a request has completed */
	onRequestComplete: (severity: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles(theme => ({
	title: {
		marginTop: '25px'
	},
	spacing: {
		marginTop: '20px'
	},
	fill: {
		width: '100%'
	},
	eventPaper: {
		marginTop: '20px',
		paddingBottom: '100px'
	}
}));

const EventHeader: React.FC<IEventHeaderProps> = ({
	selectedCompany,
	selectedEvent,
	companyInputValue,
	eventInputValue,
	tabValue,
	eventDetails,
	archiveChecked,
	onTabChange,
	onCompanyChange,
	onArchiveChange,
	onEventChange,
	onCompanyInputChange,
	onEventInputChange,
	onClearEventInput,
	onShowEventDetails,
	onRequestComplete
}) => {
	const classes = useStyles();

	const { data, setData } = useDataState();

	const [showRemove, setShowRemove] = useState<boolean>(false);
	const [showUnarchive, setShowUnarchive] = useState<boolean>(false);
	const [showDelete, setShowDelete] = useState<boolean>(false);

	/**
	 * When the user toggles the show archived events checkbox
	 * @param {React.ChangeEvent<HTMLInputElement>} event - The input event of the check box
	 */
	const handleArchiveChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const checked = event.target.checked;
		onArchiveChange(checked);

		// TO DO Send another call to get events based on if archives is checked

		if (!checked) {
			if (selectedEvent?.isArchived) {
				onClearEventInput();
			}
		}
	};

	/**
	 * Makes a request to either set company as archived or delete event (PUT/DELETE)
	 */
	const confirmRemove = (removeType: RemoveType): void => {
		if (selectedCompany && selectedEvent) {
			if (removeType === 'archive') {
				const archivedEvent: IEvent = {
					isArchived: true
				};

				axios
					.put(
						`/api/company/${selectedCompany.id}/events/${selectedEvent.id}`,
						JSON.stringify(archivedEvent),
						{ headers: { 'Content-Type': 'application/json' } }
					)
					.then(response => {
						// remove from list if archive companies are not showing
						if (!archiveChecked) {
							const newEventList = data.events!.filter(
								event => event.id !== selectedEvent.id
							);
							setData(prevData => ({ ...prevData, events: newEventList }));
							onClearEventInput();
						} else {
							const newEventList = [...data.events!];
							const updatedEvent: IEvent = response.data;
							const oldEventIndex = data.events!.findIndex(
								event => event.id === selectedEvent.id
							);
							newEventList[oldEventIndex] = updatedEvent;
							setData(prevData => ({ ...prevData, events: newEventList }));
							onEventChange(updatedEvent);
						}

						setShowRemove(false);
						onRequestComplete('success', 'Event archived.');
					})
					.catch(err => {
						onRequestComplete('error', 'Failed to archive event, please try again.');
					});
			} else {
				axios
					.delete(`/api/company/${selectedCompany.id}/events/${selectedEvent.id}`)
					.then(response => {
						const newEventList = data.events!.filter(
							event => event.id !== selectedEvent.id
						);
						setData(prevData => ({ ...prevData, events: newEventList }));
						onClearEventInput();
						setShowRemove(false);
						onRequestComplete('success', 'Event deleted.');
					})
					.catch(err => {
						onRequestComplete('error', 'Failed to delete event, please try again.');
					});
			}
		}
	};

	/**
	 * Makes a request to unarchive event from database (PUT)
	 */
	const handleUnArchiveEvent = (): void => {
		if (selectedCompany && selectedEvent) {
			const archivedEvent: IEvent = {
				isArchived: false
			};

			axios
				.put(
					`/api/company/${selectedCompany.id}/events/${selectedEvent.id}`,
					JSON.stringify(archivedEvent),
					{ headers: { 'Content-Type': 'application/json' } }
				)
				.then(response => {
					const newEventList = [...data.events!];
					const updatedEvent: IEvent = response.data;
					const oldEventIndex = data.events!.findIndex(
						event => event.id === selectedEvent.id
					);
					newEventList[oldEventIndex] = updatedEvent;
					setData(prevData => ({ ...prevData, events: newEventList }));
					setShowUnarchive(false);
					onEventChange(updatedEvent);
					onRequestComplete('success', 'Event un archived.');
				})
				.catch(err => {
					onRequestComplete('error', 'Failed to unarchive event, please try again.');
				});
		}
	};

	/**
	 * Makes a request to delete event from database (DELETE)
	 */
	const handleDeleteEvent = (): void => {
		if (selectedCompany && selectedEvent) {
			axios
				.delete(`/api/company/${selectedCompany.id}/events/${selectedEvent.id}`)
				.then(response => {
					const newEventList = data.events!.filter(
						event => event.id !== selectedEvent.id
					);
					setData(prevData => ({ ...prevData, events: newEventList }));
					onClearEventInput();
					setShowDelete(false);
					onRequestComplete('success', 'Event deleted.');
				})
				.catch(err => {
					onRequestComplete('error', 'Failed to delete event, please try again.');
				});
		}
	};

	return (
		<Fragment>
			<Paper className={classes.eventPaper}>
				<Grid container justify="center" spacing={5}>
					<Grid item className={classes.title}>
						<Typography variant="h3">Manage Events</Typography>
						<hr />
					</Grid>
					<Grid container justify="center">
						<Grid item xs={6}>
							<Autocomplete
								options={data.companies ? data.companies : []}
								getOptionLabel={option => (option.name ? option.name : '')}
								renderInput={params => (
									<TextField {...params} label="Company" variant="outlined" />
								)}
								onChange={(event: any, newValue: ICompany | null) =>
									onCompanyChange(newValue)
								}
								inputValue={companyInputValue}
								onInputChange={(event, newInputValue) => {
									onCompanyInputChange(newInputValue);
								}}
							/>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<Grid
							container
							justify="center"
							alignItems="center"
							className={classes.spacing}
						>
							<Grid item xs={2}>
								<Button
									variant="contained"
									disableTouchRipple
									disabled={selectedCompany === null}
									onClick={() => onShowEventDetails(false)}
								>
									Create Event
								</Button>
							</Grid>
							<Grid item xs={8}>
								<Autocomplete
									options={data.events ? data.events : []}
									getOptionLabel={option => (option.name ? option.name : '')}
									renderInput={params => (
										<TextField {...params} label="Event" variant="outlined" />
									)}
									onChange={(event: any, newValue: IEvent | null) =>
										onEventChange(newValue)
									}
									disabled={selectedCompany === null}
									inputValue={eventInputValue}
									onInputChange={(event, newInputValue) => {
										onEventInputChange(newInputValue);
									}}
								/>
							</Grid>
							<Grid item xs={2}>
								<Grid container justify="space-around">
									{selectedEvent?.isArchived ? (
										<Fragment>
											<Grid item>
												<Tooltip title="Unarchive Event">
													<span>
														<IconButton
															color="primary"
															disabled={
																selectedCompany === null ||
																selectedEvent === null
															}
															onClick={() => setShowUnarchive(true)}
														>
															<UnarchiveIcon />
														</IconButton>
													</span>
												</Tooltip>
											</Grid>
											<Grid item>
												<Tooltip title="Delete Event">
													<span>
														<IconButton
															color="primary"
															disabled={
																selectedCompany === null ||
																selectedEvent === null
															}
															onClick={() => setShowDelete(true)}
														>
															<DeleteIcon />
														</IconButton>
													</span>
												</Tooltip>
											</Grid>
										</Fragment>
									) : (
										<Fragment>
											<Grid item>
												<Tooltip title="Edit Event">
													<span>
														<IconButton
															color="primary"
															disabled={
																selectedCompany === null ||
																selectedEvent === null
															}
															onClick={() => onShowEventDetails(true)}
														>
															<EditIcon />
														</IconButton>
													</span>
												</Tooltip>
											</Grid>
											<Grid item>
												<Tooltip title="Archive Event">
													<span>
														<IconButton
															color="primary"
															disabled={
																selectedCompany === null ||
																selectedEvent === null
															}
															onClick={() => setShowRemove(true)}
														>
															<ArchiveIcon />
														</IconButton>
													</span>
												</Tooltip>
											</Grid>
										</Fragment>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid container justify="center">
							<Grid item>
								<FormControlLabel
									control={
										<Checkbox
											checked={archiveChecked}
											color="primary"
											onChange={handleArchiveChange}
										/>
									}
									label="Show Archived Events"
									disabled={selectedCompany === null}
								/>
							</Grid>
						</Grid>
					</Grid>
					{selectedEvent !== null && !eventDetails && !selectedEvent.isArchived && (
						<Grid
							container
							justify="center"
							alignItems="center"
							className={classes.spacing}
						>
							<Grid item xs={6}>
								<Tabs
									value={tabValue}
									onChange={(event: React.ChangeEvent<{}>, value: any) =>
										onTabChange(value)
									}
									indicatorColor="secondary"
									textColor="secondary"
									variant="fullWidth"
								>
									<Tab label="Manage Rooms" disabled={selectedEvent === null} />
									<Tab
										label="Manage Participants"
										disabled={selectedEvent === null}
									/>
								</Tabs>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Paper>
			<ArchiveConfirmation
				showConfirmation={showRemove}
				onCloseDialog={() => setShowRemove(false)}
				title="Remove Event"
				message="You have selected to remove a event, do you want to archive it or delete completely?"
				onConfirmRemove={confirmRemove}
			/>
			<Confirmation
				visiblity={showUnarchive}
				title="Un Archive this Event?"
				content="Are you sure you want to un archive this event?"
				onConfirm={handleUnArchiveEvent}
				onCancel={() => setShowUnarchive(false)}
			/>
			<Confirmation
				visiblity={showDelete}
				title="Delete this Event?"
				content="Are you sure you want to delete this event? This will remove all company info from the database"
				onConfirm={handleDeleteEvent}
				onCancel={() => setShowDelete(false)}
			/>
		</Fragment>
	);
};

export default EventHeader;
