import React, { useEffect, useState, useReducer } from 'react';
import DailyIframe, { DailyCall } from '@daily-co/daily-js';

import { useGlobalState } from '../../contexts/GlobalStateProvider';
import { useSocketManager } from '../../contexts/SocketManager';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';

import Icon from '../Icons/Icon';
import { CamOnDefault, CamOnHover, CamOffDefault, CamOffHover } from '../../assets/icons/cam';
import { MicOffDefault, MicOffHover, MicOnDefault, MicOnHover } from '../../assets/icons/mic';
import { SettingsDefault, SettingsHover } from '../../assets/icons/settings';
import {
	ScreenShareOffDefault,
	ScreenShareOffHover,
	ScreenShareOnDefault,
	ScreenShareOnHover
} from '../../assets/icons/screenShare';
import { HandDefault, HandHover } from '../../assets/icons/hand';

import DeviceSelection from '../MediaDeviceSelection/DeviceSelection';
import { getStreamStates } from '../../utils/getStreamStates';
import {
	callReducer,
	initialCallState,
	isScreenSharing,
	PARTICIPANTS_CHANGE
} from '../../utils/callState';
import { makeStyles } from '@material-ui/core';
import { brassPressed } from '../../assets/brassCircularButton';
import { WoodenPanel } from '../../assets/desktop';

import ScreenShareErrorHandling from '../ScreenShareErrorHandling';

interface IMainStageTrayProps {
	callObject: DailyCall | null;
	socket: WebSocket | null;
	space: string;
}

const useStyles = makeStyles(theme => ({
	woodenPanel: {
		height: 'auto',
		width: '40vw',
		position: 'relative'
	},
	handRaised: {
		backgroundImage: `url(${brassPressed})`,
		boxShadow: '0 5px 15px rgba(0,0,0, .5)'
	},
	dockContainer: {
		width: '80vw',
		position: 'absolute',
		bottom: 0
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		bottom: 0,
		zIndex: 998,
		marginBottom: '.5rem',
		[theme.breakpoints.up('lg')]: {
			marginBottom: '2rem'
		}
	},
	pressedIcon: {
		backgroundImage: `url(${brassPressed})`,
		boxShadow: '0 5px 15px rgba(0,0,0, .8)'
	},
	dock: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'center',
		height: '100%'
	},
	// increase button size for larger screens.
	'@global': {
		[theme.breakpoints.up('lg')]: {
			'.MuiFab-root': {
				width: 78,
				height: 78
			}
		}
	}
}));

const MainStageTray: React.FC<IMainStageTrayProps> = ({ callObject, socket, space }) => {
	const { state } = useGlobalState();
	const { handRaised } = useSocketManager();

	const [isCameraMuted, setCameraMuted] = useState<boolean | undefined>(false);
	const [isMicMuted, setMicMuted] = useState<boolean | undefined>(false);
	const [isSharingScreen, setSharingScreen] = useState<boolean | undefined>(false);
	const [showScreenShare, setShowScreenShare] = useState<boolean>(true);
	//const [handRaised, setHandRaised] = useState<boolean>(false);
	const [showSettings, setShowSettings] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);

	const [callState, dispatch] = useReducer(callReducer, initialCallState);

	const classes = useStyles();

	const handleRaiseHand = (): void => {
		if (socket) {
			const raiseHand = !handRaised;
			socket.send(
				JSON.stringify({
					type: 'set-raise-hand',
					space: space,
					data: {
						raisedHand: raiseHand
					}
				})
			);
		}
	};

	/**
	 * Handles toggling camera state
	 */
	const toggleCamera = (): void => {
		if (callObject) {
			callObject.setLocalVideo(isCameraMuted!);
		}
	};

	/**
	 * Handles toggling audio state
	 */
	const toggleMic = (): void => {
		if (callObject) {
			callObject.setLocalAudio(isMicMuted!);
		}
	};

	/**
	 * Handle toggling presenting state
	 */
	const toggleScreenShare = (): void => {
		/**
		 * Daily only supports ScreenShare WITH audio on Chrome and Microsoft Edge.
		 * Check if user is using a supported browser. If not, display an error message dialog window.
		 * **/
		const supportedBrowser =
			DailyIframe.supportedBrowser().name === 'Chrome' ||
			DailyIframe.supportedBrowser().name === 'Microsoft Edge';

		if (callObject && supportedBrowser) {
			isSharingScreen
				? callObject.stopScreenShare()
				: callObject.startScreenShare({
						audio: true
				  });
		} else {
			setError(true);
		}
	};

	/**
	 * Toggles setting modal state.
	 */
	const toggleSettings = (): void => {
		setShowSettings(!showSettings);
	};

	/**
	 * Start listening for participant changes when callObject is set (i.e. when the component mounts).
	 * This event will capture any changes to your audio/video mute state.
	 */
	useEffect(() => {
		if (!callObject) return;

		const handleNewParticipantsState = (): void => {
			const [isCameraMuted, isMicMuted, isSharingScreen] = getStreamStates(callObject);
			setCameraMuted(isCameraMuted);
			setMicMuted(isMicMuted);
			setSharingScreen(isSharingScreen);
			// listen for particpant changes to update callState as participants are joining
			dispatch({
				type: PARTICIPANTS_CHANGE,
				callObject: callObject
			});
		};

		// Use initial state
		handleNewParticipantsState();

		// Listen for changes in state
		callObject.on('participant-updated', handleNewParticipantsState);

		// Stop listening for changes in state
		return function cleanup() {
			callObject.off('participant-updated', handleNewParticipantsState);
		};
	}, [callObject]);

	// check for screensharer
	useEffect(() => {
		if (!callState) return;

		const limitScreenShares = (): void => {
			// get screensharer (anyone with '-screen' at the end of their id)

			const getScreenSharer = callState.callList
				.map((callItem: any) => callItem.id)
				.find((id: string) => id.endsWith('-screen'));

			// disable ability to share screen if there is already a screensharer.
			if (!isScreenSharing('local-screen') && getScreenSharer !== undefined) {
				setShowScreenShare(false);
			}
		};

		limitScreenShares();
	}, [callState]);

	return (
		<div className={classes.dockContainer}>
			<div className={classes.dock}>
				<Grid
					container
					className={classes.buttonContainer}
					justify="space-between"
					spacing={5}
				>
					<Grid item>
						<Tooltip title={isMicMuted ? 'Unmute Mic' : 'Mute Mic'}>
							<span>
								<Fab
									onClick={toggleMic}
									disabled={callObject === null}
									disableTouchRipple
									classes={{
										root: isMicMuted ? classes.pressedIcon : ''
									}}
									aria-label={isMicMuted ? 'Unmute Mic' : 'Mute Mic'}
								>
									{isMicMuted ? (
										<Icon defaultIcon={MicOffDefault} hoverIcon={MicOffHover} />
									) : (
										<Icon defaultIcon={MicOnDefault} hoverIcon={MicOnHover} />
									)}
								</Fab>
							</span>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip title={isCameraMuted ? 'Turn Camera On' : 'Turn Camera Off'}>
							<span>
								<Fab
									onClick={toggleCamera}
									disabled={callObject === null}
									disableTouchRipple
									classes={{
										root: isCameraMuted ? classes.pressedIcon : ''
									}}
									aria-label={
										isCameraMuted ? 'Turn Camera On' : 'Turn Camera Off'
									}
								>
									{isCameraMuted ? (
										<Icon defaultIcon={CamOffDefault} hoverIcon={CamOffHover} />
									) : (
										<Icon defaultIcon={CamOnDefault} hoverIcon={CamOnHover} />
									)}
								</Fab>
							</span>
						</Tooltip>
					</Grid>

					{state.user?.role === 'participant' && (
						<Grid item>
							<Tooltip title={handRaised ? 'Cancel Hand Raise' : 'Raise Hand'}>
								<span>
									<Fab
										onClick={handleRaiseHand}
										disabled={callObject === null}
										// if user raises hand, button remains pressed in until it is addressed
										classes={{ root: handRaised ? classes.pressedIcon : '' }}
										disableTouchRipple
										aria-label={handRaised ? 'Cancel Hand Raise' : 'Raise Hand'}
									>
										<Icon defaultIcon={HandDefault} hoverIcon={HandHover} />
									</Fab>
								</span>
							</Tooltip>
						</Grid>
					)}

					{showScreenShare && state.user?.role !== 'participant' && (
						<Grid item>
							<Tooltip
								title={
									isSharingScreen
										? 'Stop Presenting Screen'
										: 'Start Presenting Screen'
								}
							>
								<span>
									<Fab
										onClick={toggleScreenShare}
										disabled={callObject === null}
										disableTouchRipple
										classes={{
											root: isSharingScreen ? classes.pressedIcon : ''
										}}
										aria-label={
											isSharingScreen
												? 'Stop Presenting Screen'
												: 'Start Presenting Screen'
										}
									>
										{isSharingScreen ? (
											<Icon
												defaultIcon={ScreenShareOffDefault}
												hoverIcon={ScreenShareOffHover}
											/>
										) : (
											<Icon
												defaultIcon={ScreenShareOnDefault}
												hoverIcon={ScreenShareOnHover}
											/>
										)}
									</Fab>
								</span>
							</Tooltip>
						</Grid>
					)}
					<Grid item>
						<Tooltip title="Open Audio/Video Settings">
							<span>
								<Fab
									onClick={toggleSettings}
									disabled={callObject === null}
									disableTouchRipple
									classes={{
										root: showSettings ? classes.pressedIcon : ''
									}}
									aria-label="Open audio/video settings"
								>
									<Icon defaultIcon={SettingsDefault} hoverIcon={SettingsHover} />
								</Fab>
							</span>
						</Tooltip>
					</Grid>
				</Grid>

				<WoodenPanel className={classes.woodenPanel} />
			</div>
			<DeviceSelection
				callObject={callObject}
				visibility={showSettings}
				onClose={() => setShowSettings(false)}
			/>

			<ScreenShareErrorHandling visibility={error} onClose={() => setError(false)} />
		</div>
	);
};

export default MainStageTray;
