import React, { useState, useRef, useEffect } from 'react';

import Badge from '@material-ui/core/Badge';
import { ChatDefault, ChatHover } from '../../../assets/icons/chat';
import { IMessage } from '../../../interfaces/message';
import Icon from '../../Icons/Icon';
import { ChatTabDefault, ChatTabHover, ChatTabPressed } from '../../../assets/icons/chatTabs';
import TabIcon from '../../Icons/TabIcon';
import { makeStyles } from '@material-ui/core';

interface IChatViewIconProps {
	messages: IMessage[];
	isOpen: boolean;
	tab?: boolean;
}

const useStyles = makeStyles(theme => ({
	badge: {

		top: '35%',
		right: '18%',
		transform: 'scale(.8) translate(60%, -50%)',
		[theme.breakpoints.up('lg')]: {
			top: '25%',
			right: '18%',
			transform: 'scale(.8) translate(60%, -50%)'
		}
	}
}));

const ChatViewIcon: React.FC<IChatViewIconProps> = ({ messages, isOpen, tab }) => {
	const messageTotal = useRef<number>(messages.length);
	const [unreadMessage, setUnreadMessage] = useState<number>(0);

	const classes = useStyles();

	useEffect(() => {
		if (!isOpen) {
			if (messageTotal.current < messages.length) {
				setUnreadMessage(prevState => prevState + 1);
			}
		} else {
			messageTotal.current = messages.length;
			setUnreadMessage(0);
		}
	}, [messages, isOpen]);

	return (
		<Badge
			badgeContent={unreadMessage}
			color="primary"
			overlap="circle"
			classes={{ anchorOriginTopRightCircle: classes.badge }}
		>
			{/* icon varies based on location - "tab" prop signifies if it is in the Tab component or not. */}
			{tab ? (
				<TabIcon
					defaultIcon={ChatTabDefault}
					hoverIcon={ChatTabHover}
					isPressed={isOpen}
					pressedIcon={ChatTabPressed}
				/>
			) : (
				<Icon defaultIcon={ChatDefault} hoverIcon={ChatHover} />
			)}
		</Badge>
	);
};

export default ChatViewIcon;
