import React, {
	createContext,
	Dispatch,
	SetStateAction,
	useState,
	useContext,
	Fragment
} from 'react';
import Acknowledgement from '../components/Confirmation/Acknowledgement';
import { IAcknowledge } from '../interfaces/acknowledge';
import { IUser } from '../interfaces/user';

export interface IGlobalState {
	/* User information needed throughout client application*/
	user: IUser;
}

// create the context to be able to view and edit global state
const GlobalStatContext = createContext({
	state: {} as Partial<IGlobalState>,
	setState: {} as Dispatch<SetStateAction<Partial<IGlobalState>>>,
	setAcknowledgement: (
		title: string,
		content: string,
		confirmText: string,
		onConfirm: () => void
	): void => {},
	closeAcknowledgement: (): void => {}
});

const GlobalStateProvider = ({
	children,
	value = {} as IGlobalState
}: {
	children: React.ReactNode;
	value?: Partial<IGlobalState>;
}) => {
	const [state, setState] = useState(value);

	const [acknowledgeMessage, setAcknowledgeMessage] = useState<IAcknowledge>({
		title: 'Title',
		content: 'content',
		confirmText: 'ok',
		onConfirm: () => {}
	});
	const [acknowledgeOpen, setAcknowledgeOpen] = useState<boolean>(false);

	const handleOpenAcknowledgement = (
		title: string,
		content: string,
		confirmText: string,
		onConfirm: () => void
	) => {
		setAcknowledgeMessage({
			title,
			content,
			confirmText,
			onConfirm
		});

		setAcknowledgeOpen(true);
	};

	const handleCloseAcknowledgment = () => {
		setAcknowledgeOpen(false);
	};

	return (
		<GlobalStatContext.Provider
			value={{
				state,
				setState,
				setAcknowledgement: handleOpenAcknowledgement,
				closeAcknowledgement: handleCloseAcknowledgment
			}}
		>
			<Fragment>
				{children}
				<Acknowledgement
					visiblity={acknowledgeOpen}
					title={acknowledgeMessage.title}
					content={acknowledgeMessage.content}
					acknowledgeMsg={acknowledgeMessage.confirmText}
					onAcknowledge={acknowledgeMessage.onConfirm}
				/>
			</Fragment>
		</GlobalStatContext.Provider>
	);
};

const useGlobalState = () => {
	const context = useContext(GlobalStatContext);
	if (!context) {
		throw new Error('useGlobalState must be used within a GlobalStateContext');
	}

	return context;
};

export { GlobalStateProvider, useGlobalState };
