import { IValues } from '../components/Form';
/**
 * Validates whether a field has a value
 * @param {IValues} values - All the field values in the form
 * @param {string} fieldName - The field to validate
 * @returns {string} - The error message
 */
export const required = (values: IValues, fieldName: string): string =>
	values[fieldName] === undefined || values[fieldName] === null || values[fieldName] === ''
		? 'This is required'
		: '';

/**
 * Validates whether a field is a valid email
 * @param {IValues} values - All the field values in the form
 * @param {string} fieldName - The field to validate
 * @returns {string} - The error message
 */
export const isEmail = (values: IValues, fieldName: string): string =>
	values[fieldName] &&
	values[fieldName].search(
		/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
	)
		? 'This must be in a valid email format'
		: '';

/**
 * Validates whether a field is within a certain amount of characters
 * @param {IValues} values - All the field values in the form
 * @param {string} fieldName - The field to validate
 * @param {number} length - The maximum number of characters
 * @returns {string} - The error message
 */
export const maxLength = (values: IValues, fieldName: string, length: number): string =>
	values[fieldName] && values[fieldName].length > length
		? `This can not exceed ${length} characters`
		: '';

/**
 * Validates whether a field is within a certain amount of characters
 * @param {IValues} values - All the field values in the form
 * @param {string} fieldName - The field to validate
 * @param {number} length - The minimum number of characters
 * @returns {string} - The error message
 */
export const minLength = (values: IValues, fieldName: string, length: number): string =>
	values[fieldName] && values[fieldName].length < length
		? `This must be more than ${length} characters`
		: '';

/**
 * Validates whether two password fields match
 * @param {IValues} values - All the field values in the form
 * @param {string} fieldName - The field to validate
 * @param {string} otherFieldName - The other name to match
 * @returns {string} - The error message
 */
export const passwordMatch = (values: IValues, fieldName: string, otherFieldName: number): string =>
	values[fieldName] && values[otherFieldName] && values[fieldName] !== values[otherFieldName]
		? `Passwords do not match`
		: '';

/**
 * Validates whether the date is after current date
 * @param {IValues} values - All the field values in the form
 * @param {string} fieldName - The field to validate
 * @returns {string} - The error message
 */
export const futureDate = (values: IValues, fieldName: string): string => {
	const currentDate = new Date();
	const valueDate = values[fieldName];

	const result = valueDate < currentDate ? 'This date is in the past' : '';

	return result;
};
