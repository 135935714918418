import React, { Fragment, useEffect, useState } from 'react';
import DailyIframe, { DailyCall } from '@daily-co/daily-js';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import Fab from '@material-ui/core/Fab';

import * as MessageType from '../../interfaces/message';
import DeviceSelection from '../MediaDeviceSelection/DeviceSelection';
import { getStreamStates } from '../../utils/getStreamStates';
import { useGlobalState } from '../../contexts/GlobalStateProvider';
import { useSocketManager } from '../../contexts/SocketManager';
import { useReducer } from 'react';
import {
	callReducer,
	containsScreenShare,
	initialCallState,
	isScreenSharing,
	PARTICIPANTS_CHANGE
} from '../../utils/callState';

import { Button, makeStyles } from '@material-ui/core';
import { brassPressed } from '../../assets/brassCircularButton';
import Icon from '../Icons/Icon';
import { SettingsDefault, SettingsHover } from '../../assets/icons/settings';
import { HandDefault, HandHover } from '../../assets/icons/hand';
import {
	ScreenShareOnHover,
	ScreenShareOffHover,
	ScreenShareOnDefault,
	ScreenShareOffDefault
} from '../../assets/icons/screenShare';
import { CamOnDefault, CamOnHover, CamOffDefault, CamOffHover } from '../../assets/icons/cam';
import { MicOffDefault, MicOffHover, MicOnDefault, MicOnHover } from '../../assets/icons/mic';
import { WoodenPanel } from '../../assets/desktop';
import ScreenShareErrorHandling from '../ScreenShareErrorHandling';

interface IVideoTrayProps {
	callObject: DailyCall | null;
	socket: WebSocket | null;
	space: string;
	needAssistance: boolean;
	/**
	 * ref to get height of tray
	 */
	trayRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const useStyles = makeStyles(theme => ({
	pressedIcon: {
		backgroundImage: `url(${brassPressed})`,
		boxShadow: '0 5px 15px rgba(0,0,0, .8)'
	},
	woodenPanel: {
		height: 'auto',
		width: '40vw',
		position: 'relative'
	},
	dockContainer: {
		width: '80vw',
		position: 'absolute',
		bottom: 0
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		bottom: 0,
		zIndex: 998,
		marginBottom: '.5rem',
		[theme.breakpoints.up('lg')]: {
			marginBottom: '2rem'
		}
	},
	dock: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'center',
		height: '100%'
	},
	// increase button size for larger screens.
	'@global': {
		[theme.breakpoints.up('lg')]: {
			'.MuiFab-root': {
				width: 78,
				height: 78
			}
		}
	}
}));

const VideoTray: React.FC<IVideoTrayProps> = ({
	callObject,
	socket,
	space,
	needAssistance,
	trayRef
}) => {
	const { state } = useGlobalState();
	const { supervisor } = useSocketManager();

	const [isCameraMuted, setCameraMuted] = useState<boolean | undefined>(false);
	const [isMicMuted, setMicMuted] = useState<boolean | undefined>(false);
	const [isSharingScreen, setSharingScreen] = useState<boolean | undefined>(false);
	const [showScreenShare, setShowScreenShare] = useState<boolean>(true);
	const [showSettings, setShowSettings] = useState<boolean>(false);
	const [cameraReveal, setCameraReveal] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [callState, dispatch] = useReducer(callReducer, initialCallState);

	const classes = useStyles();

	// const getSubscribeState = () =>  {
	// 	if (callObject) {
	// 		if (callObject.participants().local.screenVideoTrack?.enabled) {
	// 			return true;
	// 		}
	// 	}
	// 	return false;
	// }

	const requestAssistance = (): void => {
		// toggle the room needs assistance flag
		let roomNeedsAssistance = true;
		if (needAssistance) {
			roomNeedsAssistance = !needAssistance;
		}
		if (socket) {
			const message: MessageType.ISetAssistanceFlagMessage = {
				type: 'set-assistance-flag',
				space: space,
				data: {
					needsFacilitatorAssistance: roomNeedsAssistance
				}
			};
			socket.send(JSON.stringify(message));
		}
	};

	const toggleReveal = () => {
		if (callObject && supervisor) {
			const name = callObject.participants().local.user_name;
			const videoState = name.split('|')[0];

			if (videoState !== 'subscribe') {
				callObject.setUserName(
					state.user
						? `subscribe|${state.user.firstName} ${state.user.lastName}`
						: 'subscribe|No Name'
				);
				setCameraReveal(true);
			} else {
				callObject.setUserName(
					state.user
						? `nosubscribe|${state.user.firstName} ${state.user.lastName}`
						: 'nosubscribe|No Name'
				);
				setCameraReveal(false);
			}
		}
	};

	/**
	 * Handles toggling camera state
	 */
	const toggleCamera = (): void => {
		if (callObject) {
			callObject.setLocalVideo(isCameraMuted!);
		}
	};

	/**
	 * Handles toggling audio state
	 */
	const toggleMic = (): void => {
		if (callObject) {
			callObject.setLocalAudio(isMicMuted!);
		}
	};

	/**
	 * Handle toggling presenting state
	 */
	const toggleScreenShare = (): void => {
		/**
		 * Daily only supports ScreenShare WITH audio on Chrome and Microsoft Edge.
		 * Check if user is using a supported browser. If not, display an error message dialog window.
		 * **/
		const supportedBrowser =
			DailyIframe.supportedBrowser().name === 'Chrome' ||
			DailyIframe.supportedBrowser().name === 'Microsoft Edge';

		if (callObject && supportedBrowser) {
			isSharingScreen
				? callObject.stopScreenShare()
				: callObject.startScreenShare({
						audio: true
				  });
		} else {
			setError(true);
		}
	};

	/**
	 * Toggles setting modal state.
	 */
	const toggleSettings = (): void => {
		setShowSettings(!showSettings);
	};

	/**
	 * Start listening for participant changes when callObject is set (i.e. when the component mounts).
	 * This event will capture any changes to your audio/video mute state.
	 */
	useEffect(() => {
		if (!callObject) return;

		const handleNewParticipantsState = (): void => {
			const [isCameraMuted, isMicMuted, isSharingScreen] = getStreamStates(callObject);
			setCameraMuted(isCameraMuted);
			setMicMuted(isMicMuted);
			setSharingScreen(isSharingScreen);
			// listen for particpant changes to update callState as participants are joining
			dispatch({
				type: PARTICIPANTS_CHANGE,
				callObject: callObject
			});
		};

		// Use initial state
		handleNewParticipantsState();

		// Listen for changes in state
		callObject.on('participant-updated', handleNewParticipantsState);

		// Stop listening for changes in state
		return function cleanup() {
			callObject.off('participant-updated', handleNewParticipantsState);
		};
	}, [callObject]);

	// check for screensharer
	useEffect(() => {
		if (!callState) return;

		const limitScreenShares = (): void => {
			// disable ability to share screen if there is already a screensharer.
			if (!isScreenSharing('local-screen') && containsScreenShare(callState.callList)) {
				setShowScreenShare(false);
			}
			if (!containsScreenShare(callState.callList)) {
				setShowScreenShare(true);
			}
		};

		limitScreenShares();
	}, [callState]);

	const isSupervisorCameraReveal = () => {
		return !supervisor || (supervisor && cameraReveal);
	};

	return (
		<div className={classes.dockContainer} ref={trayRef}>
			<div className={classes.dock}>
				<Grid
					container
					justify="space-between"
					spacing={5}
					className={classes.buttonContainer}
				>
					{isSupervisorCameraReveal() && (
						<Fragment>
							<Grid item>
								<Tooltip title={isMicMuted ? 'Unmute Mic' : 'Mute Mic'}>
									<span>
										<Fab
											onClick={toggleMic}
											disabled={callObject === null}
											disableTouchRipple
											classes={{
												root: isMicMuted ? classes.pressedIcon : ''
											}}
											aria-label={isMicMuted ? 'Unmute Mic' : 'Mute Mic'}
										>
											{isMicMuted ? (
												<Icon
													defaultIcon={MicOffDefault}
													hoverIcon={MicOffHover}
												/>
											) : (
												<Icon
													defaultIcon={MicOnDefault}
													hoverIcon={MicOnHover}
												/>
											)}
										</Fab>
									</span>
								</Tooltip>
							</Grid>
							<Grid item>
								<Tooltip
									title={isCameraMuted ? 'Turn Camera On' : 'Turn Camera Off'}
								>
									<span>
										<Fab
											onClick={toggleCamera}
											disabled={callObject === null}
											disableTouchRipple
											classes={{
												root: isCameraMuted ? classes.pressedIcon : ''
											}}
											aria-label={
												isCameraMuted ? 'Turn Camera On' : 'Turn Camera Off'
											}
										>
											{isCameraMuted ? (
												<Icon
													defaultIcon={CamOffDefault}
													hoverIcon={CamOffHover}
												/>
											) : (
												<Icon
													defaultIcon={CamOnDefault}
													hoverIcon={CamOnHover}
												/>
											)}
										</Fab>
									</span>
								</Tooltip>
							</Grid>
						</Fragment>
					)}

					{state.user?.role !== 'participant' && (
						<Grid item>
							<Tooltip
								title={
									isSharingScreen
										? 'Stop Presenting Screen'
										: 'Start Presenting Screen'
								}
							>
								<span>
									<Fab
										onClick={toggleScreenShare}
										disabled={callObject === null || !showScreenShare}
										disableTouchRipple
										classes={{
											root: isSharingScreen ? classes.pressedIcon : ''
										}}
										aria-label={
											isSharingScreen
												? 'Stop Presenting Screen'
												: 'Start Presenting Screen'
										}
									>
										{isSharingScreen ? (
											<Icon
												defaultIcon={ScreenShareOffDefault}
												hoverIcon={ScreenShareOffHover}
											/>
										) : (
											<Icon
												defaultIcon={ScreenShareOnDefault}
												hoverIcon={ScreenShareOnHover}
											/>
										)}
									</Fab>
								</span>
							</Tooltip>
						</Grid>
					)}
					{state.user?.role === 'participant' && (
						<Grid item>
							<Tooltip
								title={needAssistance ? 'Cancel Request' : 'Request Assistance'}
							>
								<span>
									<Fab
										onClick={requestAssistance}
										disabled={callObject === null}
										// if user needs assistance, button remains pressed in until the user/staff address it
										classes={{
											root: needAssistance ? classes.pressedIcon : ''
										}}
										disableTouchRipple
										aria-label={
											needAssistance ? 'Cancel Request' : 'Request Assistance'
										}
									>
										<Icon defaultIcon={HandDefault} hoverIcon={HandHover} />
									</Fab>
								</span>
							</Tooltip>
						</Grid>
					)}

					<Grid item>
						<Tooltip title="Open Audio/Video Settings">
							<span>
								<Fab
									onClick={toggleSettings}
									disabled={callObject === null}
									disableTouchRipple
									classes={{
										root: showSettings ? classes.pressedIcon : ''
									}}
								>
									<Icon defaultIcon={SettingsDefault} hoverIcon={SettingsHover} />
								</Fab>
							</span>
						</Tooltip>
					</Grid>
					{supervisor && (
						<div style={{ position: 'absolute', bottom: 5, left: 40 }}>
							<Button variant="contained" onClick={toggleReveal}>
								Become {cameraReveal ? 'Hidden' : 'Visible'}
							</Button>
						</div>
					)}
				</Grid>

				<WoodenPanel className={classes.woodenPanel} />
			</div>

			{showSettings && (
				<DeviceSelection
					callObject={callObject}
					visibility={showSettings}
					onClose={() => setShowSettings(false)}
				/>
			)}

			<ScreenShareErrorHandling visibility={error} onClose={() => setError(false)} />
		</div>
	);
};

export default VideoTray;
