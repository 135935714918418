import React, { Fragment } from 'react';
import { useGlobalState } from '../../../contexts/GlobalStateProvider';
import { useSocketManager } from '../../../contexts/SocketManager';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import PanToolIcon from '@material-ui/icons/PanTool';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

import { ILobby, IRoomUser } from '../../../interfaces/lobby';

import { makeStyles } from '@material-ui/core/styles';

interface IMainStageLobbyViewProps {
	lobby: ILobby;
	space: string;
}

const useStyles = makeStyles(theme => ({
	lobbyView: {
		margin: theme.spacing(0, 1.5),
		padding: theme.spacing(2),
		color: theme.palette.primary.contrastText,
		overflow: 'auto',
		maxHeight: '75vh'
	}
}));

const MainStageLobbyView: React.FC<IMainStageLobbyViewProps> = ({ lobby, space }) => {
	const classes = useStyles();
	const { state } = useGlobalState();
	const { socket } = useSocketManager();

	const handleLowerHand = (userId: string) => {
		if (socket) {
			socket.send(
				JSON.stringify({
					type: 'staff-set-raise-hand',
					space: space,
					data: {
						userId: userId,
						raisedHand: false
					}
				})
			);
		}
	};

	const handleToggleVideo = (user: IRoomUser) => {
		if (socket) {
			const videoEnable = user.videoEnabled ? !user.videoEnabled : true;

			socket.send(
				JSON.stringify({
					type: 'staff-set-video-chatter',
					space: space,
					data: {
						userId: user.id,
						allowed: videoEnable
					}
				})
			);
		}
	};

	/**
	 * Creates a list item on a particular user
	 * @param user - information on the current user
	 * @returns user info to go into the list of users in the room
	 */
	const renderUser = (user: IRoomUser) => {
		return (
			<Grid container key={user.firstName + user.lastName} alignItems="center">
				<Grid item xs={2}>
					{user.role === 'participant' ? (
						<AccountCircleIcon />
					) : (
						<SupervisedUserCircleIcon />
					)}
				</Grid>
				<Grid item xs={6}>
					<Typography variant="body1">
						{user.firstName} {user.lastName}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					{user.handRaised && (
						<Fragment>
							{state.user?.role === 'participant' ? (
								<Tooltip title="Hand Raised">
									<PanToolIcon color="primary" />
								</Tooltip>
							) : (
								<Tooltip title="Hand Raised - Click to Lower">
									<IconButton onClick={() => handleLowerHand(user.id)}>
										<PanToolIcon color="primary" />
									</IconButton>
								</Tooltip>
							)}
						</Fragment>
					)}

					{state.user?.role !== 'participant' && user.role === 'participant' && (
						<Tooltip title={user.videoEnabled ? 'Disable Video' : 'Enable Video'}>
							<IconButton onClick={() => handleToggleVideo(user)}>
								{user.videoEnabled ? (
									<VideocamIcon color="primary" />
								) : (
									<VideocamOffIcon color="primary" />
								)}
							</IconButton>
						</Tooltip>
					)}
					{state.user?.role === 'participant' && user.role === 'participant' && (
						<Tooltip title={user.videoEnabled ? 'Video On' : 'Video Off'}>
							{user.videoEnabled ? (
								<VideocamIcon color="primary" />
							) : (
								<VideocamOffIcon color="primary" />
							)}
						</Tooltip>
					)}
				</Grid>
			</Grid>
		);
	};

	return (
		<Grid container className={classes.lobbyView}>
			<Grid item xs={12}>
				<Typography variant="h5">Room Users</Typography>
				{Object.keys(lobby).map(key => {
					const user = lobby[key];
					return renderUser(user);
				})}
			</Grid>
		</Grid>
	);
};

export default MainStageLobbyView;
