import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	page: {
		height: '100vh',
		width: '100vw',
	},
}));

const WaitingRoom: React.FC = () => {
	const classes = useStyles();
	return (
		<Grid container justify="center" alignItems="center" className={classes.page}>
			<Grid container direction="column" justify="center" alignItems="center" spacing={5}>
				<Grid item>
					<Typography variant="h3">Event has not started Yet</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body1">
						Please wait until a staff memeber has started the event.
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default WaitingRoom;
