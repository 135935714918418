import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import { ILobby, IRoomUser } from '../../../interfaces/lobby';

import { makeStyles } from '@material-ui/core/styles';
import { RoomType } from '../../../types/types';

interface ILobbyViewProps {
	lobby: ILobby;
	roomType: RoomType;
}

const useStyles = makeStyles(theme => ({
	lobbyView: {
		margin: theme.spacing(0, 1.5),
		padding: theme.spacing(2),
		color: theme.palette.primary.contrastText
	}
}));

const LobbyView: React.FC<ILobbyViewProps> = ({ lobby, roomType }) => {
	const classes = useStyles();

	/**
	 * Creates a list item on a particular user
	 * @param user - information on the current user
	 * @returns user info to go into the list of users in the room
	 */
	const renderUser = (user: IRoomUser) => {
		return (
			<Grid container key={user.firstName + user.lastName}>
				<Grid item xs={2}>
					{user.role === 'participant' ? (
						<AccountCircleIcon />
					) : (
						<SupervisedUserCircleIcon />
					)}
				</Grid>
				<Grid item xs={10}>
					<Typography variant="body1">
						{user.firstName} {user.lastName}
					</Typography>
				</Grid>
			</Grid>
		);
	};

	return (
		<div className={classes.lobbyView}>
			<Typography variant="h5">Room Users</Typography>
			{Object.keys(lobby).map(key => {
				const user = lobby[key];
				if (roomType === 'Experience Room' && user.role === 'supervisor') {
					return null;
				} else {
					return renderUser(user);
				}
			})}
		</div>
	);
};

export default LobbyView;
