import { IDesktopContent } from '../../interfaces/desktopContent';
import { IDocument } from '../../interfaces/document';
import { ILobby } from '../../interfaces/lobby';
import * as MessageType from '../../interfaces/message';
import {
	IChatRoomData,
	IExperienceRoomData,
	IVideoRoomData
} from '../../interfaces/roomSocketData';
import { matchImage, matchVideo } from '../fileMatch';

/**
 * Joins an experience room with all relevant information
 * @param joinMessage - Information of the room being joined
 * @returns {IExperienceRoomData} - The joined room compiled together
 */
export const handleJoinedExperienceRoom = (
	joinMessage: MessageType.IJoinedExperienceSpaceMessage
): IExperienceRoomData => {
	const room: IExperienceRoomData = {
		space: joinMessage.space,
		type: joinMessage.data.type,
		name: joinMessage.space.split('_').pop()!,
		messages: [],
		lobby: {},
		assets: joinMessage.data.assets,
		documents: [],
		roomAssistance: joinMessage.data.needsFacilitatorAssistance ? true : false,
		videoRoomUrl: joinMessage.data.videoRoomUrl,
		videoToken: joinMessage.data.videoToken,
		callStatus: 'None',
		loadingCall: false
	};

	room.lobby = createLobby(joinMessage.data.users);

	const chatNames: MessageType.IChatNameData[] = joinMessage.data.chatNames;
	const chatLog = joinMessage.data.chatLog;

	room.messages = constructChatLog(chatLog, chatNames);

	let contents: IDesktopContent[] = [];
	if (joinMessage.data.desktopContents) {
		contents = joinMessage.data.desktopContents;
	}

	if (contents.length > 0) {
		let documentList: IDocument[] = [];
		// add all documents from desktop contents
		contents.forEach(content => {
			// create a new document
			const foundAsset = room.assets!.find(asset => asset.key === content.key);

			if (foundAsset) {
				const isImage = matchImage(foundAsset.url);
				const isVideo = matchVideo(foundAsset.url);

				const newDocument: IDocument = {
					name: content.key,
					url: foundAsset.url,
					type: isImage ? 'image' : isVideo ? 'video' : 'notSupported',
					posX: content.x,
					posY: content.y,
					width: content.width,
					height: content.height
				};

				documentList.push(newDocument);
			}
		});

		room.documents = documentList;
	}

	return room;
};

/**
 * Joins the main stage room of an event with all relevant information
 * @param joinMessage - Information of the room being joined
 * @returns {IVideoRoomData} - The joined room compiled together
 */
export const handleJoinGeneralRoom = (
	joinMessage: MessageType.IJoinedGeneralSpaceMessage
): IVideoRoomData => {
	const room: IVideoRoomData = {
		space: joinMessage.space,
		type: joinMessage.data.type,
		name: joinMessage.space.split('_').pop()!,
		messages: [],
		lobby: {},
		videoRoomUrl: joinMessage.data.videoRoomUrl,
		videoToken: joinMessage.data.videoToken,
		callStatus: 'None',
		loadingCall: false
	};

	room.lobby = createLobby(
		joinMessage.data.users,
		joinMessage.data.raisedHands ? joinMessage.data.raisedHands : []
	);

	const chatNames: MessageType.IChatNameData[] = joinMessage.data.chatNames;
	const chatLog = joinMessage.data.chatLog;

	room.messages = constructChatLog(chatLog, chatNames);

	return room;
};

/**
 * Joins the staff chat room of an event with all relevant information
 * @param joinMessage - Information of the room being joined
 * @returns {IChatRoomData} - The joined room compiled together
 */
export const handleJoinChatRoom = (
	joinMessage: MessageType.IJoinChatSpaceMessage
): IChatRoomData => {
	const room: IChatRoomData = {
		space: joinMessage.space,
		type: joinMessage.data.type,
		name: joinMessage.space.split('_').pop()!,
		messages: [],
		lobby: {}
	};

	room.lobby = createLobby(joinMessage.data.users);

	const chatNames: MessageType.IChatNameData[] = joinMessage.data.chatNames;
	const chatLog = joinMessage.data.chatLog;

	room.messages = constructChatLog(chatLog, chatNames);

	return room;
};

/**
 * Compiles users into a lobby
 * @param {any[]} users - informations on all users currently in lobby
 * @returns {ILobby} - the lobby object with users
 */
const createLobby = (users: any[], raisedHands: string[] = []): ILobby => {
	const lobby: ILobby = {};

	if (users) {
		users.forEach((user: any) => {
			const handRaised =
				raisedHands.findIndex(userHandRaised => userHandRaised === user.id) !== -1;

			lobby[user.id] = {
				id: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
				role: user.role,
				handRaised: handRaised
			};
		});
	}

	return lobby;
};

/**
 * Combines all messages from room
 * @param {MessageType.IChatMessageData[]} chatLog - all previous messages in room before joining
 * @param {MessageType.IChatNameData[]} chatNames - names of all users that have been in the room
 * @returns {MessageType.IMessage[]} - list of all messages formatted
 */
const constructChatLog = (
	chatLog: MessageType.IChatMessageData[],
	chatNames: MessageType.IChatNameData[]
) => {
	let pastMessages: MessageType.IMessage[] = [];

	if (chatLog) {
		chatLog.forEach(chatMessage => {
			const name = chatNames.find(chat => chat.id === chatMessage.from);
			if (name) {
				chatMessage.from = `${name.firstName} ${name.lastName}`;
				chatMessage.role = name.role;
			}
		});

		pastMessages = chatLog.map(chatMessage => buildMessage(chatMessage));

		// Link replies
		chatLog.forEach((chatMessage, index) => {
			if (chatMessage.responseTo) {
				pastMessages[index].replyTo = pastMessages.find(
					m => m.id === chatMessage.responseTo
				);
			}
		});
	}

	return pastMessages;
};

/**
 * formats message to fit onto chat card component
 * @param chatMessage - raw data of message
 * @returns {MessageType.IMessage} - the formatted chat message
 */
const buildMessage = (chatMessage: MessageType.IChatMessageData) => {
	const message: MessageType.IMessage = {
		id: chatMessage.id,
		isAction: chatMessage.isAction,
		from: {
			name: chatMessage.from,
			role: chatMessage.role ? chatMessage.role : ''
		},
		time: new Date(chatMessage.timestamp),
		message: chatMessage.message
	};
	return message;
};
