import React, { useState } from 'react';

import { IHeader } from '../../utils/tableData';

import { useDataState } from '../../contexts/DataProvider';

import Grid, { GridSize } from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { ICompany } from '../../interfaces/company';
import { IEvent } from '../../interfaces/event';
import { IParticipant } from '../../interfaces/participant';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import axios from 'axios';

interface IRoomCellProps {
	/* The participant for this row */
	participant: IParticipant;

	/* The current selected company */
	selectedCompany: ICompany;

	/* The current selected event */
	selectedEvent: IEvent;

	/* When a request has completed */
	onRequestComplete: (severity: 'success' | 'error', message: string) => void;
}

// Cell component to handle room assignment of participant
const RoomCell: React.FC<IRoomCellProps> = ({
	participant,
	selectedCompany,
	selectedEvent,
	onRequestComplete
}) => {
	const { data, setData } = useDataState();

	const [oldRoomValue, setOldRoomValue] = useState<string>('');
	const [roomValue, setRoomValue] = useState<string>(
		participant.roomName ? participant.roomName : ''
	);
	const [savedRoomValue, setSavedRoomValue] = useState<string>(
		participant.roomName ? participant.roomName : ''
	);
	const [hasSaved, setHasSaved] = useState<boolean>(participant.roomName ? true : false);

	/**
	 * When the room drop down changes
	 * @param event - the dropdown event
	 */
	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const newRoom = event.target.value as string;

		// Add room to participant
		setOldRoomValue(roomValue);
		setRoomValue(newRoom);

		setHasSaved(newRoom === savedRoomValue);
	};

	/**
	 * Makes request to change the room for the participant depending on current state (POST/Delete)
	 */
	const saveChanges = () => {
		if (roomValue === '') {
			return;
		} else if (oldRoomValue === '') {
			// If adding participant to room
			axios
				.post(
					`/api/company/${selectedCompany.id}/events/${selectedEvent.id}/rooms/${roomValue}/participants`,
					JSON.stringify({ id: participant.id }),
					{ headers: { 'Content-Type': 'application/json' } }
				)
				.then(res => {
					onRequestComplete('success', 'Participant assigned to room.');

					const selectedRoom = data.rooms!.find(room => room.name === roomValue);

					if (selectedRoom) {
						if (selectedRoom.participants) {
							selectedRoom.participants.push(participant);
						} else {
							selectedRoom.participants = [participant];
						}
						const newRoomList = [...data!.rooms!];
						const roomIndex = newRoomList.findIndex(
							dataRoom => dataRoom.name === roomValue
						);
						newRoomList[roomIndex] = selectedRoom;
						setData(prevData => ({ ...prevData, rooms: newRoomList }));
						setHasSaved(true);
						setSavedRoomValue(roomValue);
					}
				})
				.catch(err => {
					onRequestComplete('error', 'Unable to assign participant, please try again.');
				});
		} else if (roomValue !== oldRoomValue) {
			// If switching particpants to another room
			axios
				.delete(
					`/api/company/${selectedCompany.id}/events/${selectedEvent.id}/rooms/${oldRoomValue}/participants/${participant.id}`
				)
				.then(response => {
					if (response.status === 200) {
						const selectedRoom = data.rooms!.find(room => room.name === oldRoomValue);

						if (selectedRoom) {
							const updatedParticpants = selectedRoom.participants!.filter(
								roomParticipant => roomParticipant.id !== participant.id
							);
							const newRoomList = [...data!.rooms!];
							const roomIndex = newRoomList.findIndex(
								dataRoom => dataRoom.name === oldRoomValue
							);
							newRoomList[roomIndex].participants = updatedParticpants;
							setData(prevData => ({ ...prevData, rooms: newRoomList }));
						}

						axios
							.post(
								`/api/company/${selectedCompany.id}/events/${selectedEvent.id}/rooms/${roomValue}/participants`,
								JSON.stringify({ id: participant.id }),
								{ headers: { 'Content-Type': 'application/json' } }
							)
							.then(res => {
								onRequestComplete('success', 'Room changed.');
								const selectedRoom = data.rooms!.find(
									room => room.name === roomValue
								);

								if (selectedRoom) {
									if (selectedRoom.participants) {
										selectedRoom.participants.push(participant);
									} else {
										selectedRoom.participants = [participant];
									}
									const newRoomList = [...data!.rooms!];
									const roomIndex = newRoomList.findIndex(
										dataRoom => dataRoom.name === roomValue
									);
									newRoomList[roomIndex] = selectedRoom;
									setData(prevData => ({ ...prevData, rooms: newRoomList }));
									setHasSaved(true);
									setSavedRoomValue(roomValue);
								}
							})
							.catch(err => {
								onRequestComplete(
									'error',
									'Unable to change room, please try again.'
								);
							});
					}
				})
				.catch(err => {
					onRequestComplete('error', 'Unable to change room, please try again.');
				});
		}
	};

	return (
		<Grid container alignItems="center" justify="center">
			<Grid item xs={8}>
				<FormControl
					style={{ width: '90%' }}
					disabled={!data.rooms || data.rooms.length <= 0}
				>
					<InputLabel style={{ marginLeft: '20px' }} id="room-label">
						Room
					</InputLabel>
					<Select
						labelId="room-label"
						value={roomValue}
						onChange={handleChange}
						variant="outlined"
					>
						{data.rooms?.map(room => (
							<MenuItem key={room.name} value={room.name}>
								{room.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={2}>
				{!hasSaved && (
					<Button variant="contained" disableTouchRipple onClick={saveChanges}>
						Save
					</Button>
				)}
			</Grid>
		</Grid>
	);
};

interface IParticipantTableProps {
	/* How wide in grid formation should the table be */
	size: GridSize;

	/* The required headers for this table */
	headers: IHeader[];

	/* The required data for this table */
	participantData: IParticipant[];

	/* The current selected company */
	selectedCompany: ICompany;

	/* The current selected event */
	selectedEvent: IEvent;

	/* The selected row */
	selected: any[];

	/* data to exclude from making into cells */
	excludedColumns?: string[];

	/* When the selected row changes*/
	onSelectedChange: (newSelected: any) => void;

	/* When a request has completed */
	onRequestComplete: (severity: 'success' | 'error', message: string) => void;
}

/**
 * Comparing elements in an array from descending order
 * "-" infront of this function will make it ascending order
 * @param {any} a - left element to compare
 * @param {any} b - right element to compare
 * @param {string} orderBy - the field to compare these elements by
 * @returns {number} - The result of the compare
 */
const descendingComparator = (a: any, b: any, orderBy: string): number => {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
};

/**
 * Finds out what order to do the sort (ascending or descending)
 * @param {SortDirection} order - either ascending or descending
 * @param {string} orderBy - the field to compare these elements by
 * @returns {(a:any, b:any) => number} - The appropriate comparing function (ascending or decenting)
 */
const getComparator = (
	order: 'asc' | 'desc' | undefined,
	orderBy: string
): ((a: any, b: any) => number) => {
	return order === 'desc'
		? (a: any, b: any) => descendingComparator(a, b, orderBy)
		: (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

/**
 * Get the comparing criteria for this sort
 * @param {any[]} array - an array of elements to sort
 * @param {any} comparator - the comparing criteria for this sort
 * @returns {any[]} - The sorted array
 */
const stableSort = (array: any[], comparator: any): any[] => {
	const stabilizedThis = array.map((el: any, index: any) => [el, index]);
	stabilizedThis.sort((a: any, b: any) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el: any) => el[0]);
};

const ParticipantTable: React.FC<IParticipantTableProps> = ({
	size,
	headers,
	selectedCompany,
	selectedEvent,
	participantData,
	selected,
	excludedColumns,
	onSelectedChange,
	onRequestComplete
}) => {
	const [orderBy, setOrderBy] = useState<string>(headers[0].id);
	const [order, setOrder] = useState<'asc' | 'desc' | undefined>('asc');
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);

	const isSelected = (id: any): boolean => selected.indexOf(id) >= 0;

	/**
	 * When user clicks on a row
	 * @param {React.MouseEvent<HTMLTableRowElement, MouseEvent>} event - The Click event that is passed by default
	 * @param {any} id - The Id of the element in that row
	 */
	const handleRowClick = (
		event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
		id: any
	): void => {
		const selectedIdx = selected.indexOf(id);
		let newSelected = [...selected];

		if (selectedIdx === -1) {
			newSelected.push(id);
		} else {
			newSelected = selected.filter(selectId => selectId !== id);
		}

		onSelectedChange(newSelected);
	};

	/**
	 * When user clicks the select all check box
	 * @param {React.MouseEvent<HTMLTableRowElement, MouseEvent>} event - The Click event that is passed by default
	 */
	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = participantData.map(data => data.id);
			onSelectedChange(newSelecteds);
			return;
		}
		onSelectedChange([]);
	};

	/**
	 * Changing the page of the table
	 * @param {React.MouseEvent<HTMLTableRowElement, MouseEvent>} event - The Click event that is passed by default
	 */
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
		newPage: number
	): void => {
		setPage(newPage);
	};

	/**
	 * Changing how many rows on one page
	 * @param {any} event - The change event information
	 */
	const handleChangeRowsPerPage = (event: any): void => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	/**
	 * Check if the given property key is a excluded column (and "id") on this table
	 * @param {string} key - The property key to examin the excluded ones from
	 * @returns {boolean} - If this key is an excluded column or not
	 */
	const isExcludedColumn = (key: string): boolean => {
		if (key === 'id') {
			return true;
		}

		let result: boolean = false;

		if (excludedColumns) {
			excludedColumns.forEach((col: any) => {
				if (key === col) {
					result = true;
				}
			});
		}
		return result;
	};

	/**
	 * Handles the adjusment of sorting a particular column
	 * @param {string} property - The property (key) that is being sorted
	 * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The default button event that is passed
	 */
	const handleRequestSort =
		(property: string) =>
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
			const isAsc = orderBy === property && order === 'asc';
			setOrder(isAsc ? 'desc' : 'asc');
			setOrderBy(property);
		};

	return (
		<Grid container justify="center">
			<Grid item xs={size}>
				<Paper>
					<TableContainer>
						<Table size="medium">
							<TableHead>
								<TableRow>
									<TableCell padding="checkbox">
										<Checkbox
											indeterminate={
												selected.length > 0 &&
												selected.length < participantData.length
											}
											checked={
												participantData.length > 0 &&
												selected.length === participantData.length
											}
											onChange={handleSelectAllClick}
										/>
									</TableCell>
									{headers.map((headCell: IHeader) => (
										<TableCell
											key={headCell.id}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={handleRequestSort(headCell.id)}
											>
												<span style={{ fontWeight: 'bold' }}>
													{headCell.label}
												</span>
											</TableSortLabel>
										</TableCell>
									))}
									<TableCell
										sortDirection={orderBy === 'roomName' ? order : false}
									>
										<TableSortLabel
											active={orderBy === 'roomName'}
											direction={orderBy === 'roomName' ? order : 'asc'}
											onClick={handleRequestSort('roomName')}
										>
											<span style={{ fontWeight: 'bold' }}>
												Assigned Room
											</span>
										</TableSortLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(participantData, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row: any, index: any) => {
										const isItemSelected = isSelected(row.id);
										return (
											<TableRow
												key={row.email}
												role="checkbox"
												selected={isItemSelected}
												onClick={event => handleRowClick(event, row.id)}
											>
												<TableCell padding="checkbox">
													<Checkbox checked={isItemSelected} />
												</TableCell>
												{Object.keys(row).map(
													(key: string, index: number) => {
														if (!isExcludedColumn(key)) {
															return (
																<TableCell key={index}>
																	{row[key] === true
																		? 'Yes'
																		: row[key] === false
																		? 'No'
																		: row[key]}
																</TableCell>
															);
														} else {
															return null;
														}
													}
												)}
												<TableCell>
													<RoomCell
														participant={row as IParticipant}
														selectedCompany={selectedCompany}
														selectedEvent={selectedEvent}
														onRequestComplete={onRequestComplete}
													/>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={participantData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default ParticipantTable;
