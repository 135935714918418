import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { RemoveType } from "../../types/types";

interface IArchiveConfirmationProps {
    /** The state of showing the confirmation */
    showConfirmation: boolean;

    /** The title of the confirmation */
    title: string;

    /** The message of the confirmation */
    message: string;

    /** When user closes the confirmation */
    onCloseDialog: () => void;

    /** When user confirms the confirmation's action */
    onConfirmRemove: (removeType: RemoveType) => void;
}


const ArchiveConfirmation: React.FC<IArchiveConfirmationProps> = ({showConfirmation, title, message, onCloseDialog, onConfirmRemove}) => {

    const [removeType, setRemoveType] = useState<RemoveType>('archive');

    /**
     * Change the remove type when user selects specific radio button
     * @param {React.ChangeEvent<HTMLInputElement>} event - Handle when the remove type changes in confirmation dialog
     */
    const handleRemoveTypeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRemoveType((event.target as HTMLInputElement).value as RemoveType);
    };

    return (
        <Dialog open={showConfirmation} onClose={onCloseDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
                <RadioGroup name="removeCompany" value={removeType} onChange={handleRemoveTypeChange}>
                    <FormControlLabel value="archive" control={<Radio />} label="Archive" />
                    <FormControlLabel value="delete" control={<Radio />} label="Delete Completely"/>
                </RadioGroup>
                {removeType === 'delete' && (
                    <Typography variant="body1" color="error">This will completely remove all information from the database</Typography>
                )}                 
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onConfirmRemove(removeType)} color="primary">Confirm</Button>
                <Button onClick={onCloseDialog} color="primary">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ArchiveConfirmation;