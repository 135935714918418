import React, { useEffect, useReducer, useState, Fragment } from 'react';
import { DailyCall, DailyEvent, DailyEventObjectActiveSpeakerChange, DailyTrackState } from '@daily-co/daily-js';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import MicOffIcon from '@material-ui/icons/MicOff';
import { makeStyles } from '@material-ui/core/styles';

import { useGlobalState } from '../../contexts/GlobalStateProvider';
import { useSocketManager } from '../../contexts/SocketManager';
import VideoTile from './VideoTile';
import {
	initialCallState,
	CLICK_ALLOW_TIMEOUT,
	PARTICIPANTS_CHANGE,
	CAM_OR_MIC_ERROR,
	FATAL_ERROR,
	callReducer,
	isLocal,
	isScreenSharing,
	containsScreenShare,
} from '../../utils/callState';
import { NamePlate } from '../../assets/namePlate';
import { Tooltip } from '@material-ui/core';

/**
 * Check if a certain track of a specific user is off
 * @param {any} trackState - the track of either video or audio
 * @returns {boolean} - if the current users track (video or audio) is off
 */
const isTrackOff = (trackState: any): boolean => {
	if (!trackState) return false;
	switch (trackState.state) {
		case 'off':
			if (trackState.off.byUser) {
				return true;
			}
	}
	return false;
};

interface IVideoPanelProps {
	callObject: DailyCall | null;
	staffPanel: boolean;
}

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		width: '80vw',
		flexWrap: 'wrap',
		overflow: 'auto',
		height: '20vh'
	},
	gridList: {
		flexWrap: 'nowrap',
		transform: 'translateZ(0)',
		height: '100%',
		width: '100%'
	},
	title: {
		color: 'white',
		textTransform: 'capitalize'
	},
	titleBar: {
		backgroundImage: `url(${NamePlate})`,
		backgroundSize: 'cover',
		position: 'initial',
		borderBottom: `5px inset ${theme.palette.primary.main}`,
		padding: theme.spacing(0, 0.75)
	},
	titleBarSpeaking: {
		backgroundImage: `url(${NamePlate})`,
		backgroundSize: 'cover',
		position: 'initial',
		borderBottom: `5ypx inset ${theme.palette.secondary.light}`,
		padding: theme.spacing(0, 0.75)
	},
	tileRoot: {
		height: 'auto',
		width: '12.5%',
		minHeight: 164,
		margin: 0
	},
	tile: {
		overflow: 'visible',
		height: 164
	},
	settingsOffTile: {
		height: 164,
		backgroundColor: theme.palette.secondary.contrastText,
		overflow: 'visible'
	},
	icon: { color: theme.palette.primary.contrastText },
	'@global': {
		'.MuiGridListTileBar-titleWrap': {
			margin: 0,
			display: 'flex',
			justifyContent: 'flex-start',
			padding: '.25rem'
		}
	}
}));

const VideoPanel: React.FC<IVideoPanelProps> = ({ callObject, staffPanel }) => {
	const classes = useStyles();
	const [callState, dispatch] = useReducer(callReducer, initialCallState);
	const { state } = useGlobalState();
	const { supervisor } = useSocketManager();

	const [speakerId, setSpeakerId] = useState<string>('');

	//Start listening for participant changes, when the callObject is set.
	useEffect(() => {
		if (!callObject) return;

		const events: DailyEvent[] = [
			'participant-joined',
			'participant-updated',
			'participant-left'
		];

		const handleNewParticipantsState = (): void => {
			dispatch({
				type: PARTICIPANTS_CHANGE,
				callObject: callObject
			});
		};

		// Commneted out for now, may use later
		const handleActiveSpeaker = (
			activeSpeaker: DailyEventObjectActiveSpeakerChange | undefined
		): void => {
			if (activeSpeaker) {
				setSpeakerId(activeSpeaker.activeSpeaker.peerId);
			} else {
				setSpeakerId('');
			}
		};

		// Use initial state
		handleNewParticipantsState();

		// Listen for changes in state
		for (const event of events) {
			callObject.on(event, handleNewParticipantsState);
		}

		callObject.on('active-speaker-change', handleActiveSpeaker);

		// Stop listening for changes in state
		return function cleanup() {
			for (const event of events) {
				callObject.off(event, handleNewParticipantsState);
			}

			callObject.off('active-speaker-change', handleActiveSpeaker);
		};
	}, [callObject]);

	//Start listening for call errors, when the callObject is set.
	useEffect(() => {
		if (!callObject) return;

		const handleCameraErrorEvent = (event: any) => {
			dispatch({
				type: CAM_OR_MIC_ERROR,
				message: (event && event.errorMsg && event.errorMsg.errorMsg) || 'Unknown'
			});
		};

		callObject.on('camera-error', handleCameraErrorEvent);

		return function cleanup() {
			callObject.off('camera-error', handleCameraErrorEvent);
		};
	}, [callObject]);

	//Start listening for fatal errors, when the callObject is set.
	useEffect(() => {
		if (!callObject) return;

		function handleErrorEvent(e: any) {
			dispatch({
				type: FATAL_ERROR,
				message: (e && e.errorMsg) || 'Unknown'
			});
		}

		callObject.on('error', handleErrorEvent);

		return function cleanup() {
			callObject.off('error', handleErrorEvent);
		};
	}, [callObject]);

	//Start a timer to show the "click allow" message, when the component mounts.
	useEffect(() => {
		const t = setTimeout(() => {
			dispatch({ type: CLICK_ALLOW_TIMEOUT });
		}, 2500);

		return function cleanup() {
			clearTimeout(t);
		};
	}, []);

	useEffect(() => {
		if (!callObject) return;

		if (staffPanel) {
			if (state.user?.role !== 'participant' || supervisor) {
				callObject?.setUserName(
					state.user
						? `subscribe|${state.user.firstName} ${state.user.lastName}`
						: 'subscribe|No Name'
				);
			} else {
				callObject?.setUserName(
					state.user
						? `nosubscribe|${state.user.firstName} ${state.user.lastName}`
						: 'nosubscribe|No Name'
				);
			}
		} else {
			if (supervisor) {
				callObject?.setUserName(
					state.user
						? `nosubscribe|${state.user.firstName} ${state.user.lastName}`
						: 'nosubscribe|No Name'
				);
			} else {
				callObject?.setUserName(
					state.user
						? `subscribe|${state.user.firstName} ${state.user.lastName}`
						: 'subscribe|No Name'
				);
			}
		}
	}, [callObject, staffPanel, state.user, supervisor]);

	return (
		<div className={classes.root}>
			<GridList cellHeight={160} className={classes.gridList} cols={8} style={{ margin: 0 }}>
				{callObject !== null &&
					callState.callList
						.filter(
							(callItem: any) =>
								!isScreenSharing(callItem.id) ||
								(!isLocal(callItem.id) && containsScreenShare(callState.callItem))
						)
						.map((callItem: any) => (
							<GridListTile
								rows={1}
								key={callItem.id}
								style={{ padding: 0 }}
								classes={{ root: classes.tileRoot, tile: classes.tile }}
							>
								<VideoTile
									isLocalPerson={isLocal(callItem.id)}
									videoTrackState={callItem.videoTrackState as DailyTrackState}
									audioTrackState={callItem.audioTrackState as DailyTrackState}
								/>

								<GridListTileBar
									title={callItem.name}
									classes={{
										root: callItem.sessionId === speakerId && !isTrackOff(callItem.audioTrackState) ? classes.titleBarSpeaking : classes.titleBar,
										title: classes.title
									}}
									actionIcon={
										<Fragment>
											{isTrackOff(callItem.audioTrackState) && (
												<Tooltip title="User is Muted">
													<MicOffIcon className={classes.icon} />
												</Tooltip>
											)}
											{isTrackOff(callItem.videoTrackState) && (
												<Tooltip title="User's Camera is Off">
													<VideocamOffIcon className={classes.icon} />
												</Tooltip>
											)}
										</Fragment>
									}
								/>
							</GridListTile>
						))}
			</GridList>
		</div>
	);
};

export default VideoPanel;
