import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { IMessage } from '../../interfaces/message';

interface IChatCardProps {
	message: IMessage;
}

const useStyles = makeStyles(theme => ({
	container: {
		padding: '5px',
		marginBottom: '10px'
	},
	action: {
		backgroundColor: theme.palette.secondary.main
	},
	message: {
		backgroundColor: theme.palette.grey[50]
	}
}));

const StaffChatCard: React.FC<IChatCardProps> = ({ message }) => {
	const classes = useStyles();

	return (
		<Paper className={message.isAction ? classes.action : classes.message}>
			<Grid container className={classes.container}>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={8}>
							<Typography variant="body2" color="primary">
								{message.from?.name}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Grid container justify="flex-end">
								<Typography variant="body2" color="primary">
									{message.time.toLocaleTimeString()}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} style={{ wordWrap: 'break-word' }}>
					<Typography variant="body1">{message.message}</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default StaffChatCard;
