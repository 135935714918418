import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDataState } from '../../contexts/DataProvider';
import { useGlobalState } from '../../contexts/GlobalStateProvider';
import { useSocketManager } from '../../contexts/SocketManager';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import GroupIcon from '@material-ui/icons/Group';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import Autocomplete from '@material-ui/lab/Autocomplete';

import EventNavigationTable from '../../components/EventNavigationTable';
import StaffChat from '../../components/StaffChat/StaffChat';
import { eventNavigationHeaderCells } from '../../utils/tableData';

import { ICompany } from '../../interfaces/company';
import { IEvent } from '../../interfaces/event';
import { IRoom } from '../../interfaces/room';

import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { IFacilitatorData } from '../../interfaces/roomSocketData';

const useStyles = makeStyles(theme => ({
	title: {
		marginTop: '25px'
	},
	spacing: {
		marginTop: '20px'
	}
}));

const EventNavigation: React.FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const { state, setAcknowledgement, closeAcknowledgement } = useGlobalState();
	const { data, setData } = useDataState();
	const {
		socket,
		rooms,
		facilitatorJoinEvent,
		facilitatorJoinRooms,
		facilitatorData,
		facilitatorUpdateEvent
	} = useSocketManager();

	const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
	const [companyInputValue, setCompanyInputValue] = useState<string>('');
	const [eventInputValue, setEventInputValue] = useState<string>('');
	const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);
	const [archiveChecked, setArchiveChecked] = useState<boolean>(false);
	const [eventRooms, setEventRooms] = useState<IRoom[]>([]);
	const [eventTime, setEventTime] = useState<string>('');

	const [selectedRooms, setSelectedRooms] = useState<any[]>([]);

	/**
	 * Takes the staff memeber to a specific room
	 * @param {string} roomName - Name of room to go to
	 */
	const handleJoinGameRoom = (roomName: string): void => {
		if (socket && selectedEvent && selectedCompany) {
			const newFacilitatorData: IFacilitatorData = {
				companyId: selectedCompany.id!,
				companyName: selectedCompany.name!,
				eventId: selectedEvent.id!,
				eventName: selectedEvent.name!,
				roomEntered: 'Experience Room',
				eventState: facilitatorData!.eventState!,
				rooms: [roomName]
			};

			facilitatorJoinRooms(newFacilitatorData);

			history.push('./experience-room');
		}
	};

	/**
	 * Takes the staff memeber to the facilitator view page
	 */
	const handleJoinFacilitatorView = (): void => {
		if (socket && selectedEvent && selectedCompany) {
			const newFacilitatorData: IFacilitatorData = {
				companyId: selectedCompany.id!,
				companyName: selectedCompany.name!,
				eventId: selectedEvent.id!,
				eventName: selectedEvent.name!,
				roomEntered: 'Experience Room',
				eventState: facilitatorData!.eventState!,
				rooms: [...selectedRooms]
			};

			facilitatorJoinRooms(newFacilitatorData);

			history.push('./facilitator-view');
		}
	};

	/**
	 * Takes the staff memeber to the Main Stage of the event
	 */
	const handleJoinMainStage = (): void => {
		if (socket && facilitatorData && facilitatorData.eventId) {
			socket.send(
				JSON.stringify({
					type: 'staff-set-main-stage-request',
					space: `staffRoom_${facilitatorData.eventId}`,
					data: {
						wantMainStage: true
					}
				})
			);
		}
	};

	/**
	 * Takes the staff memeber to the Main Stage of the event
	 */
	const handleJoinGreenRoom = (): void => {
		if (socket && facilitatorData && facilitatorData.eventId) {
			socket.send(
				JSON.stringify({
					type: 'staff-set-green-room-request',
					space: `staffRoom_${facilitatorData.eventId}`,
					data: {
						wantGreenRoom: true
					}
				})
			);
		}
	};

	/**
	 * Handles when to show archive events or not
	 * @param {React.ChangeEvent<HTMLInputElement>} event - the event that comes with Checkbox
	 */
	const handleArchiveChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const checked = event.target.checked;
		setArchiveChecked(checked);

		handleGetEvents(checked, selectedCompany!);

		if (!checked) {
			if (selectedEvent?.isArchived) {
				clearEventInput();
			}
		}
	};

	/**
	 * Handles when to show archive events or not
	 * @param {string[]} newSelectedRooms - all the rooms currently selected
	 */
	const handleSelectedRoomChange = (newSelectedRooms: string[]): void => {
		setSelectedRooms(newSelectedRooms);
	};

	/**
	 * Removes the selected event and value in auto complete
	 */
	const clearEventInput = (): void => {
		setSelectedEvent(null);
		setEventInputValue('');
	};

	/**
	 * Handles when the selected company has changed
	 * @param {ICompany | null} newSelectedCompany - The new selected company
	 */
	const handleChangeCompany = (newSelectedCompany: ICompany | null): void => {
		setSelectedCompany(newSelectedCompany);

		if (newSelectedCompany) {
			handleGetEvents(archiveChecked, newSelectedCompany);
		}

		clearEventInput();
	};

	/**
	 * Handles when the selected event has changed
	 * @param {IEvent | null} newSelectedEvent - The new selected event
	 */
	const handleChangeEvent = (newSelectedEvent: IEvent | null): void => {
		setSelectedEvent(newSelectedEvent);

		if (newSelectedEvent) {
			if (selectedCompany !== null) {
				if (selectedCompany.name && newSelectedEvent.name && newSelectedEvent.id) {
					facilitatorJoinEvent(
						selectedCompany.name,
						newSelectedEvent.name,
						newSelectedEvent.id
					);
				}

				if (newSelectedEvent) {
					axios
						.get(`/api/company/${selectedCompany.id}/events/${newSelectedEvent.id}`)
						.then(response => {
							const event: IEvent = response.data;
							if (event.startTime) {
								const time = new Date(event.startTime);
								const dateTimeFormat = new Intl.DateTimeFormat('en', {
									year: 'numeric',
									month: 'long',
									day: 'numeric',
									hour: 'numeric',
									minute: 'numeric'
								});
								const format = dateTimeFormat.format(time);
								setEventTime(format);
							}
							if (event.rooms) {
								setEventRooms(event.rooms);
							} else {
								setEventRooms([]);
							}
						});
				}
			}
		} else {
			setEventRooms([]);
		}
	};

	/**
	 * Makes request to get Events (with archives included)
	 * @param {ICompany} selectedCompany - the current selected comapny
	 */
	const getAllEvents = useCallback(
		(selectedCompany: ICompany): void => {
			axios
				.get(`/api/company/${selectedCompany.id}/events?type=all`)
				.then(response => {
					const events: IEvent[] = [];
					response.data.forEach((event: IEvent) => {
						events.push(event);
					});
					setData(prevData => ({ ...prevData, events: events }));
				})
				.catch(err => {
					console.log(err);
					setAcknowledgement(
						'Error',
						'We were unable to retreive events for this company, please try again or contact support.',
						'Ok',
						closeAcknowledgement
					);
				});
		},
		[setData, setAcknowledgement, closeAcknowledgement]
	);

	/**
	 * Makes request to get Events (not including archives)
	 * @param {ICompany} selectedCompany - the current selected comapny
	 */
	const getUnarchivedEvents = useCallback(
		(selectedCompany: ICompany): void => {
			axios
				.get(`/api/company/${selectedCompany.id}/events`)
				.then(response => {
					const events: IEvent[] = [];
					response.data.forEach((event: IEvent) => {
						events.push(event);
					});
					setData(prevData => ({ ...prevData, events: events }));
				})
				.catch(err => {
					console.log(err);
					setAcknowledgement(
						'Error',
						'We were unable to retreive events for this company, please try again or contact support.',
						'Ok',
						closeAcknowledgement
					);
				});
		},
		[setData, setAcknowledgement, closeAcknowledgement]
	);

	/**
	 * Handles which get request to do based on the current state of archive toggle
	 * @param {boolean} includeArchive - whether to include archives or not
	 * @param {ICompany} selectedCompany - the current selected comapny
	 */
	const handleGetEvents = useCallback(
		(includeArchive: boolean, selectedCompany: ICompany): void => {
			if (includeArchive) {
				getAllEvents(selectedCompany);
			} else {
				getUnarchivedEvents(selectedCompany);
			}
		},
		[getAllEvents, getUnarchivedEvents]
	);

	const getEventStatus = (): string => {
		if (facilitatorData) {
			switch (facilitatorData?.eventState) {
				case 'not-started':
					return 'Not Started';
				case 'main-stage':
					return 'Presentation on Main Stage';
				case 'running':
					return 'Currently Running';
				case 'finished':
					return 'finished';
			}
		}
		return 'Unknown';
	};

	useEffect(() => {
		if (
			facilitatorData &&
			facilitatorData.companyId &&
			facilitatorData.companyName &&
			facilitatorData.eventId &&
			facilitatorData.eventName
		) {
			if (data.companies) {
				const selectCompany = data.companies.find(
					company => (company.id = facilitatorData.companyId)
				);

				if (selectCompany) {
					setSelectedCompany(selectCompany);
					setCompanyInputValue(facilitatorData.companyName);
					handleGetEvents(archiveChecked, selectCompany);
					setEventInputValue(facilitatorData.eventName);

					axios
						.get(
							`/api/company/${facilitatorData.companyId}/events/${facilitatorData.eventId}`
						)
						.then(response => {
							const event: IEvent = response.data;

							setSelectedEvent(event);
							if (event.startTime) {
								const time = new Date(event.startTime);
								const dateTimeFormat = new Intl.DateTimeFormat('en', {
									year: 'numeric',
									month: 'long',
									day: 'numeric',
									hour: 'numeric',
									minute: 'numeric'
								});
								const format = dateTimeFormat.format(time);
								setEventTime(format);
							}
							if (event.rooms) {
								setEventRooms(event.rooms);
							} else {
								setEventRooms([]);
							}
						});
				}
			}
		}
	}, [facilitatorData, data.companies, archiveChecked, handleGetEvents]);

	return (
		<Fragment>
			<Grid container justify="center">
				<Grid item className={classes.title}>
					<Typography variant="h3">Event Navigation</Typography>
					<hr />
				</Grid>
				<Grid container justify="center">
					<Grid item xs={6} className={classes.spacing}>
						<Autocomplete
							options={data.companies ? data.companies : []}
							getOptionLabel={option => (option.name ? option.name : '')}
							getOptionSelected={(option, value) => option.id === value.id}
							renderInput={params => (
								<TextField {...params} label="Company" variant="outlined" />
							)}
							onChange={(event: any, newValue: ICompany | null) =>
								handleChangeCompany(newValue)
							}
							inputValue={companyInputValue}
							onInputChange={(event, newInputValue) =>
								setCompanyInputValue(newInputValue)
							}
						/>
					</Grid>
				</Grid>
				<Grid item xs={6} className={classes.spacing}>
					<Autocomplete
						options={data.events ? data.events : []}
						getOptionLabel={option => (option.name ? option.name : '')}
						getOptionSelected={(option, value) => option.id === value.id}
						renderInput={params => (
							<TextField {...params} label="Event" variant="outlined" />
						)}
						onChange={(event: any, newValue: IEvent | null) =>
							handleChangeEvent(newValue)
						}
						disabled={selectedCompany === null}
						inputValue={eventInputValue}
						onInputChange={(event, newInputValue) => setEventInputValue(newInputValue)}
					/>
				</Grid>
				<Grid container justify="center" className={classes.spacing}>
					<Grid item>
						<FormControlLabel
							control={
								<Checkbox
									checked={archiveChecked}
									color="primary"
									onChange={handleArchiveChange}
								/>
							}
							label="Show Archived Events"
							disabled={selectedCompany === null}
						/>
					</Grid>
				</Grid>
				{facilitatorData?.eventState === 'unknown' ? (
					<div className={classes.spacing}>
						<Typography variant="h3">Loading Event...</Typography>
						<LinearProgress style={{ marginTop: '15px' }} />
					</div>
				) : facilitatorData?.eventState === 'not-started' ? (
					<Grid
						container
						direction="column"
						className={classes.spacing}
						justify="center"
						alignItems="center"
						spacing={5}
					>
						<Grid item>
							<Typography variant="h3">Event has not started.</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body1">Event Time: {eventTime}</Typography>
						</Grid>
						{state.user?.role === 'admin' && (
							<Grid item>
								<Button
									variant="contained"
									disableTouchRipple
									onClick={() => facilitatorUpdateEvent('main-stage')}
								>
									Move to Presentation Stage
								</Button>
							</Grid>
						)}
					</Grid>
				) : (
					selectedCompany &&
					selectedEvent && (
						<Fragment>
							<Grid container className={classes.spacing} justify="center">
								<Grid item>
									<Typography variant="h5">
										Event Status: {getEventStatus()}
									</Typography>
								</Grid>
							</Grid>
							{state.user?.role === 'admin' && (
								<Grid container className={classes.spacing} justify="center">
									<Grid item xs={4}>
										<Grid container justify="center">
											<Grid item>
												<Typography variant="h6">Change Status</Typography>
											</Grid>
										</Grid>
										<Grid
											container
											className={classes.spacing}
											justify="space-around"
										>
											<Grid item>
												<Button
													variant="contained"
													disableTouchRipple
													disabled={
														facilitatorData?.eventState === 'main-stage'
													}
													onClick={() =>
														facilitatorUpdateEvent('main-stage')
													}
												>
													Main Stage
												</Button>
											</Grid>
											<Grid item>
												<Button
													variant="contained"
													disableTouchRipple
													disabled={
														facilitatorData?.eventState === 'running'
													}
													onClick={() =>
														facilitatorUpdateEvent('running')
													}
												>
													Start Event
												</Button>
											</Grid>
											<Grid item>
												<Button
													variant="contained"
													disableTouchRipple
													disabled={
														facilitatorData?.eventState === 'finished'
													}
													onClick={() =>
														facilitatorUpdateEvent('finished')
													}
												>
													Finish Event
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							)}

							<Grid container className={classes.spacing} justify="center">
								<EventNavigationTable
									size={4}
									headers={eventNavigationHeaderCells}
									roomData={eventRooms}
									selected={selectedRooms}
									onJoinRoom={handleJoinGameRoom}
									onSelectedChange={handleSelectedRoomChange}
								/>
							</Grid>
							<Grid
								item
								xs={4}
								className={classes.spacing}
								style={{ marginBottom: '25px' }}
							>
								<Grid container justify="space-between">
									<Grid item>
										<Button
											variant="contained"
											disableTouchRipple
											startIcon={<GroupIcon />}
											disabled={!selectedCompany || !selectedEvent}
											onClick={handleJoinGreenRoom}
										>
											Green Room
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											disableTouchRipple
											startIcon={<DesktopWindowsIcon />}
											disabled={!selectedCompany || !selectedEvent}
											onClick={handleJoinMainStage}
										>
											Main Stage
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											disableTouchRipple
											startIcon={<ViewColumnIcon />}
											disabled={
												selectedRooms.length <= 1 ||
												selectedRooms.length > 5
											}
											onClick={handleJoinFacilitatorView}
										>
											Facilitator View
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											disableTouchRipple
											onClick={() => facilitatorUpdateEvent('not-started')}
										>
											Undo Event
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Fragment>
					)
				)}
			</Grid>
			{rooms.staffChatRoom && <StaffChat socket={socket} roomData={rooms.staffChatRoom} />}
		</Fragment>
	);
};

export default EventNavigation;
