import React from 'react';

import Grid, { GridSize } from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

interface IDetailsProps {
	/** The title of the details */
	title: string;

	/** The grid size of this detail panel */
	size: GridSize;

	/** When the user close the details */
	onClose: () => void;

	header?: React.ReactElement;
}

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: '50px',
		marginBottom: '25px'
	},
	DetailPaper: {
		paddingRight: '20px',
		paddingLeft: '20px',
		paddingBottom: '10px',
		paddingTop: '10px'
	}
}));

const Details: React.FC<IDetailsProps> = ({ title, size, onClose, children, header }) => {
	const classes = useStyles();

	return (
		<Grid container justify="center" className={classes.container}>
			<Grid item xs={size}>
				<Paper className={classes.DetailPaper}>
					<Grid container justify="flex-end">
						<Grid item>
							<IconButton color="primary" onClick={() => onClose()}>
								<Close />
							</IconButton>
						</Grid>
					</Grid>

					<Grid container justify="center" style={{ marginBottom: '2rem' }}>
						<Grid item xs={11}>
							{header}
						</Grid>
					</Grid>

					<Grid container justify="center">
						<Grid item>
							<Typography variant="h6">{title}</Typography>
							<hr />
						</Grid>
					</Grid>
					{children}
				</Paper>
			</Grid>
		</Grid>
	);
};

export default Details;
