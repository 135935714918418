import React, { useEffect, useState } from 'react';

import { useSocketManager } from '../contexts/SocketManager';

import { useGlobalState } from '../contexts/GlobalStateProvider';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { IParticipant } from '../interfaces/participant';

import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	page: {
		height: '100vh',
		width: '100vw'
	},
	title: {
		textAlign: 'center'
	},
	line: {
		width: '100%'
	}
}));

const ParticipantLogin: React.FC = (props: any) => {
	const classes = useStyles();

	const { state, setState, setAcknowledgement, closeAcknowledgement } = useGlobalState();

	const { connect, socket } = useSocketManager();

	const [error, setError] = useState<boolean>(false);

	const tokenParam = new URLSearchParams(props.location.search).get('loginCode');
	const emailParam = new URLSearchParams(props.location.search).get('email');

	useEffect(() => {
		if (!socket && !state.user) {
			// Validate login code and email
			const credentials = {
				email: emailParam,
				loginCode: tokenParam
			};
			// if no user is signed in, attempt to sign in via email & login code
			if (credentials) {
				axios
					.post(`/api/login`, JSON.stringify(credentials), {
						headers: { 'Content-Type': 'application/json' }
					})
					.then(response => {
						const userData: IParticipant = response.data;
						setState(() => ({
							user: {
								id: userData.id!,
								email: userData.email!,
								firstName: userData.firstName!,
								lastName: userData.lastName!,
								role: 'participant'
							}
						}));
					})
					.catch(err => {
						setError(true);
					});
			} else {
				setError(true);
			}
		}
	}, [setState, state, socket, tokenParam, emailParam]);

	const handleLoginRequest = () => {
		const credentials = {
			email: emailParam,
			loginCode: tokenParam
		};
		// get email and login code from prev (failed) login session.
		// Make req to send new link.
		axios
			.post('/api/login-request', JSON.stringify(credentials), {
				headers: { 'Content-Type': 'application/json' }
			})
			.then(res =>
				setAcknowledgement(
					'New Login Link Has Been Sent!',
					'Please check your email and try to login again.',
					'OK',
					closeAcknowledgement
				)
			)
			.catch(err => {
				setError(true);
				setAcknowledgement(
					'Request for New Login Link Failed',
					`We were unable to send you a new link. Please try again or contact support.`,
					'OK',
					closeAcknowledgement
				);
			});
	};

	const handleSendNewLink = () => {
		// log user out, then send new login link
		axios
			.post('/api/logout')
			.then(res => {
				setState(() => ({ user: undefined }));
			})
			.then(() => handleLoginRequest())
			.catch(err => {
				setAcknowledgement(
					'Request for New Login Link Failed',
					`We were unable to send you a new link. Please try again or contact support.`,
					'Ok',
					closeAcknowledgement
				);
			});
	};

	const handleContinue = () => {
		// continue to appropriate room on click if correct user is displayed
		connect();
	};

	return (
		<Grid container justify="center" alignItems="center" className={classes.page}>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				spacing={10}
				style={{ width: '100%' }}
			>
				<Grid item className={classes.title}>
					<Typography variant="h4">Learn2 Platform</Typography>
					<Typography variant="h3">SAVE THE TITANIC</Typography>
					<hr className={classes.line} />
					{error && (
						<Grid item>
							<Typography variant="body1">
								Sorry, we were unable to verify your validations for participation.
							</Typography>
							<Button
								variant="text"
								color="primary"
								style={{ padding: '0 .35rem', fontSize: 16 }}
								onClick={handleSendNewLink}
							>
								Resend login link.
							</Button>
						</Grid>
					)}
				</Grid>
				{state.user && (
					<Grid container direction="column" justify="center" alignItems="center">
						<Grid
							item
							style={{
								display: 'flex',
								flexDirection: 'column'
							}}
						>
							<Typography variant="h5">Hello, {state.user?.firstName}!</Typography>
						</Grid>
						<Grid
							container
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}
						>
							<Grid item>
								<Button
									variant="contained"
									style={{ margin: '1rem 0' }}
									onClick={handleContinue}
								>
									Continue to Event
								</Button>
							</Grid>
							<Grid
								item
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'flex-end'
								}}
							>
								<Typography variant="subtitle2" style={{ fontWeight: 400 }}>
									Not you? Get a new{' '}
								</Typography>
								<Button
									variant="text"
									color="primary"
									style={{ padding: '0 .35rem' }}
									onClick={handleSendNewLink}
								>
									sign-in link.
								</Button>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default ParticipantLogin;
