import React, { Component, createContext, ReactNode } from 'react';
import { IFieldProps } from './Field';

export interface IFields {
	/*All the field properties and their attributes */
	[key: string]: IFieldProps;
}

interface IFormProps {
	/* Reference to all the fields in this form */
	fields: IFields;

	/* A prop which allows content to be injected */
	render: () => ReactNode;

	/* Submit method when validation passes */
	onSubmit: (values: any) => void;
}

export interface IValues {
	/* Key value pairs for all the field values with key being the field name */
	[key: string]: any;
}

export interface IErrors {
	/* The validation error messages for each field (key is the field name) */
	[key: string]: string[];
}

export interface IFormState {
	/* The field values */
	values: IValues;

	/* The field validation error messages */
	errors: IErrors;
}

export interface IFormContext extends IFormState {
	/* Function that allows values in the values state to be set */
	setValues: (values: IValues) => void;

	/* Function that validates a field */
	validate: (fieldName: string) => void;
}

export const FormContext = createContext<IFormContext | undefined>(undefined);

class Form extends Component<IFormProps, IFormState> {
	constructor(props: IFormProps) {
		super(props);

		const errors: IErrors = {};
		const values: IValues = {};

		// Set the initial properties of this form
		// This is incase this is already values in the fields (example: editing account)
		Object.keys(props.fields).forEach(key => {
			values[key] = props.fields[key].value;
		});

		this.state = {
			errors,
			values
		};
	}

	/**
	 * Returns whether there are any errors in the errors object that is passed in
	 * @param {IErrors} errors - The field errors
	 */
	private haveErrors(errors: IErrors): boolean {
		let haveError: boolean = false;
		Object.keys(errors).forEach((key: string) => {
			if (errors[key].length > 0) {
				haveError = true;
			}
		});
		return haveError;
	}

	/**
	 * Handles form submission
	 * @param {React.FormEvent<HTMLFormElement>} e - The form event
	 */
	private handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
		e.preventDefault();

		if (this.validateForm()) {
			this.props.onSubmit(this.state.values);
		}
	};

	/**
	 * Executes the validation rules for all the fields on the form and sets the error state
	 * @returns {boolean} - Whether the form is valid or not
	 */
	private validateForm(): boolean {
		const errors: IErrors = {};
		Object.keys(this.props.fields).forEach((fieldName: string) => {
			errors[fieldName] = this.validate(fieldName);
		});
		this.setState({ errors });
		return !this.haveErrors(errors);
	}

	/**
	 * Stores new field values in state
	 * @param {IValues} values - The new field values
	 */
	private setValues = (values: IValues): void => {
		this.setState({ values: { ...this.state.values, ...values } });
	};

	/**
	 * Executes the validation rule for the field and updates the form errors
	 * @param {string} fieldName - The field to validate
	 * @returns {string} - The error message
	 */
	private validate = (fieldName: string): string[] => {
		let newErrors: string[] = [];
		if (this.props.fields[fieldName] && this.props.fields[fieldName].validation) {
			this.props.fields[fieldName].validation?.forEach(validator => {
				const err = validator?.rule(this.state.values, fieldName, validator.args);

				if (err !== '') {
					newErrors.push(err);
				}
			});
		}
		this.setState({ errors: { ...this.state.errors, [fieldName]: newErrors } });
		return newErrors;
	};

	public render() {
		const context: IFormContext = {
			...this.state,
			setValues: this.setValues,
			validate: this.validate
		};

		return (
			<FormContext.Provider value={context}>
				<form onSubmit={this.handleSubmit} noValidate={true}>
					<div className="container">{this.props.render()}</div>
				</form>
			</FormContext.Provider>
		);
	}
}

export default Form;
