import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;

	return {
		width,
		height
	};
};

const useWindowDimensions = () => {
	const [windowDimensions, setWindowDimensions] = useState<{ width: number; height: number }>(
		getWindowDimensions()
	);

	useEffect(() => {
		const handleResize = () => {
			setWindowDimensions(getWindowDimensions());
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	});

	return windowDimensions;
};

export default useWindowDimensions;
