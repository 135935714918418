import React, { useState, useRef, useLayoutEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from '../../TabPanel';
import RoomManagerHeader from './RoomManagerHeader';
import AssetView from '../AssetView/AssetView';
import ChatView from '../ChatView/ChatView';
import ChatViewIcon from '../ChatView/ChatViewIcon';

import { makeStyles } from '@material-ui/core/styles';
import LobbyView from '../LobbyView/LobbyView';
import { IAsset } from '../../../interfaces/asset';
import { FrameChat, WoodPanel } from '../../../assets/chatPanel';
import { IChatRoomData, IExperienceRoomData } from '../../../interfaces/roomSocketData';
import AnnouncementViewIcon from '../ChatView/AnnouncementViewIcon';
import AnnouncementChatView from '../ChatView/AnnouncementChatView';

import {
	FolderTabDefault,
	FolderTabHover,
	FolderTabPressed,
	UsersTabDefault,
	UsersTabHover,
	UsersTabPressed
} from '../../../assets/icons/chatTabs';
import TabIcon from '../../Icons/TabIcon';
import {
	Tab1Default,
	Tab1Hover,
	Tab1Pressed,
	Tab2Default,
	Tab2Hover,
	Tab2Pressed,
	Tab3Default,
	Tab3Hover,
	Tab3Pressed,
	Tab4Default,
	Tab4Hover,
	Tab4Pressed
} from '../../../assets/chatTab';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import { Container, Tooltip } from '@material-ui/core';

interface IRoomMangerProps {
	socket: WebSocket | null;
	roomData: IExperienceRoomData;
	announcementRoomData: IChatRoomData;
	onPreviewAsset: (asset: IAsset) => void;
	/**
	 * ref to get height of panel
	 */
	panelRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const useStyles = makeStyles(theme => ({
	root: {
		maxHeight: '100vh',
		width: '100vw'
	},
	sidePanel: {
		width: '20vw',
		height: 'auto',
		position: 'absolute',
		top: 0,
		right: 0,
		display: 'inline'
	},
	tab: {
		height: 50,
		minWidth: 25,
		paddingLeft: 0,
		paddingRight: 0,
		[theme.breakpoints.up('lg')]: {
			height: 75
		}
	},

	woodPanel: {
		position: 'absolute',
		left: -40,
		zIndex: 1000,
		[theme.breakpoints.down('md')]: {
			left: -33
		}
	},
	panel: {
		color: theme.palette.primary.contrastText,
		backgroundImage: `url(${FrameChat})`,
		backgroundSize: 'contain'
	},
	tab1: {
		padding: theme.spacing(0),
		backgroundImage: `url(${Tab1Default})`,
		backgroundSize: 'cover',
		'&:hover': {
			backgroundImage: `url(${Tab1Hover})`,
			backgroundSize: 'cover'
		},
		'&:active': {
			backgroundImage: `url(${Tab1Pressed})`,
			backgroundSize: 'cover'
		}
	},
	tab1Pressed: {
		backgroundImage: `url(${Tab1Pressed})`,
		backgroundSize: 'cover'
	},
	tab2: {
		padding: theme.spacing(0),
		backgroundImage: `url(${Tab2Default})`,
		backgroundSize: 'cover',
		'&:hover': {
			backgroundImage: `url(${Tab2Hover})`,
			backgroundSize: 'cover'
		},
		'&:active': {
			backgroundImage: `url(${Tab2Pressed})`,
			backgroundSize: 'cover'
		}
	},
	tab2Pressed: {
		backgroundImage: `url(${Tab2Pressed})`,
		backgroundSize: 'cover'
	},
	tab3: {
		padding: theme.spacing(0),
		backgroundImage: `url(${Tab3Default})`,
		backgroundSize: 'cover',
		'&:hover': {
			backgroundImage: `url(${Tab3Hover})`,
			backgroundSize: 'cover'
		},
		'&:active': {
			backgroundImage: `url(${Tab3Pressed})`,
			backgroundSize: 'cover'
		}
	},
	tab3Pressed: {
		backgroundImage: `url(${Tab3Pressed})`,
		backgroundSize: 'cover'
	},
	tab4Pressed: {
		backgroundImage: `url(${Tab4Pressed})`,
		backgroundSize: 'cover'
	},
	tab4: {
		padding: theme.spacing(0),
		backgroundImage: `url(${Tab4Default})`,
		backgroundSize: 'cover',
		'&:hover': {
			backgroundImage: `url(${Tab4Hover})`,
			backgroundSize: 'cover'
		},
		'&:active': {
			backgroundImage: `url(${Tab4Pressed})`,
			backgroundSize: 'cover'
		}
	},
	'@global': {
		'.MuiTab-textColorInherit': {
			opacity: 1
		}
	}
}));

const RoomManager: React.FC<IRoomMangerProps> = ({
	socket,
	roomData,
	announcementRoomData,
	onPreviewAsset,
	panelRef
}) => {
	const classes = useStyles();
	const { height, width } = useWindowDimensions();

	const [tabValue, setTabValue] = useState<number>(0);
	const [isChatOpen, setIsChatOpen] = useState<boolean>(true);
	const [isAnnouncementsOpen, setIsAnnouncementsOpen] = useState<boolean>(false);
	const [headerHeight, setHeaderHeight] = useState<number>(0);
	const [windowDimensions, setWindowDimensions] = useState<{ height: number; width: number }>({
		height,
		width
	});
	const sidePanelHeaderRef = useRef<HTMLDivElement | null>(null);

	/**
	 * Handles the tab value changing
	 * @param newValue - new value to change to
	 */
	const handleTabChange = (newValue: number): void => {
		setTabValue(newValue);
		if (newValue === 0) {
			setIsChatOpen(true);
		} else {
			setIsChatOpen(false);
		}

		if (newValue === 3) {
			setIsAnnouncementsOpen(true);
		} else {
			setIsAnnouncementsOpen(false);
		}
	};

	//  grabs and sets the values of the side panel's header to calculate
	// 	what the exact height of the chat component should be.
	useLayoutEffect(() => {
		if (sidePanelHeaderRef.current !== null) {
			setHeaderHeight(sidePanelHeaderRef.current.clientHeight);
		}
		setWindowDimensions({ height, width });
	}, [sidePanelHeaderRef, height, width]);

	return (
		<Container disableGutters className={classes.sidePanel} ref={panelRef}>
			<WoodPanel className={classes.woodPanel} style={{ height: windowDimensions.height }} />
			<Grid container direction="column" style={{ height: 'auto', display: 'inline' }}>
				<Grid item ref={sidePanelHeaderRef}>
					<RoomManagerHeader
						roomName={roomData.name}
						space={roomData.space}
						needAssistance={roomData.roomAssistance!}
					/>
					<Tabs
						value={tabValue}
						onChange={(event: React.ChangeEvent<{}>, value: any) =>
							handleTabChange(value)
						}
						TabIndicatorProps={{
							style: {
								display: 'none'
							}
						}}
						variant="fullWidth"
					>
						<Tooltip title={isChatOpen ? 'Chat' : 'View Chat'}>
							<Tab
								icon={
									<ChatViewIcon
										messages={roomData.messages}
										isOpen={isChatOpen}
										tab
									/>
								}
								className={classes.tab}
								classes={{
									root: isChatOpen ? classes.tab1Pressed : classes.tab1
								}}
								disableTouchRipple
							/>
						</Tooltip>
						<Tooltip title={tabValue === 1 ? 'Room Users' : 'View Room Users'}>
							<Tab
								icon={
									<TabIcon
										defaultIcon={UsersTabDefault}
										hoverIcon={UsersTabHover}
										isPressed={tabValue === 1}
										pressedIcon={UsersTabPressed}
									/>
								}
								className={classes.tab}
								classes={{
									root: tabValue === 1 ? classes.tab2Pressed : classes.tab2
								}}
								disableTouchRipple
							/>
						</Tooltip>
						<Tooltip title={tabValue === 2 ? 'Assets' : 'View Assets'}>
							<Tab
								icon={
									<TabIcon
										defaultIcon={FolderTabDefault}
										hoverIcon={FolderTabHover}
										isPressed={tabValue === 2}
										pressedIcon={FolderTabPressed}
									/>
								}
								className={classes.tab}
								classes={{
									root: tabValue === 2 ? classes.tab3Pressed : classes.tab3
								}}
								disableTouchRipple
							/>
						</Tooltip>
						<Tooltip
							title={isAnnouncementsOpen ? 'Announcements' : 'View Announcements'}
						>
							<Tab
								icon={
									<AnnouncementViewIcon
										messages={announcementRoomData.messages}
										isOpen={isAnnouncementsOpen}
									/>
								}
								className={classes.tab}
								classes={{
									root: isAnnouncementsOpen ? classes.tab4Pressed : classes.tab4
								}}
								disableTouchRipple
							/>
						</Tooltip>
					</Tabs>
				</Grid>
				<Grid
					item
					className={classes.panel}
					style={{ height: windowDimensions.height - headerHeight }}
				>
					<TabPanel index={0} value={tabValue}>
						<ChatView
							socket={socket}
							space={roomData.space}
							messages={roomData.messages}
							makeActions={true}
							height={windowDimensions.height - headerHeight}
						/>
					</TabPanel>
					<TabPanel index={1} value={tabValue}>
						<LobbyView lobby={roomData.lobby} roomType="Experience Room" />
					</TabPanel>
					<TabPanel index={2} value={tabValue}>
						<AssetView
							socket={socket}
							space={roomData.space}
							assets={roomData.assets!}
							onPreviewAsset={onPreviewAsset}
						/>
					</TabPanel>
					<TabPanel index={3} value={tabValue}>
						<AnnouncementChatView
							socket={socket}
							space={announcementRoomData.space}
							messages={announcementRoomData.messages}
							height={windowDimensions.height - headerHeight}
						/>
					</TabPanel>
				</Grid>
			</Grid>
		</Container>
	);
};

export default RoomManager;
