import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { DailyTrackState } from '@daily-co/daily-js';

interface IVideoTileProps {
	audioTrackState: DailyTrackState;
}

const AudioTrack: React.FC<IVideoTileProps> = ({ audioTrackState }) => {
	const audioEl = useRef<any>(null);

	const audioTrack = useMemo(() => {
		return audioTrackState && audioTrackState.state === 'playable'
			? audioTrackState.track
			: null;
	}, [audioTrackState]);

	//When audio track changes, update audio srcObject
	useEffect(() => {
		audioEl.current &&
			(audioEl.current!.srcObject = new MediaStream([audioTrack!]));
	}, [audioTrack]);

	return (
		<Fragment>
			{audioTrack && <audio autoPlay playsInline ref={audioEl} />}
		</Fragment>
	);
};

export default AudioTrack;
