import React, {useEffect} from 'react';
import { useSocketManager } from '../contexts/SocketManager';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import EventNavigationTable from '../components/EventNavigationTable';
import { eventNavigationHeaderCells } from '../utils/tableData';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	spacing: {
		marginTop: '25px',
		marginBotton: '25px'
	}
}));

const SupervisorNavigation: React.FC = () => {
	const classes = useStyles();
	const { supervisorRooms, socket, connect } = useSocketManager();

	useEffect(() => {
		if (!socket) {
			connect();
		}
	}, [socket, connect]);

	const handleJoinGameRoom = (roomName: string): void => {
		if (socket) {
			socket.send(
				JSON.stringify({
					type: 'staff-join-room',
					space: supervisorRooms.space,
					data: {
						roomName: roomName
					}
				})
			);
		}
	};

	/**
	 * Takes the staff memeber to the Main Stage of the event
	 */
	 const handleJoinMainStage = (): void => {
		if (socket) {
			socket.send(
				JSON.stringify({
					type: 'staff-set-main-stage-request',
					space: supervisorRooms.space,
					data: {
						wantMainStage: true
					}
				})
			);
		}
	};

	/**
	 * Takes the staff memeber to the Main Stage of the event
	 */
	const handleJoinGreenRoom = (): void => {
		if (socket) {
			socket.send(
				JSON.stringify({
					type: 'staff-set-green-room-request',
					space: supervisorRooms.space,
					data: {
						wantGreenRoom: true
					}
				})
			);
		}
	};

	return (
		<Grid container justify="center" alignItems="center">
			<Grid container direction="column" justify="center" alignItems="center" spacing={5}>
				<Grid item>
					<Typography variant="h3">Supervisor Navigation</Typography>
				</Grid>
			</Grid>
			<Grid container className={classes.spacing} justify="center">
				<EventNavigationTable
					size={4}
					headers={eventNavigationHeaderCells}
					roomData={supervisorRooms.rooms}
					onJoinRoom={handleJoinGameRoom}
				/>
			</Grid>
			<Grid item xs={6}>
				<Grid container className={classes.spacing} justify="space-around">
					<Grid item>
						<Button variant="contained" onClick={handleJoinMainStage}>Main Stage</Button>
					</Grid>
					<Grid item>
						<Button variant="contained" onClick={handleJoinGreenRoom}>Green Room</Button>
					</Grid>
				</Grid>
			</Grid>
			
		</Grid>
	);
};

export default SupervisorNavigation;
