import React, { useState, useLayoutEffect } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from '../../../components/TabPanel';
import FacilitatorRoomManagerHeader from '../Facilitator/FacilitatorRoomManagerHeader';
import AssetView from '../AssetView/AssetView';
import ChatView from '../ChatView/ChatView';
import ChatViewIcon from '../ChatView/ChatViewIcon';
import LobbyView from '../LobbyView/LobbyView';

import { IAsset } from '../../../interfaces/asset';

import { IChatRoomData, IExperienceRoomData } from '../../../interfaces/roomSocketData';

import { makeStyles } from '@material-ui/core/styles';
import AnnouncementViewIcon from '../ChatView/AnnouncementViewIcon';
import AnnouncementChatView from '../ChatView/AnnouncementChatView';
import { FrameChat } from '../../../assets/chatPanel';
import {
	FolderTabDefault,
	FolderTabHover,
	FolderTabPressed,
	UsersTabDefault,
	UsersTabHover,
	UsersTabPressed
} from '../../../assets/icons/chatTabs';
import {
	Tab1Default,
	Tab1Hover,
	Tab1Pressed,
	Tab2Default,
	Tab2Hover,
	Tab2Pressed,
	Tab3Default,
	Tab3Hover,
	Tab3Pressed,
	Tab4Default,
	Tab4Hover,
	Tab4Pressed
} from '../../../assets/chatTab';
import TabIcon from '../../Icons/TabIcon';
import { useRef } from 'react';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import { Grid, useTheme } from '@material-ui/core';

interface IFacilitatorRoomManagerProps {
	socket: WebSocket | null;
	space: string;
	roomData: IExperienceRoomData;
	announcementRoomData: IChatRoomData;
	onPreviewAsset: (asset: IAsset) => void;
	announcementRoomHeaderHeight: number;
}

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap'
	},
	box: {
		backgroundImage: `url(${FrameChat})`,
		backgroundSize: 'contain',
		width: '20vw',
		maxHeight: '100vh',
		display: 'inline',
		height: 'auto'
	},
	tabsContainer: {
		width: '20vw'
	},
	tab: {
		height: 50,
		minWidth: 25,
		paddingLeft: 0,
		paddingRight: 0,
		[theme.breakpoints.up('lg')]: {
			height: 75
		}
	},
	tab1: {
		padding: theme.spacing(0),
		backgroundImage: `url(${Tab1Default})`,
		backgroundSize: 'cover',
		'&:hover': {
			backgroundImage: `url(${Tab1Hover})`,
			backgroundSize: 'cover'
		},
		'&:active': {
			backgroundImage: `url(${Tab1Pressed})`,
			backgroundSize: 'cover'
		}
	},
	tab1Pressed: {
		backgroundImage: `url(${Tab1Pressed})`,
		backgroundSize: 'cover'
	},
	tab2: {
		padding: theme.spacing(0),
		backgroundImage: `url(${Tab2Default})`,
		backgroundSize: 'cover',
		'&:hover': {
			backgroundImage: `url(${Tab2Hover})`,
			backgroundSize: 'cover'
		},
		'&:active': {
			backgroundImage: `url(${Tab2Pressed})`,
			backgroundSize: 'cover'
		}
	},
	tab2Pressed: {
		backgroundImage: `url(${Tab2Pressed})`,
		backgroundSize: 'cover'
	},
	tab3: {
		padding: theme.spacing(0),
		backgroundImage: `url(${Tab3Default})`,
		backgroundSize: 'cover',
		'&:hover': {
			backgroundImage: `url(${Tab3Hover})`,
			backgroundSize: 'cover'
		},
		'&:active': {
			backgroundImage: `url(${Tab3Pressed})`,
			backgroundSize: 'cover'
		}
	},
	tab3Pressed: {
		backgroundImage: `url(${Tab3Pressed})`,
		backgroundSize: 'cover'
	},
	tab4Pressed: {
		backgroundImage: `url(${Tab4Pressed})`,
		backgroundSize: 'cover'
	},
	tab4: {
		padding: theme.spacing(0),
		backgroundImage: `url(${Tab4Default})`,
		backgroundSize: 'cover',
		'&:hover': {
			backgroundImage: `url(${Tab4Hover})`,
			backgroundSize: 'cover'
		},
		'&:active': {
			backgroundImage: `url(${Tab4Pressed})`,
			backgroundSize: 'cover'
		}
	},
	'@global': {
		'.MuiTab-textColorInherit': {
			opacity: 1
		}
	}
}));

const FacilitatorRoomManager: React.FC<IFacilitatorRoomManagerProps> = ({
	socket,
	space,
	roomData,
	announcementRoomData,
	onPreviewAsset,
	announcementRoomHeaderHeight
}) => {
	const classes = useStyles();
	const theme = useTheme();

	const [tabValue, setTabValue] = useState<number>(0);

	const [isChatOpen, setIsChatOpen] = useState<boolean>(true);
	const [isAnnouncementsOpen, setIsAnnouncementsOpen] = useState<boolean>(false);
	const [roomManagerHeaderHeight, setRoomManagerHeaderHeight] = useState<number | undefined>(0);
	const roomManagerHeaderRef = useRef<HTMLDivElement | null>(null);
	const { height, width } = useWindowDimensions();

	useLayoutEffect(() => {
		if (roomManagerHeaderRef !== null) {
			setRoomManagerHeaderHeight(roomManagerHeaderRef.current?.clientHeight);
		}
	}, [roomManagerHeaderRef, setRoomManagerHeaderHeight]);

	/**
	 * Handles the tab value changing
	 * @param newValue - new value to change to
	 */
	const handleTabChange = (newValue: number): void => {
		setTabValue(newValue);
		if (newValue === 0) {
			setIsChatOpen(true);
		} else {
			setIsChatOpen(false);
		}

		if (newValue === 3) {
			setIsAnnouncementsOpen(true);
		} else {
			setIsAnnouncementsOpen(false);
		}
	};

	// get the height of various refs to calculate the height of the chat panel.
	const assetHeight = height - (announcementRoomHeaderHeight as number);

	const isLgScreen = width >= theme.breakpoints.values.lg ? 75 : 50;

	const chatHeight =
		height -
		(announcementRoomHeaderHeight as number) -
		(roomManagerHeaderHeight as number) -
		isLgScreen;

	return (
		<div className={classes.root}>
			<div className={classes.box} style={{ height: assetHeight }}>
				<div ref={roomManagerHeaderRef}>
					<FacilitatorRoomManagerHeader
						roomName={roomData.name}
						space={roomData.space}
						needAssistance={roomData.roomAssistance!}
						isCallLoading={roomData.loadingCall!}
						callStatus={roomData.callStatus!}
					/>
				</div>
				<Grid container>
					<div>
						<Tabs
							value={tabValue}
							onChange={(event: React.ChangeEvent<{}>, value: any) =>
								handleTabChange(value)
							}
							variant="fullWidth"
							TabIndicatorProps={{
								style: {
									display: 'none'
								}
							}}
							classes={{ flexContainer: classes.tabsContainer }}
						>
							<Tab
								icon={
									<ChatViewIcon
										messages={roomData.messages}
										isOpen={isChatOpen}
										tab
									/>
								}
								className={classes.tab}
								classes={{
									root: isChatOpen ? classes.tab1Pressed : classes.tab1
								}}
								disableTouchRipple
							/>
							<Tab
								icon={
									<TabIcon
										defaultIcon={UsersTabDefault}
										hoverIcon={UsersTabHover}
										isPressed={tabValue === 1}
										pressedIcon={UsersTabPressed}
									/>
								}
								className={classes.tab}
								classes={{
									root: tabValue === 1 ? classes.tab2Pressed : classes.tab2
								}}
								disableTouchRipple
							/>
							<Tab
								icon={
									<TabIcon
										defaultIcon={FolderTabDefault}
										hoverIcon={FolderTabHover}
										isPressed={tabValue === 2}
										pressedIcon={FolderTabPressed}
									/>
								}
								className={classes.tab}
								classes={{
									root: tabValue === 2 ? classes.tab3Pressed : classes.tab3
								}}
								disableTouchRipple
							/>
							<Tab
								icon={
									<AnnouncementViewIcon
										messages={announcementRoomData.messages}
										isOpen={isAnnouncementsOpen}
									/>
								}
								className={classes.tab}
								classes={{
									root: isAnnouncementsOpen ? classes.tab4Pressed : classes.tab4
								}}
								disableTouchRipple
							/>
						</Tabs>
					</div>
					<div>
						<TabPanel index={0} value={tabValue}>
							<ChatView
								socket={socket}
								space={space}
								messages={roomData.messages}
								makeActions={false}
								height={chatHeight}
							/>
						</TabPanel>
						<TabPanel index={1} value={tabValue}>
							<LobbyView lobby={roomData.lobby} roomType="Experience Room" />
						</TabPanel>
						<TabPanel index={2} value={tabValue}>
							<AssetView
								socket={socket}
								space={space}
								assets={roomData.assets!}
								onPreviewAsset={onPreviewAsset}
							/>
						</TabPanel>
						<TabPanel index={3} value={tabValue}>
							<AnnouncementChatView
								socket={socket}
								space={announcementRoomData.space}
								messages={announcementRoomData.messages}
								height={chatHeight}
							/>
						</TabPanel>
					</div>
				</Grid>
			</div>
		</div>
	);
};

export default FacilitatorRoomManager;
