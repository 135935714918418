import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useGlobalState } from '../contexts/GlobalStateProvider';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';

import { makeStyles } from '@material-ui/core/styles';

import Form from '../components/Form';
import Field from '../components/Field';
import { loginFields, confirmEmailFields } from '../utils/inputFields';

import { IStaff } from '../interfaces/staff';

import axios from 'axios';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	page: {
		height: '100vh',
		width: '100vw'
	},
	title: {
		textAlign: 'center'
	},
	line: {
		width: '100%'
	},
	formArea: {
		width: '100%'
	},
	confirmButton: {
		marginTop: '25px'
	}
}));

const Login: React.FC = () => {
	const classes = useStyles();

	const history = useHistory();
	const [openReset, setOpenReset] = useState<boolean>(false);
	const [openResetConfirm, setOpenResetConfirm] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [resetErrorMessage, setResetErrorMessage] = useState<string>('');

	const { state, setState } = useGlobalState();

	useEffect(() => {
		if (state.user?.email !== undefined && state.user.role !== 'participant') {
			history.push('/dashboard');
		}
	}, [history, state]);

	/**
	 * Handling the open of reset dialogue
	 */
	const handleResetOpen = (): void => {
		setOpenReset(true);
	};

	/**
	 * Handling the close of reset dialogue
	 */
	const handleResetClose = (): void => {
		setOpenReset(false);
	};

	/**
	 * Handling open of reset confirm snackbar
	 */
	const handleResetConfirmClose = (): void => {
		setOpenResetConfirm(false);
	};

	/**
	 * Makes request to add or modify participant to database (POST)
	 * @param {any} loginInfo - All the login information (email, password)
	 */
	const handleLogin = (loginInfo: any): void => {
		axios
			.post(
				'/api/staff/login',
				JSON.stringify({ email: loginInfo.email, password: loginInfo.password }),
				{ headers: { 'Content-Type': 'application/json' } }
			)
			.then(response => {
				setOpenResetConfirm(true);
				setOpenReset(false);

				const userData: IStaff = response.data;

				setState(() => ({
					user: {
						id: userData.id,
						firstName: userData.firstName,
						lastName: userData.lastName,
						email: userData.email,
						role: userData.role
					}
				}));
				history.push('/dashboard');
			})
			.catch(err => {
				const code = err.response.status;

				if (code === 401) {
					setErrorMessage('Login failed, could not authenticate email and/or password.');
				} else if (code === 429) {
					setErrorMessage('Too many login attempts, please wait a minute to try again.');
				} else {
					setErrorMessage('Oops, Something went wrong! Contact Support.');
				}
			});
	};

	/**
	 * Makes request to add or modify participant to database (POST)
	 * @param {any} loginInfo - All the login information (email, password)
	 */
	const confirmEmailReset = (value: any): void => {
		axios
			.post(
				'/api/staff/password_reset_request',
				JSON.stringify({ email: value.confirmEmail }),
				{ headers: { 'Content-Type': 'application/json' } }
			)
			.then(response => {
				setResetErrorMessage('');
				setOpenResetConfirm(true);
				setOpenReset(false);
			})
			.catch(err => {
				setResetErrorMessage('Oops, Something went wrong! Contact Support.');
			});
	};

	return (
		<Fragment>
			<Grid container justify="center" alignItems="center" className={classes.page}>
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
					spacing={10}
					style={{ width: '100%' }}
				>
					<Grid item className={classes.title}>
						<Typography variant="h4">Learn2 Platform</Typography>
						<Typography variant="h3">SAVE THE TITANIC</Typography>
						<hr className={classes.line} />
					</Grid>
					<Grid container justify="center">
						<Grid item xs={4}>
							<Form
								onSubmit={handleLogin}
								fields={loginFields}
								render={() => (
									<Grid
										container
										direction="column"
										alignItems="center"
										spacing={2}
									>
										<Grid item>
											<Typography
												variant="h5"
												style={{ textAlign: 'center' }}
											>
												Staff Login
											</Typography>
											<Typography variant="body1" color="error">
												{errorMessage}
											</Typography>
										</Grid>
										<Grid item className={classes.formArea}>
											<Field {...loginFields.email} />
											<Field {...loginFields.password} />
											<Grid container justify="flex-end">
												<Tooltip title="Send Password Reset Email">
													<Button
														onClick={handleResetOpen}
														color="primary"
													>
														Reset Password
													</Button>
												</Tooltip>
											</Grid>
											<Button
												type="submit"
												variant="contained"
												fullWidth
												className={classes.confirmButton}
												disableTouchRipple
											>
												Confirm
											</Button>
										</Grid>
									</Grid>
								)}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Dialog open={openReset} onClose={handleResetClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
				<Form
					onSubmit={confirmEmailReset}
					fields={confirmEmailFields}
					render={() => (
						<Fragment>
							<DialogContent>
								<DialogContentText>
									Please enter your Email Address, An Email will be sent to you
									with instructions to reset your password.
								</DialogContentText>
								<Typography variant="body1" color="error">
									{resetErrorMessage}
								</Typography>
								<Field {...confirmEmailFields.confirmEmail} />
							</DialogContent>
							<DialogActions>
								<Button onClick={handleResetClose} color="primary">
									Cancel
								</Button>
								<Button type="submit" color="primary">
									Reset
								</Button>
							</DialogActions>
						</Fragment>
					)}
				/>
			</Dialog>
			<Snackbar
				open={openResetConfirm}
				autoHideDuration={3000}
				onClose={handleResetConfirmClose}
				message="Password Reset confirmed, check you email Inbox."
			/>
		</Fragment>
	);
};

export default Login;
