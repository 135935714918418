import { Fragment, useState, ElementType } from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	icon: {
		fontSize: '3.75rem',
		cursor: 'pointer',
		[theme.breakpoints.up('lg')]: {
			fontSize: '4.25rem'
		}
	},

	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}));

interface IIconProps {
	hoverIcon: ElementType<any>;
	defaultIcon: ElementType<any>;
	onClick?: () => void;
	viewbox?: string;
	className?: string;
}

const Icon: React.FC<IIconProps> = ({ hoverIcon, defaultIcon, onClick, viewbox, className }) => {
	// sets default SVGs hover state to false
	const [hover, setHover] = useState<boolean>(false);
	const classes = useStyles();

	function getHoverState() {
		// sets SVG hover state to the opposite of the current state
		setHover(!hover);
	}

	return (
		<Fragment>
			{hover ? (
				<SvgIcon
					component={hoverIcon}
					viewBox={viewbox ? viewbox : '0 0 256 256'}
					onMouseLeave={getHoverState}
					className={className ? className : classes.icon}
					onClick={onClick}
				/>
			) : (
				<SvgIcon
					component={defaultIcon}
					viewBox={viewbox ? viewbox : '0 0 256 256'}
					onMouseEnter={getHoverState}
					className={className ? className : classes.icon}
					onClick={onClick}
				/>
			)}
		</Fragment>
	);
};

export default Icon;
