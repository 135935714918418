import React, { Fragment, useState } from 'react';

import { useDataState } from '../../contexts/DataProvider';
import { useGlobalState } from '../../contexts/GlobalStateProvider';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import Form from '../../components/Form';
import Field from '../../components/Field';
import { companyFields } from '../../utils/inputFields';

import CompanyHeader from '../../components/ManageCompany/CompanyHeader';
import CompanyContacts from '../../components/ManageCompany/CompanyContacts';
import Details from '../../components/Details';

import { ICompany } from '../../interfaces/company';
import { ICompanyContact } from '../../interfaces/companyContact';

import axios from 'axios';

interface IManageCompanyProps {
	onGetCompanies: (includeArchive: boolean) => void;
}

const ManageCompany: React.FC<IManageCompanyProps> = ({ onGetCompanies }) => {
	const { data, setData } = useDataState();

	const { setAcknowledgement, closeAcknowledgement } = useGlobalState();

	const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
	const [companyInputValue, setCompanyInputValue] = useState<string>('');
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [showCompanyProfile, setShowCompanyProfile] = useState<boolean>(false);
	const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
	const [snackbarSeverity, setSnackBackSeverity] = useState<Color | undefined>(undefined);
	const [snackbarMessage, setSnackbarMessage] = useState<string>('');

	const [isLoading, setIsLoading] = useState<boolean>(false);

	/**
	 * Show the company details for adding and editing
	 * @param {boolean} editMode - If the user is in edit mode or not
	 */
	const handleShowCompanyDetails = (editMode: boolean): void => {
		setIsEdit(editMode);

		// Set the field values if in edit mode
		companyFields.name.value = editMode ? selectedCompany?.name : '';
		companyFields.industry.value = editMode ? selectedCompany?.industry : '';
		companyFields.description.value = editMode ? selectedCompany?.description : '';

		setShowCompanyProfile(true);
	};

	/**
	 * Clears the selected company and input for auto complete
	 */
	const clearCompanyInput = (): void => {
		setSelectedCompany(null);
		setCompanyInputValue('');
	};

	/**
	 * When the user selects a company from the auto complete
	 * @param {ICompany | null} newSelectedCompany - The new company selected or nothing if deselected/clear
	 */
	const handleChangeCompany = (newSelectedCompany: ICompany | null): void => {
		setSelectedCompany(newSelectedCompany);
		setShowCompanyProfile(false);
		if (newSelectedCompany?.id) {
			getCompanyContacts(newSelectedCompany.id);
		}
	};

	/**
	 * Makes request to add or modify company to database (POST/PUT)
	 * @param {any} companyData - All the staff info needed to add or edit
	 */
	const handleAddCompany = (companyData: any): void => {
		setIsLoading(true);
		if (isEdit) {
			if (selectedCompany !== null) {
				const editedCompany: ICompany = {
					name: companyData.name,
					industry: companyData.industry,
					description: companyData.description,
					isArchived: selectedCompany.isArchived
				};

				axios
					.put(`/api/company/${selectedCompany.id}`, JSON.stringify(editedCompany), {
						headers: { 'Content-Type': 'application/json' }
					})
					.then(response => {
						const newCompanyList = [...data.companies!];
						const updatedCompany: ICompany = response.data;
						const oldCompanyIndex = data.companies!.findIndex(
							company => company.id === selectedCompany.id
						);
						newCompanyList[oldCompanyIndex] = updatedCompany;
						setData(prevData => ({ ...prevData, companies: newCompanyList }));
						setSelectedCompany(updatedCompany);
						setShowCompanyProfile(false);
						setIsLoading(false);
						clearCompanyInput();
						handleRequestComplete('success', 'Company has been edited.');
					})
					.catch(err => {
						const code = err.response.status;
						setIsLoading(false);
						if (code === 409) {
							handleRequestComplete(
								'error',
								'Can not update company name, a company with this name already exists.'
							);
						} else {
							handleRequestComplete(
								'error',
								'Failed to update company, please try again.'
							);
						}
					});
			}
		} else {
			const newCompany: ICompany = {
				name: companyData.name,
				industry: companyData.industry,
				description: companyData.description
			};

			axios
				.post('/api/company', JSON.stringify(newCompany), {
					headers: { 'Content-Type': 'application/json' }
				})
				.then(response => {
					const newCompany: ICompany = response.data;
					const newCompanyList = [...data.companies!, newCompany];
					setData(prevData => ({ ...prevData, companies: newCompanyList }));
					setShowCompanyProfile(false);
					setIsLoading(false);
					handleRequestComplete('success', 'Company has been added.');
				})
				.catch(err => {
					const code = err.response.status;
					setIsLoading(false);
					if (code === 409) {
						handleRequestComplete('error', 'Company with this name already exists.');
					} else {
						handleRequestComplete('error', 'Failed to add company, please try again.');
					}
				});
		}
	};

	/**
	 * Makes request to get all company contacts from a particular company (GET)
	 */
	const getCompanyContacts = (companyId: number): void => {
		axios
			.get(`/api/company/${companyId}/contacts/`)
			.then(response => {
				const contacts: ICompanyContact[] = [];
				response.data.forEach((contact: ICompanyContact) => {
					contacts.push(contact);
				});
				setData(prevData => ({ ...prevData, contacts: contacts }));
			})
			.catch(err => {
				console.log(err);
				setAcknowledgement(
					'Error',
					'We were unable to retreive contacts for this company, please try again or contact support.',
					'Ok',
					() => {
						closeAcknowledgement();
					}
				);
			});
	};

	/**
	 * Handles when a request is completed and show snackbar
	 * @param {'success' | 'error'} severity - If the request was an success or error, change the type of snackbar
	 * @param {string} message - The message to send to snackbar
	 */
	const handleRequestComplete = (severity: 'success' | 'error', message: string): void => {
		setSnackBackSeverity(severity);
		setSnackbarMessage(message);
		setShowSnackbar(true);
	};

	return (
		<Fragment>
			<CompanyHeader
				selectedCompany={selectedCompany}
				companyInputValue={companyInputValue}
				onInputChange={newInput => setCompanyInputValue(newInput)}
				onCompanyChange={handleChangeCompany}
				onShowCompanyDetails={editMode => handleShowCompanyDetails(editMode)}
				onGetCompanies={onGetCompanies}
				onClearCompanyInput={clearCompanyInput}
				onRequestComplete={handleRequestComplete}
			/>
			<Grid container justify="center" spacing={5} style={{ marginTop: '25px' }}>
				<Grid item xs={6}>
					<Grid container direction="column" alignItems="center" spacing={5}>
						{showCompanyProfile && (
							<Fragment>
								{!isLoading ? (
									<Details
										title="Company Profile"
										size={12}
										onClose={() => setShowCompanyProfile(false)}
									>
										<Form
											onSubmit={handleAddCompany}
											fields={companyFields}
											render={() => (
												<Fragment>
													<Grid container justify="space-between">
														<Grid item xs={5}>
															<Field
																{...companyFields.name}
																value={
																	isEdit
																		? selectedCompany?.name
																		: ''
																}
															/>
														</Grid>
														<Grid item xs={5}>
															<Field
																{...companyFields.industry}
																value={
																	isEdit
																		? selectedCompany?.industry
																		: ''
																}
															/>
														</Grid>
													</Grid>
													<Grid container justify="space-between">
														<Grid item xs={12}>
															<Field
																{...companyFields.description}
																value={
																	isEdit
																		? selectedCompany?.description
																		: ''
																}
															/>
														</Grid>
													</Grid>
													<Grid container justify="flex-end">
														<Grid item>
															<Button
																type="submit"
																variant="contained"
																disableTouchRipple
															>
																{isEdit
																	? 'Edit Company'
																	: 'Add Company'}
															</Button>
														</Grid>
													</Grid>
												</Fragment>
											)}
										/>
									</Details>
								) : (
									<Grid item>
										<CircularProgress size={100} color="primary" />
									</Grid>
								)}
							</Fragment>
						)}
						{selectedCompany !== null &&
							selectedCompany.isArchived === false &&
							!isLoading && (
								<CompanyContacts
									selectedCompany={selectedCompany}
									onRequestComplete={handleRequestComplete}
								/>
							)}
					</Grid>
				</Grid>
			</Grid>
			<Snackbar
				open={showSnackbar}
				autoHideDuration={3000}
				onClose={() => setShowSnackbar(false)}
			>
				<MuiAlert severity={snackbarSeverity}>{snackbarMessage}</MuiAlert>
			</Snackbar>
		</Fragment>
	);
};

export default ManageCompany;
