import React, { useRef, SyntheticEvent } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import { IDocument } from '../../interfaces/document';

import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import { Container, Typography } from '@material-ui/core';

interface IDocumentProps {
	desktopWidth: number;

	desktopHeight: number;

	document: IDocument;

	onRemoveDocument: (id: string) => void;

	onUpdateDocument: (document: IDocument) => void;

	onDocumentSelected: (document: IDocument) => void;
}

const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(2),
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		zIndex: 1000
	},
	documentWrapper: {
		position: 'absolute',
		width: 'auto',
		objectFit: 'contain'
	},
	document: {
		backgroundColor: '#ccc',
		border: '1px solid black',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'auto !important'
	},
	video: {
		backgroundColor: '#ccc',
		border: '1px solid black',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: theme.spacing(6, 0),
		width: '100%',
		height: 'auto !important'
	},
	documentTitle: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		marginBottom: theme.spacing(1)
	},
	documentContent: {
		padding: theme.spacing(2)
	},

	closeButton: {
		position: 'absolute',
		right: 0,
		zIndex: 3,
		marginRight: theme.spacing(2)
	},
	imageContent: {
		cursor: 'auto'
	},
	gridCenter: {
		display: 'flex',
		justifyContent: 'center'
	},
	draggableDocument: {
		objectFit: 'contain',
		height: 'auto',
		width: 'auto'
	},
	'@global': {
		'.dragHandle': {
			'&:hover': {
				cursor: 'grab'
			},
			'&:active': {
				cursor: 'grabbing'
			}
		},
		video: {
			maxHeight: 650,
			[theme.breakpoints.up('lg')]: {
				maxHeight: 900
			}
		}
	}
}));

const Document: React.FC<IDocumentProps> = ({
	desktopWidth,
	desktopHeight,
	document,
	onUpdateDocument,
	onRemoveDocument,
	onDocumentSelected
}) => {
	const classes = useStyles();

	const playerRef = useRef<any>();

	// Move and resize handling

	const handleMove = (e: DraggableEvent, data: DraggableData) => {
		document.posX = data.x;
		document.posY = data.y;

		onUpdateDocument(document);
	};

	const handleResize = (e: SyntheticEvent, data: ResizeCallbackData) => {
		document.height = data.size.height;
		document.width = data.size.width;

		onUpdateDocument(document);
	};

	const assetTitle =
		document.type === 'video'
			? document.name.replace('.mp4', '')
			: document.name.replace('.jpg', '');

	return (
		<Container className={classes.root}>
			<div
				style={{
					height: desktopHeight,
					width: desktopWidth,
					position: 'absolute',
					top: '16vh',
					left: '2vw'
				}}
			>
				<Draggable
					defaultPosition={{ x: document.posX, y: document.posY }}
					defaultClassName={classes.draggableDocument}
					handle=".dragHandle"
					bounds="parent"
					{...handleMove}
				>
					<div className={classes.documentWrapper}>
						<ResizableBox
							className={classes.document}
							height={document.height}
							width={document.width + 100}
							resizeHandles={['se']}
							handleSize={[8, 8]}
							minConstraints={[500, 300]}
							maxConstraints={
								document.type === 'video' ? [1100, 800] : [550, desktopHeight]
							}
							onResizeStop={handleResize}
							draggableOpts={{ grid: [10, 10] }}
							lockAspectRatio
						>
							<Container className={`${classes.documentContent} dragHandle`}>
								<Grid container className={classes.gridCenter}>
									<Grid item xs={12} className={classes.documentTitle}>
										<Typography variant="subtitle1" color="textPrimary">
											{assetTitle}
										</Typography>

										<IconButton
											className={classes.closeButton}
											size="small"
											color="primary"
											onClick={() => onRemoveDocument(document.name)}
										>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									</Grid>

									<Grid
										item
										xs={12}
										onMouseDown={() => onDocumentSelected(document)}
									>
										<div>
											{document.type === 'image' && (
												<img
													className={classes.imageContent}
													src={document.url}
													alt={document.name}
													style={{
														maxHeight: desktopHeight - 100,
														width: '100%',
														height: 'auto'
													}}
												/>
											)}
											{document.type === 'video' && (
												<ReactPlayer
													playing
													controls
													config={{
														file: {
															attributes: {
																disablePictureInPicture: true,
																controlsList: 'nodownload'
															}
														}
													}}
													ref={playerRef}
													url={document.url}
													height="auto !important"
													width="100% !important"
												/>
											)}
											{document.type === 'notSupported' && (
												<p>This Asset's format is not supported</p>
											)}
										</div>
									</Grid>
								</Grid>
							</Container>
						</ResizableBox>
					</div>
				</Draggable>
			</div>
		</Container>
	);
};

export default Document;
