import React, { Fragment, useState } from 'react';
import { useGlobalState } from '../contexts/GlobalStateProvider';
import { useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';
import { useSocketManager } from '../contexts/SocketManager';

const StaffNavigation: React.FC = () => {
	const history = useHistory();
	const { state } = useGlobalState();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { setFacilitatorData } = useSocketManager();

	/**
	 * Opens the navigation menu
	 * @param {React.MouseEvent<HTMLButtonElement>} event - The button element
	 */
	const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	/**
	 * Navigates to the dashboard page
	 * @param tab - which tab to open on the dashboard (-1 will trigger logout)
	 */
	const handleNavigateToDashboard = (tab: number) => {
		history.push({
			pathname: '/dashboard',
			state: { page: tab }
		});
		if (tab === -1) {
			// set Facilitator Data to empty obj on sign out
			// to disconnect from previous event a facilitator might've joined.
			setFacilitatorData(() => ({}));
		}
	};

	/**
	 * Closes the Navigation menu
	 */
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return (
		<Fragment>
			<Tooltip title="Open Staff Navigation">
				<span>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="menu"
						onClick={handleOpenMenu}
					>
						<MenuIcon />
					</IconButton>
				</span>
			</Tooltip>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleCloseMenu}
			>
				{state.user?.role === 'admin' && (
					<span>
						<MenuItem onClick={() => handleNavigateToDashboard(0)}>
							Manage Companies
						</MenuItem>
						<MenuItem onClick={() => handleNavigateToDashboard(1)}>
							Manage Event
						</MenuItem>
						<MenuItem onClick={() => handleNavigateToDashboard(2)}>
							Manage Staff
						</MenuItem>
					</span>
				)}

				<MenuItem onClick={() => handleNavigateToDashboard(3)}>Event Navigation</MenuItem>
				<MenuItem onClick={() => handleNavigateToDashboard(-1)}>Logout</MenuItem>
			</Menu>
		</Fragment>
	);
};

export default StaffNavigation;
