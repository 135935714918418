import React, { SetStateAction, useState, Fragment } from 'react';
import axios from 'axios';

import { ICompany } from '../interfaces/company';
import { IEvent } from '../interfaces/event';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import ErrorDialog from './ErrorDialog';

const useStyles = makeStyles(theme => ({
	downloadLink: {
		textDecoration: 'none'
	},
	grid: {
		display: 'flex',
		flexDirection: 'column',
		margin: '1rem 0'
	}
}));

interface IUploadCSV {
	/** The current selected company */
	selectedCompany: ICompany | null;

	/** The Current Selected Event */
	selectedEvent: IEvent | null;

	/** When a request has completed */
	onRequestComplete: (severity: 'success' | 'error', message: string) => void;

	/** actions to perform when file has successfully been submitted **/
	toggleLoading: React.Dispatch<SetStateAction<boolean>>;

	toggleDetails: React.Dispatch<SetStateAction<boolean>>;

	/** updates participant table once CSV is successfully imported **/
	onUpdateEvent: (newSelectedEvent: IEvent | null) => void;
}

interface ICsvError {
	email?: string;
	message: string;
}

const UploadCSV: React.FC<IUploadCSV> = ({
	selectedCompany,
	selectedEvent,
	onRequestComplete,
	toggleLoading,
	toggleDetails,
	onUpdateEvent
}) => {
	const [file, setFile] = useState<File | null>(null);
	const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
	const [errors, setErrors] = useState<ICsvError[] | []>([]);

	const classes = useStyles();

	const handleCsvFile = (e: any) => {
		setFile(e.target.files && e.target.files[0]);
	};

	/**
	 * handles CSV import
	 * **/
	const handleCsvImport = (): void => {
		let csvData = new FormData();
		csvData.append('file', file as File);

		axios
			.post(
				`/api/company/${selectedCompany?.id}/events/${selectedEvent?.id}/import_csv`,
				csvData,
				{ headers: { 'Content-Type': 'application/json' } }
			)
			.then(res => {
				if (res.status === 200) {
					toggleLoading(false);
					toggleDetails(false);
					onRequestComplete(
						'success',
						'Entries have been successfully added and/or updated!'
					);
					onUpdateEvent(selectedEvent);
				}
			})
			.catch(err => {
				const code = err.response.status;
				if (code === 409) {
					setShowErrorDialog(true);
					setErrors(err.response.data);
				} else if (code === 404) {
					// use snackbar to handle this error
					onRequestComplete(
						'error',
						'This event does not exist in the company. Please create the event and try again.'
					);
				} else if (code === 400) {
					setErrors([
						{
							message: `You've entered a ${file!.type} file. Only CSVs are accepted.`
						}
					]);
					setShowErrorDialog(true);
				}
			});
	};

	const handleCloseErrorDialog = (): void => {
		setShowErrorDialog(false);
		setFile(null);
		setErrors([]);
	};

	const ErrorHandling = () => {
		// if a CSV file is uploaded, handle the CSV errors
		if (file && file.type === 'text/csv') {
			// determine if there are more than 10 errrors. Only show 10/n, but let user know how many there are.

			const errorLength = errors && errors.length;
			const cappedErrors = errors && errors.length <= 10 ? errors : errors?.slice(0, 10);
			const cappedErrorsMessage =
				errors!.length > 10 &&
				`Showing the first 10 of ${errorLength} errors in your file...`;
			return (
				<ErrorDialog
					visibility={showErrorDialog}
					title="There Were Errors With Your CSV File..."
					content={
						<Grid container style={{ flexDirection: 'column' }}>
							{cappedErrorsMessage && (
								<Grid
									item
									xs={12}
									style={{ marginBottom: '1rem', fontWeight: 500 }}
								>
									{cappedErrorsMessage}
								</Grid>
							)}
							<Grid item xs={12} style={{ marginBottom: '1rem' }}>
								At rows with email:
							</Grid>
							{cappedErrors &&
								cappedErrors.map((csvError: ICsvError, i: number) => (
									<Grid item xs={12} key={i}>
										<Typography>
											<b>{csvError.email}:</b> {csvError.message}.
										</Typography>
									</Grid>
								))}

							<Grid item xs={12} style={{ marginTop: '2rem' }}>
								Please fix these errors and try again!
							</Grid>
						</Grid>
					}
					onClose={() => handleCloseErrorDialog()}
				/>
			);
		}
		// or we'll just return errors re: incorrect file type.
		return (
			<ErrorDialog
				visibility={showErrorDialog && file!.type !== 'text/csv'}
				title="You Must Upload a CSV File..."
				content={
					<Grid container style={{ flexDirection: 'column' }}>
						{errors.map((fileError: { message: string }) => fileError.message)}
					</Grid>
				}
				onClose={() => setShowErrorDialog(false)}
			/>
		);
	};

	return (
		<Fragment>
			<Grid container alignItems="center" className={classes.grid}>
				<Grid item>
					<Typography variant="subtitle1">
						Please download and edit the template below. Headers <b>must</b> be as
						follows: <b>email, first name, last name, position</b> and <b>room</b>.
					</Typography>
					<Typography
						component="a"
						color="primary"
						href="/templates/sttCsvTemplate.csv"
						download
						className={classes.downloadLink}
					>
						Download CSV Template
					</Typography>
				</Grid>
				<Grid
					container
					justify="center"
					alignItems="center"
					style={{ flexDirection: 'column', margin: '2rem 0' }}
				>
					<Grid item>
						<Button variant="text" color="primary" component="label">
							<CloudUpload style={{ margin: '0 .5rem' }} />
							Select File
							<input
								multiple
								type="file"
								style={{ display: 'none' }}
								// only allows users to upload csv files
								accept=".csv"
								onChange={e => handleCsvFile(e)}
							/>
						</Button>
					</Grid>
					<Grid item>
						<Typography variant="caption">{file && file.name}</Typography>
					</Grid>
				</Grid>

				<Grid item>
					<Button onClick={handleCsvImport} variant="contained">
						Submit
					</Button>
				</Grid>
			</Grid>
			<ErrorHandling />
		</Fragment>
	);
};

export default UploadCSV;
