import { Cabin } from '../assets/desktop';

interface ICabinImageProps {
	className?: string;
}
const CabinImage: React.FC<ICabinImageProps> = ({ className }) => (
	<picture style={{ maxHeight: '100vh', zIndex: -1 }}>
		<img src={`${Cabin}`} alt="Titanic Cabin" className={className} />
	</picture>
);

export default CabinImage;
