import React, { Fragment } from 'react';

import { useSocketManager } from '../../../contexts/SocketManager';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import * as MessageType from '../../../interfaces/message';

import VideocamIcon from '@material-ui/icons/Videocam';
import CallEndIcon from '@material-ui/icons/CallEnd';
import MicIcon from '@material-ui/icons/Mic';
import PanToolIcon from '@material-ui/icons/PanTool';
import CancelIcon from '@material-ui/icons/Cancel';

import { CallStatus } from '../../../types/types';

import { makeStyles } from '@material-ui/core/styles';

interface IFacilitatorRoomManagerHeaderProps {
	roomName: string;
	space: string;
	needAssistance: boolean;
	isCallLoading: boolean;
	callStatus: CallStatus;
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	headerBar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		flexGrow: 1,
	},
	alert: {
		paddingRight: '10px',
	},
}));

const FacilitatorRoomManagerHeader: React.FC<IFacilitatorRoomManagerHeaderProps> = ({
	roomName,
	space,
	needAssistance,
	isCallLoading,
	callStatus,
}) => {
	const classes = useStyles();

	const { callRequest, switchCall, leaveCall, leaveRoom, socket } = useSocketManager();

	const handleCallRequest = (isBroadcasting: boolean) => {
		callRequest(isBroadcasting, space);
	};

	const handleSwitchRequest = (status: CallStatus) => {
		switchCall(space, status);
	};

	const handleLeaveCall = () => {
		leaveCall(space);
	};

	/**
	 * Remove assistance flag (for staff memebrs)
	 */
		 const removeAssistanceHandler = (): void => {
			if (socket) {
				const message: MessageType.ISetAssistanceFlagMessage = {
					type: 'set-assistance-flag',
					space: space,
					data: {
						needsFacilitatorAssistance: false
					}
				};
				socket.send(JSON.stringify(message));
			}
		};

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Toolbar>
					<div className={classes.headerBar}>
						{needAssistance && (
							<Tooltip title="Room Needs Assistance - Click to remove">
								<IconButton onClick={removeAssistanceHandler}>
									<PanToolIcon className={classes.alert} color="secondary" />
								</IconButton>	
							</Tooltip>
						)}
						<Typography variant="body1" className={classes.title}>
							{roomName}
						</Typography>
					</div>
					{!isCallLoading ? (
						<Fragment>
							{callStatus === 'None' ? (
								<Fragment>
									<Tooltip title="Broadcast Video">
										<IconButton
											edge="end"
											color="inherit"
											onClick={() => handleCallRequest(true)}
										>
											<VideocamIcon />
										</IconButton>
									</Tooltip>
									<Tooltip title="Listen To Room">
										<IconButton
											edge="end"
											color="inherit"
											onClick={() => handleCallRequest(false)}
										>
											<MicIcon />
										</IconButton>
									</Tooltip>
								</Fragment>
							) : (
								<Fragment>
									{callStatus}
									{callStatus === 'Listening' ? (
										<Tooltip title="Switch To Broadcast">
											<IconButton
												edge="end"
												color="inherit"
												onClick={() => handleSwitchRequest('Broadcasting')}
											>
												<VideocamIcon />
											</IconButton>
										</Tooltip>
									) : (
										<Tooltip title="Switch To Listening">
											<IconButton
												edge="end"
												color="inherit"
												onClick={() => handleSwitchRequest('Listening')}
											>
												<MicIcon />
											</IconButton>
										</Tooltip>
									)}
									<Tooltip title="Leave Call">
										<IconButton
											edge="end"
											color="inherit"
											onClick={handleLeaveCall}
										>
											<CallEndIcon />
										</IconButton>
									</Tooltip>
								</Fragment>
							)}
							{callStatus === 'None' && (
								<Tooltip title="Leave Room">
									<IconButton
										edge="end"
										color="inherit"
										onClick={() => leaveRoom(roomName)}
									>
										<CancelIcon />
									</IconButton>
								</Tooltip>
							)}
						</Fragment>
					) : (
						<CircularProgress color="secondary" />
					)}
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default FacilitatorRoomManagerHeader;
