import { Button, Dialog, DialogContent, Grid, List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Fragment, useState } from 'react';
import { buttonTray, sidePanel } from '../../assets/instructions';

interface IInstructions {
	onAcknowledge: any;
	onShowInstructions: any;
}

const useStyles = makeStyles(theme => ({
	container: {
		margin: theme.spacing(2, 0),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start'
	},
	blurb: {
		paddingTop: theme.spacing(2)
	},
	line: {
		paddingTop: theme.spacing(1)
	},
	button: {
		display: 'flex'
	},
	image: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%'
	},
	'@global': {
		'.MuiDialog-paperWidthSm': {
			width: 700
		}
	}
}));

const Instructions: React.FC<IInstructions> = ({ onAcknowledge, onShowInstructions }) => {
	const [stepOne, setStepOne] = useState<boolean>(true);
	const [stepTwo, setStepTwo] = useState<boolean>(false);
	const [stepThree, setStepThree] = useState<boolean>(false);

	const classes = useStyles();

	const handlePrevStep = () => {
		if (stepTwo) {
			setStepTwo(false);
			setStepOne(true);
		}
		if (stepThree) {
			setStepThree(false);
			setStepTwo(true);
		}
	};

	const handleNextStep = () => {
		if (stepOne) {
			setStepOne(false);
			setStepTwo(true);
		}
		if (stepTwo) {
			setStepTwo(false);
			setStepThree(true);
		}
	};

	const ButtonNavigation = () => (
		<Grid container justify="space-between">
			{!stepOne && (
				<Grid item xs={6} justify="flex-start" className={classes.button}>
					<Button color="primary" onClick={() => handlePrevStep()}>
						Previous
					</Button>
				</Grid>
			)}
			{!stepThree && (
				<Grid item xs={stepOne ? 12 : 6} justify="flex-end" className={classes.button}>
					<Button color="primary" onClick={() => handleNextStep()}>
						Next
					</Button>
				</Grid>
			)}
			{stepThree && (
				<Grid item xs={6} justify="flex-end" className={classes.button}>
					<Button color="primary" onClick={() => onAcknowledge()}>
						Finish Tour
					</Button>
				</Grid>
			)}
		</Grid>
	);

	const StepOne = () => (
		<Grid>
			<Typography variant="h6">Welcome to Save the Titanic!</Typography>
			<Typography variant="subtitle1" className={classes.line}>
				This is the Main Room, where staff will provide instructions before participants are
				broken out into the Experience Rooms!
			</Typography>
			<Typography variant="subtitle1" className={classes.line}>
				You'll notice your microphone and camera are off.
			</Typography>
			<Typography className={classes.line}>
				You will be able to control those soon, in the Experience Rooms.
			</Typography>
			<ButtonNavigation />
		</Grid>
	);

	const StepTwo = () => (
		<Grid container style={{ flexDirection: 'column' }}>
			<Typography variant="h6">These are the Side Panel Tabs...</Typography>
			<Grid container className={classes.container}>
				<Grid item xs={12} className={classes.image}>
					<img src={sidePanel} alt="Explanation of side panel functionality" />
				</Grid>
				<Grid item xs={12} className={classes.blurb}>
					<Typography>Here, you can toggle between different tabs:</Typography>
					<List style={{ paddingLeft: '1rem' }}>
						<ListItem disableGutters>
							- Chat, where you can interact with your team and the facilitators
							putting on the event!
						</ListItem>
						<ListItem disableGutters>
							- Active Users, where you can view a list of current users in the room!
						</ListItem>
						<ListItem disableGutters>
							- Announcements - where facilitators will post important updates
							throughout the event.
						</ListItem>
					</List>

					<Typography className={classes.blurb}>
						* Experience Rooms have an additional tab to view assets important to saving
						the Titanic!
					</Typography>
				</Grid>
			</Grid>
			<ButtonNavigation />
		</Grid>
	);

	const StepThree = () => (
		<Grid container>
			<Typography variant="h6">This is the Button Dock...</Typography>
			<Grid container alignItems="center" justify="center" className={classes.container}>
				<Grid item className={classes.image}>
					<img src={buttonTray} alt="Explanation of button dock" />
				</Grid>
				<Grid item>
					<Typography className={classes.blurb}>
						Here, you'll have the ability to:
						<List style={{ paddingLeft: '1rem' }}>
							<ListItem disableGutters>- Toggle Microphone - mute/unmute</ListItem>
							<ListItem disableGutters>- Toggle Camera - visbile/hidden</ListItem>
							<ListItem disableGutters>
								- Raise Hand - ask a staff member for assistance!
							</ListItem>
							<ListItem disableGutters>
								- Change Settings - for audio/video devices.
							</ListItem>
						</List>
					</Typography>
				</Grid>
			</Grid>
			<ButtonNavigation />
		</Grid>
	);

	return (
		<Dialog open={onShowInstructions}>
			<DialogContent>
				<Fragment>
					{stepOne && <StepOne />}
					{stepTwo && <StepTwo />}
					{stepThree && <StepThree />}
				</Fragment>
			</DialogContent>
		</Dialog>
	);
};

export default Instructions;
