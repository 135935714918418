import { IAsset } from '../../interfaces/asset';
import { IDocument } from '../../interfaces/document';
import * as MessageType from '../../interfaces/message';
import { matchImage, matchVideo } from '../fileMatch';

/**
 * Updates if the room's assistance state (whether participants need assistance or not)
 * @param {MessageType.INeedAssistanceFlagMessage} socketMessage - all the information of assistance message
 * @returns {boolean} - the new flag state
 */
export const handleUpdateRoomAssitance = (
	socketMessage: MessageType.INeedAssistanceFlagMessage
): boolean => {
	const needAssistance = socketMessage.data.needsFacilitatorAssistance ? true : false;
	return needAssistance;
};

/**
 * Updates the lock state on an asset
 * @param {IAsset[]} space - the assets in this room
 * @param {string} key - the key of the asset to change
 * @param {boolean} newLockState - the lock state of the asset
 * @returns {IAsset[]} - the new state of asset list
 */
export const handleChangeLockState = (
	assets: IAsset[],
	key: string,
	newLockState: boolean
): IAsset[] => {
	const targetAsset = assets.find((asset) => asset.key === key);

	if (targetAsset) {
		targetAsset.locked = newLockState;
	}

	return assets;
};

/**
 * When the document has been requested to be added to the desktop
 * @param {IDocument[]} documents - The current documents on the desktop
 * @param {IAsset[]} assets - the asset list of this room
 * @param {MessageType.IDocumentReceivedMessage} documentMessage - socket message containing info on asset
 * @returns {IDocument[]} - new updated documents on the desktop
 */
export const handleDocumentAdded = (
	documents: IDocument[],
	assets: IAsset[],
	documentMessage: MessageType.IDocumentReceivedMessage
): IDocument[] => {
	const assetData = documentMessage.data;
	const foundDocument = documents.find((doc) => doc.name === assetData.key);

	// check if document is already open, update if it is.
	if (foundDocument) {
		foundDocument.posX = assetData.x;
		foundDocument.posY = assetData.y;
		foundDocument.height = assetData.height;
		foundDocument.width = assetData.width;
	} else {
		const foundAsset = assets.find((asset) => asset.key === assetData.key);

		if (foundAsset) {
			const isImage = matchImage(foundAsset.url);
			const isVideo = matchVideo(foundAsset.url);

			const newDocument: IDocument = {
				name: assetData.key,
				url: foundAsset.url,
				type: isImage ? 'image' : isVideo ? 'video' : 'notSupported',
				posX: assetData.x,
				posY: assetData.y,
				width: assetData.width,
				height: assetData.height,
			};

			const newDocumentList = [...documents, newDocument];

			return newDocumentList;
		}
	}

	return documents;
};

/**
 * When the document has been requested to be removed from the desktop
 * @param {IDocument[]} documents - The current documents on the desktop
 * @param {MessageType.IDocumentClose} documentMessage - socket message containing info on asset
 * @returns {IDocument[]} - new updated documents on the desktop
 */
export const handleDocumentRemoved = (
	documents: IDocument[],
	documentMessage: MessageType.IDocumentClose
): IDocument[] => {
	const newDocumentlist = documents.filter((doc) => doc.name !== documentMessage.data.assetKey);
	return newDocumentlist;
};
