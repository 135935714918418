import React, { useState } from 'react';

import { IHeader } from '../utils/tableData';

import Grid, { GridSize } from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { IRoom } from '../interfaces/room';

interface IEventNavigatonTableProps {
	/* How wide in grid formation should the table be */
	size: GridSize;

	/* The required headers for this table */
	headers: IHeader[];

	/* The required data for this table */
	roomData: IRoom[];

	/* The selected row */
	selected?: any[];

	/* data to exclude from making into cells */
	excludedColumns?: string[];

	/* When the selected row changes*/
	onSelectedChange?: (newSelected: any[]) => void;

	/* When the user joins a specific room*/
	onJoinRoom: (roomName: string) => void;
}

/**
 * Comparing elements in an array from descending order
 * "-" infront of this function will make it ascending order
 * @param {any} a - left element to compare
 * @param {any} b - right element to compare
 * @param {string} orderBy - the field to compare these elements by
 * @returns {number} - The result of the compare
 */
const descendingComparator = (a: any, b: any, orderBy: string): number => {
	if (orderBy === 'staff') {
		if (b[orderBy][0] && a[orderBy][0]) {
			if (b[orderBy][0].lastName < a[orderBy][0].lastName) {
				return -1;
			}
			if (b[orderBy][0].lastName > a[orderBy][0].lastName) {
				return 1;
			}
		}
	
		return 0;
	} else {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}
};

/**
 * Finds out what order to do the sort (ascending or descending)
 * @param {SortDirection} order - either ascending or descending
 * @param {string} orderBy - the field to compare these elements by
 * @returns {(a:any, b:any) => number} - The appropriate comparing function (ascending or decenting)
 */
const getComparator = (
	order: 'asc' | 'desc' | undefined,
	orderBy: string
): ((a: any, b: any) => number) => {
	return order === 'desc'
		? (a: any, b: any) => descendingComparator(a, b, orderBy)
		: (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

/**
 * Get the comparing criteria for this sort
 * @param {any[]} array - an array of elements to sort
 * @param {any} comparator - the comparing criteria for this sort
 * @returns {any[]} - The sorted array
 */
const stableSort = (array: any[], comparator: any): any[] => {
	const stabilizedThis = array.map((el: any, index: any) => [el, index]);
	stabilizedThis.sort((a: any, b: any) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el: any) => el[0]);
};

// TO DO: Make this table more specific to event navigation (room data, handle facilitator id's, ect.)
// Need to wait till we understand what room information we can grab
const EventNavigationTable: React.FC<IEventNavigatonTableProps> = ({
	size,
	headers,
	roomData,
	selected,
	excludedColumns,
	onJoinRoom,
	onSelectedChange
}) => {
	const [orderBy, setOrderBy] = useState<string>(headers[0].id);
	const [order, setOrder] = useState<'asc' | 'desc' | undefined>('asc');
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);

	const isSelected = (id: any): boolean => {
		if (selected) {
			return selected!.indexOf(id) >= 0;
		} else {
			return false;
		}
	};

	/**
	 * When user clicks on a row
	 * @param {React.MouseEvent<HTMLTableRowElement, MouseEvent>} event - The Click event that is passed by default
	 * @param {any} id - The Id of the element in that row
	 */
	const handleRowClick = (event: any, id: any): void => {
		if (selected && onSelectedChange) {
			const selectedIdx = selected.indexOf(id);
			let newSelected = [...selected];

			if (selectedIdx === -1) {
				newSelected.push(id);
			} else {
				newSelected = selected.filter(selectId => selectId !== id);
			}

			onSelectedChange(newSelected);
		}
	};

	/**
	 * When user clicks the select all check box
	 * @param {React.MouseEvent<HTMLTableRowElement, MouseEvent>} event - The Click event that is passed by default
	 */
	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (onSelectedChange) {
			if (event.target.checked) {
				const newSelecteds = roomData.map(room => room.name);
				onSelectedChange(newSelecteds);
				return;
			}
			onSelectedChange([]);
		}
	};

	/**
	 * Changing the page of the table
	 * @param {React.MouseEvent<HTMLTableRowElement, MouseEvent>} event - The Click event that is passed by default
	 */
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
		newPage: number
	): void => {
		setPage(newPage);
	};

	/**
	 * Changing how many rows on one page
	 * @param {any} event - The change event information
	 */
	const handleChangeRowsPerPage = (event: any): void => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	/**
	 * Handles the adjusment of sorting a particular column
	 * @param {string} property - The property (key) that is being sorted
	 * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The default button event that is passed
	 */
	const handleRequestSort =
		(property: string) =>
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
			const isAsc = orderBy === property && order === 'asc';
			setOrder(isAsc ? 'desc' : 'asc');
			setOrderBy(property);
		};

	return (
		<Grid container justify="center">
			<Grid item xs={size}>
				<Paper>
					<TableContainer>
						<Table size="medium">
							<TableHead>
								<TableRow>
									{selected && (
										<TableCell padding="checkbox">
											<Checkbox
												indeterminate={
													selected.length > 0 &&
													selected.length < roomData.length
												}
												checked={
													roomData.length > 0 &&
													selected.length === roomData.length
												}
												onChange={handleSelectAllClick}
											/>
										</TableCell>
									)}

									{headers.map((headCell: IHeader) => (
										<TableCell
											key={headCell.id}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={handleRequestSort(headCell.id)}
											>
												<span style={{ fontWeight: 'bold' }}>
													{headCell.label}
												</span>
											</TableSortLabel>
										</TableCell>
									))}
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(roomData, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((room: IRoom, index: any) => {
										const isItemSelected = isSelected(room.name);
										return (
											<TableRow
												key={room.name}
												role="checkbox"
												selected={isItemSelected}
											>
												{selected && (
													<TableCell padding="checkbox">
														<Checkbox
															checked={isItemSelected}
															onClick={event =>
																handleRowClick(event, room.name)
															}
														/>
													</TableCell>
												)}

												<TableCell>{room.name}</TableCell>
												<TableCell>
													{room.staff && room.staff[0]
														? `${room.staff[0].firstName} ${room.staff[0].lastName}`
														: 'No Facilitator'}
												</TableCell>
												<TableCell align="center">
													{room.participants
														? room.participants.length
														: 0}
												</TableCell>
												<TableCell>
													<Button
														onClick={() => onJoinRoom(room.name)}
														variant="contained"
														disableTouchRipple
													>
														Go To Room
													</Button>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={roomData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default EventNavigationTable;
