import React, { useState } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';

import { IHeader } from '../utils/tableData';
import { useGlobalState } from '../contexts/GlobalStateProvider';

import Grid, { GridSize } from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import { useRef } from 'react';

interface ISelectSortTableProps {
	/* How wide in grid formation should the table be */
	size?: GridSize;

	/* The required headers for this table */
	headers: IHeader[];

	/* The required data for this table */
	data: any[];

	/* The selected row */
	selected: any;

	/* data to exclude from making into cells */
	excludedColumns?: string[];

	/* When the selected row changes*/
	onSelectedChange: (newSelected: any) => void;

	/* handle delete row */
	onDelete?: () => void;

	/* handle edit row*/
	onEdit?: (id: any) => void;

	/* handle lock row*/
	onLock?: () => void;

	/* can you lock staff in this table? */
	lockable?: boolean;
}

const useStyles = makeStyles(() => ({
	table: {
		minWidth: '70vw'
	}
}));
/**
 * Comparing elements in an array from descending order
 * "-" infront of this function will make it ascending order
 * @param {any} a - left element to compare
 * @param {any} b - right element to compare
 * @param {string} orderBy - the field to compare these elements by
 * @returns {number} - The result of the compare
 */
const descendingComparator = (a: any, b: any, orderBy: string): number => {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
};

/**
 * Finds out what order to do the sort (ascending or descending)
 * @param {SortDirection} order - either ascending or descending
 * @param {string} orderBy - the field to compare these elements by
 * @returns {(a:any, b:any) => number} - The appropriate comparing function (ascending or decenting)
 */
const getComparator = (
	order: 'asc' | 'desc' | undefined,
	orderBy: string
): ((a: any, b: any) => number) => {
	return order === 'desc'
		? (a: any, b: any) => descendingComparator(a, b, orderBy)
		: (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

/**
 * Get the comparing criteria for this sort
 * @param {any[]} array - an array of elements to sort
 * @param {any} comparator - the comparing criteria for this sort
 * @returns {any[]} - The sorted array
 */
const stableSort = (array: any[], comparator: any): any[] => {
	const stabilizedThis = array.map((el: any, index: any) => [el, index]);
	stabilizedThis.sort((a: any, b: any) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el: any) => el[0]);
};

const SelectSortTable: React.FC<ISelectSortTableProps> = ({
	size,
	headers,
	data,
	selected,
	excludedColumns,
	onSelectedChange,
	onDelete,
	onEdit,
	onLock,
	lockable = true
}) => {
	const { state } = useGlobalState();
	const [orderBy, setOrderBy] = useState<string>(headers[0].id);
	const [order, setOrder] = useState<'asc' | 'desc' | undefined>('asc');
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const disableRowToggleRef = useRef<HTMLDivElement | null>(null);

	const currentUser = state.user;
	const classes = useStyles();

	const isSelected = (id: any): boolean => selected === id;

	/**
	 * When user clicks on a row
	 * @param {React.MouseEvent<HTMLTableRowElement, MouseEvent>} event - The Click event that is passed by default
	 * @param {any} id - The Id of the element in that row
	 */
	const handleRowClick = (
		event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
		id: any
	): void => {
		if (!disableRowToggleRef) {
			if (id === selected) {
				onSelectedChange(undefined);
			}
		} else {
			onSelectedChange(id);
		}
	};

	/**
	 * Changing the page of the table
	 * @param {React.MouseEvent<HTMLTableRowElement, MouseEvent>} event - The Click event that is passed by default
	 */
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
		newPage: number
	): void => {
		setPage(newPage);
	};

	/**
	 * Changing how many rows on one page
	 * @param {any} event - The change event information
	 */
	const handleChangeRowsPerPage = (event: any): void => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	/**
	 * Check if the given property key is a excluded column (and "id") on this table
	 * @param {string} key - The property key to examin the excluded ones from
	 * @returns {boolean} - If this key is an excluded column or not
	 */
	const isExcludedColumn = (key: string): boolean => {
		if (key === 'id') {
			return true;
		}

		let result: boolean = false;

		if (excludedColumns) {
			excludedColumns.forEach(col => {
				if (key === col) {
					result = true;
				}
			});
		}
		return result;
	};

	/**
	 * Handles the adjusment of sorting a particular column
	 * @param {string} property - The property (key) that is being sorted
	 * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The default button event that is passed
	 */
	const handleRequestSort =
		(property: string) =>
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
			const isAsc = orderBy === property && order === 'asc';
			setOrder(isAsc ? 'desc' : 'asc');
			setOrderBy(property);
		};

	return (
		<Grid container justify="center">
			<Grid item xs={size}>
				<Paper>
					<TableContainer className={classes.table}>
						<Table size="medium">
							<TableHead>
								<TableRow>
									<TableCell padding="checkbox"></TableCell>
									{headers.map((headCell: IHeader) => (
										<TableCell
											key={headCell.id}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={handleRequestSort(headCell.id)}
											>
												<span style={{ fontWeight: 'bold' }}>
													{headCell.label}
												</span>
											</TableSortLabel>
										</TableCell>
									))}
									<TableCell />
									{lockable && <TableCell />}
									<TableCell />
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(data, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row: any, index: any) => {
										const isItemSelected = isSelected(row.id);
										return (
											<TableRow
												key={row.email}
												role="checkbox"
												selected={isItemSelected}
												onClick={event => handleRowClick(event, row.id)}
											>
												<TableCell padding="checkbox">
													<Checkbox checked={isItemSelected} />
												</TableCell>
												{Object.keys(row).map(
													(key: string, index: number) => {
														if (!isExcludedColumn(key)) {
															return (
																<TableCell key={index}>
																	{row[key] === true
																		? 'Yes'
																		: row[key] === false
																		? 'No'
																		: row[key]}
																</TableCell>
															);
														} else {
															return null;
														}
													}
												)}
												<TableCell ref={disableRowToggleRef}>
													<Tooltip title="Edit Account">
														<IconButton
															color="primary"
															onClick={() => onEdit!(row.id)}
															aria-label="Edit Account"
														>
															<EditIcon />
														</IconButton>
													</Tooltip>
												</TableCell>
												{lockable ? (
													<TableCell>
														{/* only display locking functionality if row is NOT current user */}
														{row.id !== currentUser?.id && (
															<Tooltip title="Lock Account">
																<IconButton
																	color="primary"
																	onClick={onLock}
																	aria-label="Lock Account"
																>
																	<LockIcon />
																</IconButton>
															</Tooltip>
														)}
													</TableCell>
												) : null}
												<TableCell ref={disableRowToggleRef}>
													{/* only display deleting functionality if row is NOT current user */}

													{row.id !== currentUser?.id && (
														<Tooltip title="Delete Account">
															<IconButton
																color="primary"
																onClick={onDelete}
																aria-label="Delete Account"
															>
																<DeleteIcon />
															</IconButton>
														</Tooltip>
													)}
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default SelectSortTable;
