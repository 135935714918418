import React, {
	useContext,
	createContext,
	useState,
	useEffect,
	useCallback,
	useRef,
	Dispatch,
	SetStateAction
} from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useGlobalState } from './GlobalStateProvider';
import DailyIframe, { DailyCall, DailyEvent } from '@daily-co/daily-js';

import axios from 'axios';

import { CallStatus, EventState, RoomType, SocketMessageType, SpaceRoomType } from '../types/types';

import * as MessageType from '../interfaces/message';
import {
	IFacilitatorData,
	IEventStructure,
	IExperienceRoomData
} from '../interfaces/roomSocketData';

import { IDocument } from '../interfaces/document';

import {
	handleJoinChatRoom,
	handleJoinGeneralRoom,
	handleJoinedExperienceRoom,
	handleUserJoinLobby,
	handleUserLeftLobby,
	handleUserRaiseHand,
	handleDocumentAdded,
	handleDocumentRemoved,
	handleChangeLockState,
	handleUpdateChat,
	handleUpdateChatMessage,
	handleUpdateRoomAssitance,
	handleUserVideoChatter
} from '../utils/socketHelpers';
import { IRoom } from '../interfaces/room';
import { Container, IconButton, Typography } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';

// create the context to be able to view and edit global state
const SocketContext = createContext({
	socket: null as WebSocket | null,
	callObject: null as DailyCall | null,
	rooms: {} as IEventStructure,
	eventRooms: {} as IEventStructure,
	supervisor: false,
	handRaised: false,
	supervisorRooms: { space: '', roomJoined: '', rooms: [] as IRoom[] },
	setRooms: {} as Dispatch<SetStateAction<IEventStructure>>,
	connect: (): void => {},
	documents: [] as IDocument[],
	setDocuments: {} as Dispatch<SetStateAction<IDocument[]>>,
	facilitatorJoinEvent: (companyName: string, eventName: string, eventId: number): void => {},
	facilitatorUpdateEvent: (updatedState: EventState): void => {},
	joinCallback: (): void => {},
	facilitatorJoinRooms: (data: IFacilitatorData): void => {},
	leaveRooms: (roomType: RoomType): void => {},
	leaveRoom: (roomName: string): void => {},
	facilitatorData: null as Partial<IFacilitatorData> | null,
	setFacilitatorData: {} as Dispatch<SetStateAction<Partial<IFacilitatorData> | null>>,
	callRequest: (isBroadcasting: boolean, space: string): void => {},
	switchCall: (space: string, callStatus: CallStatus): void => {},
	leaveCall: (space: string): void => {},
	getExperienceRoom: (): IExperienceRoomData | null => {
		return null;
	}
});

const SocketManager = ({
	children,
	value: {
		socket = null as WebSocket | null,
		callObject = null as DailyCall | null,
		rooms = {} as IEventStructure
	}
}: {
	children: React.ReactNode;
	value: { socket: WebSocket | null; callObject: DailyCall | null; rooms: IEventStructure };
}) => {
	const { state, setAcknowledgement, closeAcknowledgement } = useGlobalState();
	const history = useHistory();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [facilitatorData, setFacilitatorData] = useState<Partial<IFacilitatorData> | null>(null);
	const [currentCallSpace, setCurrentCallSpace] = useState<string>('');

	const [roomInfo, setRoomInfo] = useState<IEventStructure>(rooms);

	const [socketObject, setSocketObject] = useState(socket);
	const [dailyCallObject, setDailyCallObject] = useState<DailyCall | null>(callObject);

	const [isSupervisor, setIsSupervisor] = useState<boolean>(false);
	const [supervisorRoomInfo, setSupervisorRoomInfo] = useState<{
		space: string;
		roomJoined: string;
		rooms: IRoom[];
	}>({ space: '', roomJoined: '', rooms: [] });

	const [participantHandRaised, setParticpantHandRaised] = useState<boolean>(false);

	const [documents, setDocuments] = useState<IDocument[]>([]);

	const timer = useRef<any>(null);

	/**
	 * Check if you are still authenticated on the server side.
	 */
	const getAuthentication = async () => {
		const result = await axios.get('/api/me');
		return result.status !== 401;
	};

	/**
	 * Handles connecting to the websocket on the server side
	 * @param callback - and optional callback function that returns the created websocket
	 */
	const handleConnectSocket = useCallback((): void => {
		if (!socketObject) {
			const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
			let { host } = window.location;
			const ws = new WebSocket(`${protocolPrefix}//${host}/rt`);

			ws.onopen = () => {
				setSocketObject(ws);

				if (state.user?.role !== 'participant') {
					const storedInfo = sessionStorage.getItem('facilitatorInfo');
					if (storedInfo) {
						const facilitatorInfo = JSON.parse(storedInfo);
						if (facilitatorInfo !== null) {
							setFacilitatorData(facilitatorInfo);
							ws.send(
								JSON.stringify({
									type: 'staff-join-event',
									space: 'staff_room',
									data: {
										eventId: facilitatorInfo.eventId
									}
								})
							);
						}
					}
				}
			};

			ws.onclose = () => {
				if (timer.current) {
					clearTimeout(timer.current);
				}

				if (dailyCallObject) {
					dailyCallObject.destroy();
				}

				setAcknowledgement(
					'Connection Closed',
					'Your connection has closed because of an unexpected error or you are connected somewhere else. Either reload the page, or close this tab.',
					'Reload Page',
					() => {
						window.location.reload();
					}
				);
			};
		}
	}, [socketObject, dailyCallObject, state.user, setAcknowledgement]);

	/**
	 * Shows snackbar message to user if they are currently connected to one room
	 * @param {string} message - the message being sent through snackbar
	 */
	const handleSnackbarMessage = useCallback(
		(message: string): void => {
			if (roomInfo && Object.keys(roomInfo).length === 1) {
				enqueueSnackbar(message);
			}
		},
		[roomInfo, enqueueSnackbar]
	);

	/**
	 * Retreives the single room in the roomInfo object
	 * This is used when its known that user is only connected to one room
	 * @returns {IExperienceRoomData | null} - The information on the room or null if not found
	 */
	const handleGetExperienceRoom = useCallback((): IExperienceRoomData | null => {
		if (roomInfo.experienceRooms.length > 0) {
			return roomInfo.experienceRooms[0];
		}

		return null;
	}, [roomInfo.experienceRooms]);

	/**
	 * Sends a echo every minute to keep the socket alive
	 * @param {string} space - the socket space to send the echo too
	 */
	const sendEcho = useCallback(
		(space: string): void => {
			if (socketObject) {
				const message: MessageType.IEchoMessage = {
					type: 'echo',
					space: space,
					data: {
						echo: 'ping'
					}
				};

				if (socketObject.readyState === socketObject.OPEN) {
					socketObject.send(JSON.stringify(message));

					timer.current = setTimeout(() => sendEcho(space), 60000);
				}
			}
		},
		[socketObject]
	);

	/**
	 * Find a specific experience room if exists
	 * @param {string} space - the given room space
	 * @returns {IExperienceRoomData | undefined} - the experience room information if found
	 */
	const findExerienceRoom = useCallback(
		(space: string): IExperienceRoomData | undefined => {
			return roomInfo.experienceRooms.find(expRoom => expRoom.space === space);
		},
		[roomInfo]
	);

	/**
	 * Updates the experience room information in state
	 * @param {IExperienceRoomData} room - the new room information
	 */
	const updateExperienceRoom = useCallback(
		(room: IExperienceRoomData): void => {
			const experienceRooms = [...roomInfo.experienceRooms];

			const roomIndex = experienceRooms.findIndex(expRoom => expRoom.space === room.space);

			if (roomIndex > -1) {
				experienceRooms[roomIndex] = room;
			} else {
				experienceRooms.push(room);
			}

			setRoomInfo(prevState => ({
				...prevState,
				experienceRooms: experienceRooms
			}));
		},
		[roomInfo]
	);

	/**
	 * When Facilitator attemps to join an event
	 */
	const handleFacilitatorJoinEvent = (
		companyName: string,
		eventName: string,
		eventId: number
	): void => {
		if (socketObject) {
			socketObject.send(
				JSON.stringify({
					type: 'staff-join-event',
					space: 'staff_room',
					data: {
						eventId: eventId
					}
				})
			);

			setFacilitatorData(prevState => ({
				companyName: companyName,
				eventName: eventName,
				eventId: eventId,
				eventState: 'unknown',
				rooms: []
			}));
		}
	};

	/**
	 * When the state of the event is updated
	 * @param {EventState} updateEventState - new updated event state
	 */
	const handleFacilitatorUpdateEvent = (updateEventState: EventState) => {
		if (socketObject && facilitatorData) {
			socketObject.send(
				JSON.stringify({
					type: 'staff-set-event-state',
					space: `staffRoom_${facilitatorData.eventId}`,
					data: {
						eventState: updateEventState
					}
				})
			);
		}
	};

	/**
	 * When Facilitator attemps to join room from an event
	 */
	const handleFacilitatorJoinRooms = useCallback(
		(data: IFacilitatorData): void => {
			if (socketObject) {
				socketObject.send(
					JSON.stringify({
						type: 'staff-join-room',
						space: `staffRoom_${data.eventId}`,
						data: {
							roomName: data.rooms
						}
					})
				);

				setFacilitatorData(data);
			}
		},
		[socketObject, setFacilitatorData]
	);

	/**
	 * When facilitator leaves a room in an event
	 */
	const handleLeaveRooms = useCallback(
		(roomType: RoomType): void => {
			if (state.user?.role === 'participant') {
				if (isSupervisor && socketObject) {
					switch (roomType) {
						case 'Experience Room':
							socketObject.send(
								JSON.stringify({
									type: 'staff-leave-room',
									space: supervisorRoomInfo.space,
									data: {
										roomName: supervisorRoomInfo.roomJoined
									}
								})
							);
							break;

						case 'Main Stage':
							socketObject.send(
								JSON.stringify({
									type: 'staff-set-main-stage-request',
									space: supervisorRoomInfo.space,
									data: {
										wantMainStage: false
									}
								})
							);
							break;
						case 'Green Room':
							socketObject.send(
								JSON.stringify({
									type: 'staff-set-green-room-request',
									space: supervisorRoomInfo.space,
									data: {
										wantGreenRoom: false
									}
								})
							);
							break;
					}
				}
			} else {
				// Get facilitator data from session storage vs. stored facilitator data to be independant of it
				const storage = sessionStorage.getItem('facilitatorInfo');

				if (storage && socketObject) {
					const facilitatorInfo: IFacilitatorData = JSON.parse(storage);

					if (facilitatorInfo) {
						switch (roomType) {
							case 'Main Stage':
								socketObject.send(
									JSON.stringify({
										type: 'staff-set-main-stage-request',
										space: `staffRoom_${facilitatorInfo.eventId}`,
										data: {
											wantMainStage: false
										}
									})
								);
								break;
							case 'Green Room':
								socketObject.send(
									JSON.stringify({
										type: 'staff-set-green-room-request',
										space: `staffRoom_${facilitatorInfo.eventId}`,
										data: {
											wantGreenRoom: false
										}
									})
								);
								break;
							case 'Experience Room':
								socketObject.send(
									JSON.stringify({
										type: 'staff-leave-room',
										space: `staffRoom_${facilitatorInfo.eventId}`,
										data: {
											roomName: facilitatorInfo.rooms
										}
									})
								);
								setFacilitatorData(prevState => ({
									...prevState,
									rooms: [],
									roomEntered: 'none'
								}));

								setRoomInfo(prevState => ({
									...prevState,
									experienceRooms: []
								}));
								break;
						}
					}
				}
			}
		},
		[socketObject, supervisorRoomInfo, isSupervisor, state.user]
	);

	const handleLeaveRoom = useCallback(
		(roomName: string) => {
			if (socketObject) {
				if (state.user?.role === 'participant') {
					if (isSupervisor) {
						socketObject.send(
							JSON.stringify({
								type: 'staff-leave-room',
								space: supervisorRoomInfo.space,
								data: {
									roomName: roomName
								}
							})
						);
					}
				} else {
					if (facilitatorData && facilitatorData.eventId) {
						socketObject.send(
							JSON.stringify({
								type: 'staff-leave-room',
								space: `staffRoom_${facilitatorData.eventId}`,
								data: {
									roomName: roomName
								}
							})
						);

						if (facilitatorData.rooms) {
							const newFacilitatorDataRoom = facilitatorData.rooms.filter(
								room => room !== roomName
							);

							setFacilitatorData(prevState => ({
								...prevState,
								rooms: newFacilitatorDataRoom,
								roomEntered:
									newFacilitatorDataRoom.length > 0 ? 'Experience Room' : 'none'
							}));
						}
					}
				}
			}
		},
		[socketObject, facilitatorData, isSupervisor, supervisorRoomInfo, state.user]
	);

	/**
	 * Removes room from facilitator view
	 * @param {string} space - the room that has been left
	 */
	const handleLeftRoom = useCallback(
		(space: string): void => {
			const newExperienceRooms = roomInfo.experienceRooms.filter(
				expRoom => expRoom.space !== space
			);

			setRoomInfo(prevState => ({
				...prevState,
				experienceRooms: newExperienceRooms
			}));
		},
		[roomInfo]
	);

	// Call Logic

	/**
	 * Handles joining a call into a room
	 * @param isBroadcast - whether the user is broadcasting or just listening in
	 * @param space - the room space to join
	 * @param url - the room url to join
	 * @param token - the room token for authorization
	 */
	const handleCallRequest = (isBroadcast: boolean, space: string): void => {
		const room = roomInfo.experienceRooms.find(expRoom => expRoom.space === space);
		if (room) {
			const videoUrl = room.videoRoomUrl ? room.videoRoomUrl : '';
			const videoToken = room.videoToken ? room.videoToken : '';
			if (dailyCallObject) {
				dailyCallObject
					.destroy()
					.then(() => {
						setDailyCallObject(null);

						const experienceRooms = [...roomInfo.experienceRooms];
						const callRoomIndex = roomInfo.experienceRooms.findIndex(
							expRoom => expRoom.space === currentCallSpace
						);
						if (callRoomIndex !== -1) {
							experienceRooms[callRoomIndex].callStatus = 'None';
						} else {
							const roomIndex = roomInfo.experienceRooms.findIndex(
								expRoom => expRoom.space === space
							);
							experienceRooms[roomIndex].loadingCall = true;
						}

						setRoomInfo(prevState => ({
							...prevState,
							experienceRooms: experienceRooms
						}));

						isBroadcast
							? startBroadCastCall(space, videoUrl, videoToken)
							: startListeningToCall(space, videoUrl, videoToken);
					})
					.catch(err => {
						console.log(err);
						setAcknowledgement(
							'Call request failed',
							'We were unable to close current call, please try again or contact support.',
							'Ok',
							closeAcknowledgement
						);
					});
			} else {
				const experienceRooms = [...roomInfo.experienceRooms];
				const roomIndex = roomInfo.experienceRooms.findIndex(
					expRoom => expRoom.space === space
				);
				experienceRooms[roomIndex].loadingCall = true;

				setRoomInfo(prevState => ({
					...prevState,
					experienceRooms: experienceRooms
				}));

				isBroadcast
					? startBroadCastCall(space, videoUrl, videoToken)
					: startListeningToCall(space, videoUrl, videoToken);
			}
		}
	};

	/**
	 * Starts joining a call to broadcast for participants (and supervisor)
	 * @param {RoomType} roomType - the type of room you are joining
	 * @param {string} url - the Url to the room to join
	 * @param {string} token - the room token for authorization
	 */
	const participantBroadcastCall = useCallback(
		(roomType: RoomType, url: string, token: string): void => {
			const newCallObject = DailyIframe.createCallObject();

			newCallObject
				.join({ url, token, subscribeToTracksAutomatically: false })
				.then(async obj => {
					const isAuth = await getAuthentication();
					if (isAuth) {
						setDailyCallObject(newCallObject);
					} else {
						newCallObject.destroy();
					}
				})
				.catch(err => {
					console.log(err);
					newCallObject.destroy();
					setAcknowledgement(
						'Call request failed',
						'We were unable to broadcast call, please try again or contact support.',
						'Ok',
						closeAcknowledgement
					);
				});
		},
		[setAcknowledgement, closeAcknowledgement]
	);

	/**
	 * Starts joining a call to broadcast
	 * @param {string} space - the room space to join
	 * @param {string} url - the Url to the room to join
	 * @param {string} token - the room token for authorization
	 */
	const startBroadCastCall = useCallback(
		(space: string, url: string, token: string): void => {
			const newCallObject = DailyIframe.createCallObject();

			newCallObject
				.join({ url, token, subscribeToTracksAutomatically: false })
				.then(async obj => {
					// Make sure your authenticated before going into call
					const isAuth = await getAuthentication();
					if (isAuth) {
						newCallObject.setUserName(
							state.user
								? `subscribe|${state.user.firstName} ${state.user.lastName}`
								: 'No Name'
						);
						setDailyCallObject(newCallObject);

						const roomIndex = roomInfo.experienceRooms.findIndex(
							expRoom => expRoom.space === space
						);

						if (roomIndex !== -1) {
							const experienceRooms = [...roomInfo.experienceRooms];
							experienceRooms[roomIndex].loadingCall = false;
							experienceRooms[roomIndex].callStatus = 'Broadcasting';
							setRoomInfo(prevState => ({
								...prevState,
								experienceRooms: experienceRooms
							}));
						}

						setCurrentCallSpace(space);
					} else {
						newCallObject.destroy();
					}
				})
				.catch(err => {
					console.log(err);
					newCallObject.destroy();
					setAcknowledgement(
						'Call request failed',
						'We were unable to broadcast call, please try again or contact support.',
						'Ok',
						closeAcknowledgement
					);
				});
		},
		[roomInfo, state.user, setAcknowledgement, closeAcknowledgement]
	);

	/**
	 * Starts joining a call to listen in
	 * @param {string} space - the room space to join
	 * @param {string} url - the Url to the room to join
	 * @param {string} token - the room token for authorization
	 */
	const startListeningToCall = useCallback(
		(space: string, url: string, token: string): void => {
			const newCallObject = DailyIframe.createCallObject();

			newCallObject
				.join({
					url,
					token,
					audioSource: undefined,
					videoSource: undefined
				})
				.then(async obj => {
					const isAuth = await getAuthentication();
					if (isAuth) {
						newCallObject.setUserName(
							state.user
								? `nosubscribe|${state.user.firstName} ${state.user.lastName}`
								: 'No Name'
						);
						setDailyCallObject(newCallObject);
						const roomIndex = roomInfo.experienceRooms.findIndex(
							expRoom => expRoom.space === space
						);

						if (roomIndex !== -1) {
							const experienceRooms = [...roomInfo.experienceRooms];
							experienceRooms[roomIndex].loadingCall = false;
							experienceRooms[roomIndex].callStatus = 'Listening';
							setRoomInfo(prevState => ({
								...prevState,
								experienceRooms: experienceRooms
							}));
						}

						setCurrentCallSpace(space);
					} else {
						newCallObject.destroy();
					}
				})
				.catch(err => {
					console.log(err);
					newCallObject.destroy();
					setAcknowledgement(
						'Call request failed',
						'We were unable to listen to call, please try again or contact support.',
						'Ok',
						closeAcknowledgement
					);
				});
		},
		[roomInfo, state.user, setAcknowledgement, closeAcknowledgement]
	);

	/**
	 * When uses is listening and would like to broadcast
	 * @param space - the room space to switch broadcasting to
	 */
	const handleSwitchCallType = useCallback(
		(space: string, status: CallStatus): void => {
			if (dailyCallObject) {
				dailyCallObject
					.setUserName(
						state.user
							? `${status === 'Broadcasting' ? 'subscribe' : 'nosubscribe'}|${
									state.user.firstName
							  } ${state.user.lastName}`
							: 'No Name'
					)
					.then(userName => {
						const roomIndex = roomInfo.experienceRooms.findIndex(
							expRoom => expRoom.space === space
						);

						if (roomIndex !== -1) {
							const experienceRooms = [...roomInfo.experienceRooms];
							experienceRooms[roomIndex].callStatus = status;
							setRoomInfo(prevState => ({
								...prevState,
								experienceRooms: experienceRooms
							}));
						}
					});
			}
		},
		[roomInfo, state.user, dailyCallObject]
	);

	/**
	 * Leaves the room call
	 * @param space - the room space to leave
	 */
	const handleLeaveCall = useCallback(
		(space: string): void => {
			if (dailyCallObject) {
				dailyCallObject.destroy().then(() => {
					setDailyCallObject(null);
					const roomIndex = roomInfo.experienceRooms.findIndex(
						expRoom => expRoom.space === space
					);

					if (roomIndex !== -1) {
						const experienceRooms = [...roomInfo.experienceRooms];
						experienceRooms[roomIndex].callStatus = 'None';
						setRoomInfo(prevState => ({
							...prevState,
							experienceRooms: experienceRooms
						}));
					}
				});
			}
		},
		[roomInfo, dailyCallObject]
	);

	// Initialize roomState.socket events
	useEffect(() => {
		if (!socketObject) return;

		socketObject.onerror = error => {
			console.log('error is ', error);
		};

		socketObject.onmessage = (incomingMessage: any): void => {
			const socketMessage: MessageType.ISocketMessage = JSON.parse(
				incomingMessage.data
			) as MessageType.ISocketMessage;

			const space = socketMessage.space;
			if (!socketMessage.data) return;

			// Find the type of room this message belongs to from the space name;
			const roomType = space.split('_')[0] as SpaceRoomType;
			const socketMessageType = socketMessage.type as SocketMessageType;

			if (socketMessageType === 'event-state-update') {
				const newState = socketMessage.data.eventState as EventState;

				setFacilitatorData(prevState => ({
					...prevState,
					eventState: newState
				}));
				return;
			}

			switch (roomType) {
				// Global staff chat (nothing really happens here)
				case 'staff':
					break;
				case 'supervisorRoom':
					if (socketMessageType === 'joined-space') {
						setIsSupervisor(true);
						history.push('supervisor-navigation');

						const roomData = socketMessage.data.rooms as IRoom[];
						const rooms = roomData.filter(room => !room.supervisor);
						setSupervisorRoomInfo({ space: space, roomJoined: '', rooms: rooms });
					}
					break;
				// Event Staff Chat rooms
				case 'staffRoom':
					if (socketMessageType === 'joined-space') {
						// join staff chat
						const joinStaffMessage = socketMessage as MessageType.IJoinChatSpaceMessage;
						const chatRoom = handleJoinChatRoom(joinStaffMessage);

						setRoomInfo(prevState => ({
							...prevState,
							staffChatRoom: chatRoom
						}));

						setFacilitatorData(prevState => ({
							...prevState,
							eventState: joinStaffMessage.data.eventState
						}));

						// If you joined event and were connected to rooms prior (refresh or disconnect), reconnect to them
						if (facilitatorData) {
							if (facilitatorData.roomEntered) {
								switch (facilitatorData.roomEntered) {
									case 'Experience Room':
										if (
											facilitatorData?.rooms &&
											facilitatorData.rooms?.length > 0
										) {
											socketObject.send(
												JSON.stringify({
													type: 'staff-join-room',
													space: `staffRoom_${facilitatorData.eventId}`,
													data: {
														roomName: facilitatorData.rooms
													}
												})
											);

											// navigate to the correct room view
											if (facilitatorData.rooms.length > 1) {
												history.push('facilitator-view');
											} else {
												history.push('experience-room');
											}
										}
										break;
									case 'Main Stage':
										socketObject.send(
											JSON.stringify({
												type: 'staff-set-main-stage-request',
												space: `staffRoom_${facilitatorData.eventId}`,
												data: {
													wantMainStage: true
												}
											})
										);
										break;
									case 'Green Room':
										socketObject.send(
											JSON.stringify({
												type: 'staff-set-green-room-request',
												space: `staffRoom_${facilitatorData.eventId}`,
												data: {
													wantGreenRoom: true
												}
											})
										);
										break;
								}
							}
						}

						return;
					}

					if (socketMessage.type === 'left-space') {
						setRoomInfo(prevState => ({
							...prevState,
							staffChatRoom: undefined
						}));

						return;
					}

					const chatRoom = roomInfo.staffChatRoom;

					if (chatRoom) {
						switch (socketMessageType) {
							case 'user-join-space':
								const userJoinedMessage =
									socketMessage as MessageType.IUserJoinedSpaceMessage;
								const joinedUser: any = userJoinedMessage.data.user;

								chatRoom.lobby = handleUserJoinLobby(
									chatRoom.lobby,
									joinedUser.id,
									{
										id: joinedUser.id,
										firstName: joinedUser.firstName,
										lastName: joinedUser.lastName,
										role: joinedUser.role
									}
								);

								break;
							case 'user-leave-space':
								const userLeftMessage =
									socketMessage as MessageType.IUserLeftSpaceMessage;

								chatRoom.lobby = handleUserLeftLobby(
									chatRoom.lobby,
									userLeftMessage.data.userId
								);

								break;
							case 'chat-message':
								chatRoom.messages = handleUpdateChat(
									chatRoom.messages,
									chatRoom.lobby,
									socketMessage.data as MessageType.IChatMessageData
								);
								break;
							case 'chat-message-update':
								chatRoom.messages = handleUpdateChatMessage(
									chatRoom.messages,
									socketMessage.data as MessageType.IChatMessageData
								);
								break;
							default:
								break;
						}

						setRoomInfo(prevState => ({
							...prevState,
							staffChatRoom: chatRoom
						}));
					}

					break;
				case 'announcementRoom': {
					if (socketMessageType === 'joined-space') {
						// join staff chat
						const joinAnnouncemenmtMessage =
							socketMessage as MessageType.IJoinChatSpaceMessage;
						const announcementRoomJoined = handleJoinChatRoom(joinAnnouncemenmtMessage);

						setRoomInfo(prevState => ({
							...prevState,
							announcementRoom: announcementRoomJoined
						}));

						return;
					}

					const announcementRoom = roomInfo.announcementRoom;

					if (announcementRoom) {
						switch (socketMessageType) {
							case 'user-join-space':
								const userJoinedMessage =
									socketMessage as MessageType.IUserJoinedSpaceMessage;
								const joinedUser: any = userJoinedMessage.data.user;

								announcementRoom.lobby = handleUserJoinLobby(
									announcementRoom.lobby,
									joinedUser.id,
									{
										id: joinedUser.id,
										firstName: joinedUser.firstName,
										lastName: joinedUser.lastName,
										role: joinedUser.role
									}
								);

								break;
							case 'user-leave-space':
								const userLeftMessage =
									socketMessage as MessageType.IUserLeftSpaceMessage;

								announcementRoom.lobby = handleUserLeftLobby(
									announcementRoom.lobby,
									userLeftMessage.data.userId
								);

								break;
							case 'chat-message':
								announcementRoom.messages = handleUpdateChat(
									announcementRoom.messages,
									announcementRoom.lobby,
									socketMessage.data as MessageType.IChatMessageData
								);

								const action = (key: number) => (
									<Container disableGutters>
										<IconButton onClick={() => closeSnackbar(key)}>
											<Cancel fontSize="small" color="secondary" />
										</IconButton>
									</Container>
								);

								const latestMessage =
									announcementRoom.messages[announcementRoom.messages.length - 1];

								enqueueSnackbar(
									<Container style={{ width: 200 }} disableGutters>
										<Typography
											variant="subtitle1"
											style={{ fontWeight: 600, textDecoration: 'underline' }}
										>
											Announcement:
										</Typography>
										<Typography
											variant="subtitle2"
											style={{ overflowWrap: 'break-word' }}
										>
											{latestMessage.message.length > 60
												? latestMessage.message.substring(0, 60) + '...'
												: latestMessage.message}
										</Typography>
									</Container>,
									{
										key: latestMessage.id,
										action,
										autoHideDuration: 6000
									}
								);
								break;
							case 'chat-message-update':
								announcementRoom.messages = handleUpdateChatMessage(
									announcementRoom.messages,
									socketMessage.data as MessageType.IChatMessageData
								);
								break;
							default:
								break;
						}

						setRoomInfo(prevState => ({
							...prevState,
							announcementRoom: announcementRoom
						}));
					}

					break;
				}
				// Waiting room for participants
				case 'waitingRoom':
					if (socketMessageType === 'joined-space') {
						if (state.user?.role === 'participant') {
							history.push('/waiting-room');
						}
					}
					break;
				case 'greenRoom':
					if (socketMessageType === 'joined-space') {
						const joinGreenRoomMessage =
							socketMessage as MessageType.IJoinedGeneralSpaceMessage;
						const greenRoom = handleJoinGeneralRoom(joinGreenRoomMessage);

						setRoomInfo(prevState => ({
							...prevState,
							greenRoom: greenRoom
						}));

						setFacilitatorData(prevState => ({
							...prevState,
							roomEntered: 'Green Room'
						}));

						if (state.user?.role === 'participant') {
							participantBroadcastCall(
								'Green Room',
								joinGreenRoomMessage.data.videoRoomUrl,
								joinGreenRoomMessage.data.videoToken
							);
						} else {
							startBroadCastCall(
								space,
								joinGreenRoomMessage.data.videoRoomUrl,
								joinGreenRoomMessage.data.videoToken
							);
						}

						history.push('/green-room');
						return;
					}

					if (socketMessage.type === 'left-space') {
						if (dailyCallObject) {
							dailyCallObject.destroy().then(() => {
								setDailyCallObject(null);
							});
						}

						setRoomInfo(prevState => ({
							...prevState,
							greenRoom: undefined
						}));

						setFacilitatorData(prevState => ({
							...prevState,
							roomEntered: 'none'
						}));

						return;
					}

					const greenRoom = roomInfo.greenRoom;

					if (greenRoom) {
						switch (socketMessageType) {
							case 'user-join-space':
								const userJoinedMessage =
									socketMessage as MessageType.IUserJoinedSpaceMessage;
								const joinedUser: any = userJoinedMessage.data.user;

								greenRoom.lobby = handleUserJoinLobby(
									greenRoom.lobby,
									joinedUser.id,
									{
										id: joinedUser.id,
										firstName: joinedUser.firstName,
										lastName: joinedUser.lastName,
										role: joinedUser.role
									}
								);
								handleSnackbarMessage(
									`${joinedUser.firstName} ${joinedUser.lastName} has joined the room.`
								);
								break;
							case 'user-leave-space':
								const userLeftMessage =
									socketMessage as MessageType.IUserLeftSpaceMessage;

								const user = greenRoom.lobby[userLeftMessage.data.userId];

								greenRoom.lobby = handleUserLeftLobby(
									greenRoom.lobby,
									userLeftMessage.data.userId
								);

								if (user) {
									handleSnackbarMessage(
										`${user.firstName} ${user.lastName} has left the room.`
									);
								}
								break;
							case 'chat-message':
								greenRoom.messages = handleUpdateChat(
									greenRoom.messages,
									greenRoom.lobby,
									socketMessage.data as MessageType.IChatMessageData
								);
								break;
							case 'chat-message-update':
								greenRoom.messages = handleUpdateChatMessage(
									greenRoom.messages,
									socketMessage.data as MessageType.IChatMessageData
								);
								break;
							default:
								break;
						}

						setRoomInfo(prevState => ({
							...prevState,
							greenRoom: greenRoom
						}));
					}

					break;
				// main presentation room for event
				case 'mainStage':
					if (socketMessageType === 'joined-space') {
						const joinMainStageMessage =
							socketMessage as MessageType.IJoinedGeneralSpaceMessage;
						const mainStage = handleJoinGeneralRoom(joinMainStageMessage);

						setRoomInfo(prevState => ({
							...prevState,
							mainStage: mainStage
						}));

						setFacilitatorData(prevState => ({
							...prevState,
							roomEntered: 'Main Stage'
						}));

						if (state.user?.role === 'participant') {
							participantBroadcastCall(
								'Main Stage',
								joinMainStageMessage.data.videoRoomUrl,
								joinMainStageMessage.data.videoToken
							);
						} else {
							startBroadCastCall(
								space,
								joinMainStageMessage.data.videoRoomUrl,
								joinMainStageMessage.data.videoToken
							);
						}

						history.push('/main-stage');
						return;
					}

					if (socketMessage.type === 'left-space') {
						if (dailyCallObject) {
							dailyCallObject.destroy().then(() => {
								setDailyCallObject(null);
							});
						}

						setRoomInfo(prevState => ({
							...prevState,
							mainStage: undefined
						}));

						setFacilitatorData(prevState => ({
							...prevState,
							roomEntered: 'none'
						}));

						return;
					}

					const mainStage = roomInfo.mainStage;

					if (mainStage) {
						switch (socketMessageType) {
							case 'user-join-space':
								const userJoinedMessage =
									socketMessage as MessageType.IUserJoinedSpaceMessage;
								const joinedUser: any = userJoinedMessage.data.user;

								mainStage.lobby = handleUserJoinLobby(
									mainStage.lobby,
									joinedUser.id,
									{
										id: joinedUser.id,
										firstName: joinedUser.firstName,
										lastName: joinedUser.lastName,
										role: joinedUser.role
									}
								);
								handleSnackbarMessage(
									`${joinedUser.firstName} ${joinedUser.lastName} has joined the room.`
								);
								break;
							case 'user-leave-space':
								const userLeftMessage =
									socketMessage as MessageType.IUserLeftSpaceMessage;

								const user = mainStage.lobby[userLeftMessage.data.userId];

								mainStage.lobby = handleUserLeftLobby(
									mainStage.lobby,
									userLeftMessage.data.userId
								);

								if (user) {
									handleSnackbarMessage(
										`${user.firstName} ${user.lastName} has left the room.`
									);
								}
								break;
							case 'chat-message':
								mainStage.messages = handleUpdateChat(
									mainStage.messages,
									mainStage.lobby,
									socketMessage.data as MessageType.IChatMessageData
								);
								break;
							case 'chat-message-update':
								mainStage.messages = handleUpdateChatMessage(
									mainStage.messages,
									socketMessage.data as MessageType.IChatMessageData
								);
								break;
							case 'hand-raised-update':
								const raiseHandUpdateMessage =
									socketMessage as MessageType.IHandRaiseUpdate;
								mainStage.lobby = handleUserRaiseHand(
									mainStage.lobby,
									raiseHandUpdateMessage.data.userId,
									raiseHandUpdateMessage.data.raisedHand
								);

								if (raiseHandUpdateMessage.data.affectsSelf) {
									setParticpantHandRaised(raiseHandUpdateMessage.data.raisedHand);
								}
								break;
							case 'video-chatter-update':
								const videoChatterUpdateMessage =
									socketMessage as MessageType.IVideoChatterUpdate;
								mainStage.lobby = handleUserVideoChatter(
									mainStage.lobby,
									videoChatterUpdateMessage.data.userId,
									videoChatterUpdateMessage.data.allowed
								);

								if (videoChatterUpdateMessage.data.affectsSelf) {
									const videoEnabled = videoChatterUpdateMessage.data.allowed;

									if (dailyCallObject) {
										if (videoEnabled) {
											dailyCallObject.setUserName(
												state.user
													? `subscribe|${state.user.firstName} ${state.user.lastName}`
													: 'No Name'
											);
										} else {
											dailyCallObject.setUserName(
												state.user
													? `nosubscribe|${state.user.firstName} ${state.user.lastName}`
													: 'No Name'
											);
										}
									}
								}
								break;

							default:
								break;
						}

						setRoomInfo(prevState => ({
							...prevState,
							mainStage: mainStage
						}));
					}

					break;
				case 'experienceRoom':
					if (socketMessageType === 'joined-space') {
						const joinMessage =
							socketMessage as MessageType.IJoinedExperienceSpaceMessage;
						const joinedRoom = handleJoinedExperienceRoom(joinMessage);

						updateExperienceRoom(joinedRoom);

						if (state.user?.role === 'participant') {
							participantBroadcastCall(
								'Experience Room',
								joinMessage.data.videoRoomUrl,
								joinMessage.data.videoToken
							);

							if (isSupervisor) {
								setSupervisorRoomInfo(prevState => ({
									...prevState,
									roomJoined: joinedRoom.name
								}));
							}
						} else {
							if (facilitatorData && facilitatorData.rooms) {
								if (facilitatorData.rooms.length === 1) {
									startBroadCastCall(
										space,
										joinMessage.data.videoRoomUrl,
										joinMessage.data.videoToken
									);
								}
							}
						}

						if (state.user?.role === 'participant') {
							history.push('experience-room');
						}

						// TODO: Make sure to send echo to all socket connections AND cleanup
						//sendEcho(space);
						return;
					}

					if (socketMessage.type === 'left-space') {
						if (dailyCallObject) {
							dailyCallObject.destroy().then(() => {
								setDailyCallObject(null);
							});
						}
						handleLeftRoom(space);
						return;
					}

					const selectedRoom = findExerienceRoom(socketMessage.space);
					if (selectedRoom) {
						switch (socketMessageType) {
							case 'user-join-space':
								const userJoinedMessage =
									socketMessage as MessageType.IUserJoinedSpaceMessage;
								const joinedUser: any = userJoinedMessage.data.user;

								selectedRoom.lobby = handleUserJoinLobby(
									selectedRoom.lobby,
									joinedUser.id,
									{
										id: joinedUser.id,
										firstName: joinedUser.firstName,
										lastName: joinedUser.lastName,
										role: joinedUser.role
									}
								);

								if (joinedUser.role === 'supervisor') {
									return;
								}
								handleSnackbarMessage(
									`${joinedUser.firstName} ${joinedUser.lastName} has joined the room.`
								);
								break;
							case 'user-leave-space':
								const userLeftMessage =
									socketMessage as MessageType.IUserLeftSpaceMessage;

								const user = selectedRoom.lobby[userLeftMessage.data.userId];

								selectedRoom.lobby = handleUserLeftLobby(
									selectedRoom.lobby,
									userLeftMessage.data.userId
								);

								if (user) {
									handleSnackbarMessage(
										`${user.firstName} ${user.lastName} has left the room.`
									);
								}
								break;
							case 'chat-message':
								selectedRoom.messages = handleUpdateChat(
									selectedRoom.messages,
									selectedRoom.lobby,
									socketMessage.data as MessageType.IChatMessageData
								);
								break;
							case 'chat-message-update':
								selectedRoom.messages = handleUpdateChatMessage(
									selectedRoom.messages,
									socketMessage.data as MessageType.IChatMessageData
								);
								break;
							case 'need-assistance-flag':
								selectedRoom.roomAssistance = handleUpdateRoomAssitance(
									socketMessage as MessageType.INeedAssistanceFlagMessage
								);
								break;
							case 'asset-lock-state':
								const { data } = socketMessage as MessageType.ILockAssetMessage;
								selectedRoom.assets = handleChangeLockState(
									selectedRoom.assets,
									data.key,
									data.locked
								);
								break;
							case 'desktop-document':
								selectedRoom.documents = handleDocumentAdded(
									selectedRoom.documents,
									selectedRoom.assets,
									socketMessage as MessageType.IDocumentReceivedMessage
								);
								break;
							case 'desktop-document-closed':
								selectedRoom.documents = handleDocumentRemoved(
									selectedRoom.documents,
									socketMessage as MessageType.IDocumentClose
								);
								break;
							default:
								break;
						}

						updateExperienceRoom(selectedRoom);
					}

					break;
			}
		};
	}, [
		socketObject,
		dailyCallObject,
		history,
		roomInfo,
		isSupervisor,
		state.user,
		facilitatorData,
		findExerienceRoom,
		startBroadCastCall,
		participantBroadcastCall,
		updateExperienceRoom,
		handleSnackbarMessage,
		handleLeftRoom,
		closeSnackbar,
		enqueueSnackbar
	]);

	// Handle changes within the callObject
	useEffect(() => {
		if (!dailyCallObject) return;

		const events: DailyEvent[] = ['joined-meeting', 'left-meeting', 'error'];

		const handleNewMeetingState = (): void => {
			if (dailyCallObject) {
				switch (dailyCallObject.meetingState()) {
					case 'joined-meeting':
						break;
					case 'left-meeting':
						break;
					case 'error':
						break;
					default:
						break;
				}
			}
		};

		// Use initial state
		handleNewMeetingState();

		// Listen for changes in state
		for (const event of events) {
			dailyCallObject.on(event, handleNewMeetingState);
		}

		// Stop listening for changes in state
		return function cleanup() {
			for (const event of events) {
				dailyCallObject.off(event, handleNewMeetingState);
			}

			dailyCallObject.leave();
		};
	}, [dailyCallObject]);

	useEffect(() => {
		if (facilitatorData !== null) {
			sessionStorage.setItem('facilitatorInfo', JSON.stringify(facilitatorData));
		}
	}, [facilitatorData]);

	return (
		<SocketContext.Provider
			value={{
				callObject: dailyCallObject,
				rooms: roomInfo,
				eventRooms: roomInfo,
				supervisor: isSupervisor,
				supervisorRooms: supervisorRoomInfo,
				setRooms: setRoomInfo,
				socket: socketObject,
				handRaised: participantHandRaised,
				facilitatorJoinEvent: handleFacilitatorJoinEvent,
				joinCallback: (): void => {},
				facilitatorJoinRooms: handleFacilitatorJoinRooms,
				facilitatorUpdateEvent: handleFacilitatorUpdateEvent,
				leaveRooms: handleLeaveRooms,
				leaveRoom: handleLeaveRoom,
				facilitatorData: facilitatorData,
				setFacilitatorData: setFacilitatorData,
				connect: handleConnectSocket,
				callRequest: handleCallRequest,
				switchCall: handleSwitchCallType,
				leaveCall: handleLeaveCall,
				documents: documents,
				setDocuments: setDocuments,
				getExperienceRoom: handleGetExperienceRoom
			}}
		>
			{children}
		</SocketContext.Provider>
	);
};

const useSocketManager = () => {
	const context = useContext(SocketContext);
	if (!context) {
		throw new Error('useDataState must be used within a DataContext');
	}

	return context;
};

export { SocketManager, useSocketManager };
