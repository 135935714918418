import React, { useState, useEffect, useCallback } from 'react';
import { useGlobalState } from '../../../contexts/GlobalStateProvider';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import SendIcon from '@material-ui/icons/Send';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import VisibilityIcon from '@material-ui/icons/Visibility';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

import * as MessageType from '../../../interfaces/message';
import { IAsset } from '../../../interfaces/asset';
import { IEntry } from '../../../interfaces/entry';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

interface IAssetViewProps {
	socket: WebSocket | null;
	space: string;
	assets: IAsset[];
	onPreviewAsset: (asset: IAsset) => void;
}

const useStyles = makeStyles(theme => ({
	folderView: {
		height: '100%',
		color: theme.palette.primary.contrastText,
		overflow: 'auto',
		maxHeight: '80vh'
	},
	tree: {
		height: '100%',
		overflow: 'auto',
		margin: theme.spacing(0, 1.5),
		padding: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			width: '18vw',
			padding: 0
		}
	}
}));

const checkForUnlocks = (folder: IEntry) => {
	let result = false;
	folder.children.forEach(child => {
		if (child.type === 'File' && child.content) {
			if (child.content.locked === false) {
				result = true;
			}
		} else {
			if (!result) {
				result = checkForUnlocks(child);
			}
		}
	});

	return result;
};

const AssetView: React.FC<IAssetViewProps> = ({ socket, space, assets, onPreviewAsset }) => {
	const classes = useStyles();
	const { state } = useGlobalState();

	const [structuredAssets, setStructuredAssets] = useState<any | undefined>(undefined);

	/**
	 * Populates the tree view with all the current assets provided to this room
	 * This is role dependant, meaning different things will render based on users role (Participant vs. Facilitator)
	 * @param {IEntry} assets - The structure of assets to populate in tree
	 * @returns {JSX.Element | null} - The tree item if applicable
	 */
	const renderAssetView = (assets: IEntry): JSX.Element | null => {
		if (assets.type === 'Directory') {
			if (state.user?.role === 'participant' && !checkForUnlocks(assets)) {
				return null;
			} else {
				return (
					<TreeItem key={assets.id} nodeId={assets.id} label={assets.name}>
						{assets.children.map(child => renderAssetView(child))}
					</TreeItem>
				);
			}
		} else {
			if (state.user?.role === 'participant' && assets.content?.locked) {
				return null;
			}

			return (
				<TreeItem
					key={assets.id}
					nodeId={assets.id}
					label={
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							<span style={{ marginRight: '15px' }}>{assets.name}</span>
							<Container style={{ display: 'flex', justifyContent: 'flex-end' }}>
								{state.user?.role === 'participant' ? (
									<Tooltip title="Open Asset">
										<IconButton
											onClick={() => handleAddDocument(assets.content!.key)}
											style={{ marginRight: '5px' }}
											size="small"
											color="inherit"
										>
											<OpenInBrowserIcon />
										</IconButton>
									</Tooltip>
								) : (
									<Tooltip title="Preview Asset">
										<IconButton
											onClick={() => onPreviewAsset(assets.content!)}
											style={{ marginRight: '5px' }}
											size="small"
											color="inherit"
										>
											<VisibilityIcon />
										</IconButton>
									</Tooltip>
								)}
								<Tooltip title="Send Asset to Everyone">
									<IconButton
										onClick={() => handleSendDocument(assets.content!.key)}
										style={{ marginRight: '5px' }}
										size="small"
										color="inherit"
									>
										<SendIcon />
									</IconButton>
								</Tooltip>
								{state.user?.role !== 'participant' && (
									<Tooltip
										title={
											assets.content?.locked ? 'Unlock Asset' : 'Lock Asset'
										}
									>
										<IconButton
											onClick={() => handleToggleLockAsset(assets.content!)}
											size="small"
											color="inherit"
										>
											{assets.content?.locked ? (
												<LockIcon />
											) : (
												<LockOpenIcon />
											)}
										</IconButton>
									</Tooltip>
								)}
							</Container>
						</div>
					}
				/>
			);
		}
	};

	/**
	 * Toggles the lock state of an asset (staff permission) by sending message
	 * @param {IAsset} asset - the asset to toggle the lock state on
	 */
	const handleToggleLockAsset = (asset: IAsset): void => {
		if (socket) {
			const newLockState = !asset.locked;
			socket.send(
				JSON.stringify({
					type: 'set-asset-lock-state',
					space: space,
					data: {
						assetKey: asset.key,
						locked: newLockState
					}
				})
			);
		}
	};

	/**
	 * Sends message to add document to desktop
	 * @param {string} key - The asset key to add
	 */
	const handleAddDocument = (key: string) => {
		if (socket) {
			const message: MessageType.ISetDocumentMessage = {
				type: 'set-document',
				space: space,
				data: {
					assetKey: key,
					x: 0,
					y: 0,
					width: 400,
					height: 400
				}
			};
			socket.send(JSON.stringify(message));
		}
	};

	const handleSendDocument = (key: string) => {
		if (socket) {
			const message: MessageType.ISetDocumentMessage = {
				type: 'force-send-document',
				space: space,
				data: {
					assetKey: key,
					x: 0,
					y: 0,
					width: 400,
					height: 400
				}
			};
			socket.send(JSON.stringify(message));
		}
	};

	/**
	 * Checks if current entry already has directory of the given name
	 * @returns {number} - the index location of the found folder
	 */
	const findFolder = (entry: IEntry, name: string): number => {
		return entry.children.findIndex(ent => ent.name === name);
	};

	/**
	 * Creates the asset structure to show folders and files within on the tree view compoenent
	 * @param {IAsset[]} assets - The assets retreived from the database
	 */
	const structureAssets = useCallback((assets: IAsset[]) => {
		let entry: IEntry = {
			id: 'assets',
			name: 'Assets',
			type: 'Directory',
			children: []
		};

		assets.forEach(asset => {
			const urlSplitted = asset.key.split('/');

			let node: IEntry = entry;

			while (urlSplitted.length > 1) {
				const folder = urlSplitted.shift()!;

				const currentFolderIdx = findFolder(node, folder);

				if (currentFolderIdx !== -1) {
					node = node.children[currentFolderIdx];
				} else {
					const dir: IEntry = {
						id: `${folder}-${urlSplitted[urlSplitted.length - 1]}`,
						name: folder,
						type: 'Directory',
						children: []
					};
					node.children.push(dir);
					const currentFolderIdx = findFolder(node, dir.name);
					node = node.children[currentFolderIdx];
				}
			}

			// add the file since it is the last name in the split url
			if (urlSplitted.length === 1) {
				const file: IEntry = {
					id: urlSplitted[0],
					name: urlSplitted[0],
					type: 'File',
					children: [],
					content: asset
				};
				node.children.push(file);
			}
		});

		setStructuredAssets(entry);
	}, []);

	// Update asset structure
	useEffect(() => {
		structureAssets(assets);
	}, [assets, structureAssets]);

	return (
		<div className={classes.folderView}>
			<TreeView
				className={classes.tree}
				defaultCollapseIcon={<FolderOpenIcon />}
				defaultExpandIcon={<FolderIcon />}
				defaultExpanded={['assets']}
				defaultEndIcon={<DescriptionIcon />}
			>
				{structuredAssets !== undefined && renderAssetView(structuredAssets)}
			</TreeView>
		</div>
	);
};

export default AssetView;
