import React, { Fragment, useRef, useState, useEffect } from 'react';

import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';

import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import PersonIcon from '@material-ui/icons/Person';

import StaffChatCard from './StaffChatCard';
import ChatViewIcon from '../RoomSystems/ChatView/ChatViewIcon';

import * as MessageType from '../../interfaces/message';

import { makeStyles } from '@material-ui/core/styles';
import { IChatRoomData } from '../../interfaces/roomSocketData';
import { brassPressed } from '../../assets/brassCircularButton';
import { Tooltip } from '@material-ui/core';

interface IStaffChatProps {
	socket: WebSocket | null;
	roomData: IChatRoomData;
}

const useStyles = makeStyles(theme => ({
	icon: {
		position: 'fixed',
		bottom: 0,
		margin: '1rem',
		zIndex: 1000
	},
	content: {
		position: 'fixed',
		bottom: 5,
		left: 5,
		width: '55vw',
		height: '40vh',
		zIndex: 1000
	},
	title: {
		flexGrow: 1,
		marginTop: '10px',
		marginLeft: '10px'
	},
	headerAppBar: {
		height: '40px'
	},
	headerToolBar: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: 0
	},
	messageArea: {
		height: '75%',
		overflow: 'auto'
	},
	inputArea: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '10px'
	},
	textInput: {
		flexGrow: 1,
		marginLeft: '10px',
		marginRight: '10px',
		height: '90%'
	},
	sendButton: {
		alignContent: 'flex-end',
		marginRight: '10px'
	},
	pressedIcon: {
		pressedIcon: {
			backgroundImage: `url(${brassPressed})`,
			boxShadow: '0 5px 15px rgba(0,0,0, .8)'
		}
	}
}));

const StaffChat: React.FC<IStaffChatProps> = ({ socket, roomData }) => {
	const classes = useStyles();
	const theme = useTheme();

	const messageTextField = useRef<any>();
	const messageContainer = useRef<any>();

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleSendMessage = (): void => {
		if (messageTextField && messageTextField.current!.value !== '') {
			const message: MessageType.ISendChatMessage = {
				type: 'send-chat',
				space: roomData.space,
				data: {
					message: messageTextField.current!.value
				}
			};

			if (socket) {
				socket.send(JSON.stringify(message));
			}

			messageTextField.current!.value = '';
		}
	};

	const handleOpenChat = (): void => {
		setIsOpen(true);
	};

	// Scroll down to the last message if needed when messages are added
	useEffect(() => {
		if (messageContainer.current && isOpen) {
			const scroll =
				messageContainer.current.scrollHeight - messageContainer.current.clientHeight;
			messageContainer.current.scrollTo(0, scroll);
		}
	}, [roomData.messages, isOpen]);

	return (
		<Fragment>
			{isOpen ? (
				<Paper elevation={5} className={classes.content}>
					<AppBar position="static" className={classes.headerAppBar}>
						<Toolbar className={classes.headerToolBar}>
							<Typography variant="body1" className={classes.title}>
								Staff Chat
							</Typography>
							<div style={{ display: 'flex', padding: 10, marginRight: '20px' }}>
								<PersonIcon />
								<Typography variant="body1">
									{Object.keys(roomData.lobby).length}
								</Typography>
							</div>

							<IconButton
								color="secondary"
								style={{ padding: 10 }}
								onClick={() => setIsOpen(false)}
								classes={{
									root: isOpen ? classes.pressedIcon : ''
								}}
							>
								<CancelPresentationIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<div ref={messageContainer} className={classes.messageArea}>
						{roomData.messages.map(message => (
							<StaffChatCard key={message.id} message={message} />
						))}
					</div>
					<div className={classes.inputArea}>
						<div className={classes.textInput}>
							<TextField
								style={{ marginTop: '2px' }}
								inputProps={{
									style: {
										height: '90%',
										padding: 7,
										color: `${theme.palette.text.primary}`
									}
								}}
								size="small"
								inputRef={messageTextField}
								variant="outlined"
								placeholder="Type Message"
								fullWidth
								onKeyPress={e => (e.key === 'Enter' ? handleSendMessage() : null)}
							/>
						</div>
						<div className={classes.sendButton}>
							<Button
								variant="contained"
								disableTouchRipple
								onClick={handleSendMessage}
							>
								Send
							</Button>
						</div>
					</div>
				</Paper>
			) : (
				<Tooltip title="Open Staff Chat">
					<Fab className={classes.icon} color="primary" onClick={handleOpenChat}>
						<ChatViewIcon messages={roomData.messages} isOpen={isOpen} />
					</Fab>
				</Tooltip>
			)}
		</Fragment>
	);
};

export default StaffChat;
