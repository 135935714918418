import { Fragment, useState, ElementType } from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	icon: {
		fontSize: '5.5rem',
		cursor: 'pointer',
		[theme.breakpoints.up('lg')]: {
			fontSize: '7.25rem'
		}
	},
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}));

interface ITabIconProps {
	hoverIcon: ElementType<any>;
	defaultIcon: ElementType<any>;
	pressedIcon?: ElementType<any>;
	isPressed?: boolean;
}

const TabIcon: React.FC<ITabIconProps> = ({ hoverIcon, defaultIcon, pressedIcon, isPressed }) => {
	// sets default SVGs hover state to false
	const [hover, setHover] = useState<boolean>(false);

	const classes = useStyles();

	function getHoverState() {
		// sets SVG hover state to the opposite of the current state
		setHover(!hover);
	}

	return (
		<Fragment>
			{hover ? (
				<SvgIcon
					component={hoverIcon}
					viewBox="0 0 192 112"
					onMouseLeave={getHoverState}
					className={classes.icon}
				/>
			) : pressedIcon && isPressed ? (
				<SvgIcon
					component={pressedIcon}
					viewBox="0 0 192 112"
					onMouseEnter={getHoverState}
					className={classes.icon}
				/>
			) : (
				<SvgIcon
					component={defaultIcon}
					viewBox="0 0 192 112"
					onMouseEnter={getHoverState}
					className={classes.icon}
				/>
			)}
		</Fragment>
	);
};

export default TabIcon;
